import React from 'react';
import './Avatars.css';

const Avatars = ({ members }: any) => {
  const maxAvatars = 5;
  const displayedMembers = members.slice(0, maxAvatars - 1);
  const remainingCount = members.length > maxAvatars ? members.length - (maxAvatars - 1) : 0;

  return (
    <div id="avatars">
      {displayedMembers.map((member: any, index: any) =>
        member.picture ? (
          <img
            key={`avatar-${index}`}
            className="avatar"
            src={`${member.picture}.jpg`}
            alt={`${member.firstName} ${member.lastName}`}
          />
        ) : (
          <div key={`avatar-${index}`} className="avatar initials">
            {`${member.firstName[0]}${member.lastName[0]}`}
          </div>
        ),
      )}

      {remainingCount > 0 && <div className="avatar remaining">+{remainingCount}</div>}
    </div>
  );
};

export default Avatars;
