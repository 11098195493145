import { API_URL, CLIENT_ID_HUBSPOT, DASHBOARD_URL, headers, TOKEN } from '../../constants';
import { convertToJsonAndHandleErrors, getToken } from '../utils';

export async function integrationAuthConnect({ code, apiKey, integrationName, domain }: any) {
  const auth: any = {};

  if (code) auth.code = code;
  else auth.apiKey = apiKey;
  return fetch(`${API_URL}/integrations/${integrationName}`, {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: `Bearer ${TOKEN()}`,
    },
    body: JSON.stringify({
      ...auth,
      clientId: CLIENT_ID_HUBSPOT,
      redirectUri: `${DASHBOARD_URL}/socialAuth/${integrationName}/`,
      domain: domain,
    }),
  }).then(convertToJsonAndHandleErrors);
}

export const integrationDeleteAccount = ({ integrationName }: any) => {
  return fetch(`${API_URL}/integrations/${integrationName}`, {
    method: 'DELETE',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};

export const getIntegrationProperties = ({ integrationName, sObject }: any) => {
  let url = `${API_URL}/integrations/${integrationName}/properties`;
  if (sObject) url += '?sObject=' + sObject;
  return fetch(url, {
    method: 'Get',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};

export const mappingIntegrationProperties = ({
  mappedProperties,
  integrationName,
  sObject,
}: any) => {
  return fetch(`${API_URL}/integrations/${integrationName}/mapping`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify({ properties: mappedProperties, sObject }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};
export const resetMappingIntegration = ({ integrationName }: any) => {
  return fetch(`${API_URL}/integrations/${integrationName}/mapping/reset`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};
export const getIntegrationsLists = ({ integrationName, getAll }: any) => {
  return fetch(`${API_URL}/integrations/${integrationName}/lists/`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify({ getAll }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};
export const addLeadsIntegration = (lead: any) => {
  return fetch(`${API_URL}/integrations/${lead.integrationName}/leads`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(lead),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};
export const getEmails = ({ integrationName }: any) => {
  return fetch(`${API_URL}/integrations/${integrationName}/getmessages`, {
    method: 'GET',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};
export const getAccounts = () => {
  return fetch(`${API_URL}/integrations/accounts`, {
    method: 'GET',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};

export async function getIntegrationKey(integrationName: any) {
  return fetch(`${API_URL}/integrations/${integrationName}/getKey`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${TOKEN()}`,
    },
  }).then(convertToJsonAndHandleErrors);
}

export const updateIntegrationKey = (integrationName: any) => {
  return fetch(`${API_URL}/integrations/${integrationName}/updateKey`, {
    method: 'PUT',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};

export const updateZap = (zap: any) => {
  return fetch(`${API_URL}/integrations/zapier/zap`, {
    method: 'PUT',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify({ zap }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};

export const deleteZap = (zap: any) => {
  return fetch(`${API_URL}/integrations/zapier/zap`, {
    method: 'DELETE',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify({ zap }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};

export const createEmailTemplate = ({ template, subject, title }: any) => {
  return fetch(`${API_URL}/integrations/emailTemplate/create`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify({ template, subject, title }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};

export const getEmailTemplates = ({}) => {
  return fetch(`${API_URL}/integrations/emailTemplate/list`, {
    method: 'GET',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};

export const updateEmailTemplate = ({ _id, template, subject, title }: any) => {
  return fetch(`${API_URL}/integrations/emailTemplate/update`, {
    method: 'PUT',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify({ _id, template, subject, title }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};

export const deleteEmailTemplate = ({ template }: any) => {
  return fetch(`${API_URL}/integrations/emailTemplate/delete`, {
    method: 'DELETE',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify({ template }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};

export const sendEmail = (emailOptions: any, lead: any) => {
  return fetch(`${API_URL}/integrations/gmail/sendEmail`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify({ emailOptions, lead }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};

export const saveEmailSignature = ({ signature }: any) => {
  return fetch(`${API_URL}/integrations/emailTemplate/signatures`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify({ signature }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};

export const getEmailSignature = () => {
  return fetch(`${API_URL}/integrations/emailTemplate/signatures`, {
    method: 'GET',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};
