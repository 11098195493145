import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import { BillingContext } from '../../store/billing.store';
import { PreLoader } from '../../../../components';
import { FormattedMessage, injectIntl } from 'react-intl';

import { CheckoutFormWrapper, Field } from '../common/common.tools';

import SubscriptionInfo from './SubscriptionInfo/SubscriptionInfo';

import DiscountSection from './DiscountSection/DiscountSection';

import { ExtraCreditsTable } from './ExtraCreditsTable/ExtraCreditsTable';

import BillingInfoSection from './BillingInfoSection/BillingInfoSection';
import { CREDITS } from '../../../../constants';
import './OrderSummary.css';
import NumberFormatter from '../../../../components/NumberFormatter/NumberFormatter';

function OrderSummary({ plan, organizationCredits, blockProceed, error, user, isFlagOn }: any) {
  const [couponModalToggle, setCouponModalToggle] = useState(false);
  //@ts-expect-error
  const { state } = useContext(BillingContext);

  if (!plan.credits) return <PreLoader />;
  const currentPlan = organizationCredits.plan;

  // Function to determine the message ID to display
  //@ts-expect-error
  const getMessageId = (plan) => {
    if (plan && plan.isUpgrade) {
      const upgrade = plan.isUpgrade;
      const { subscription } = upgrade;

      if (subscription === true) {
        // If 'subscription' is true, display 'checkout.upgradePlan'
        return 'checkout.upgradePlan';
      } else {
        // If 'subscription' is false and the others are false or null, display 'checkout.downgradePlan'
        return 'checkout.downgradePlan';
      }
    }

    // In all other cases, display 'checkout.subscribePlan'
    return 'checkout.subscribePlan';
  };

  // Calculate if there are any extra credits in the current plan
  let checkCurrentExtraCredits = CREDITS.filter((credit) => credit !== 'legacyCredits').some(
    (credit) => currentPlan[credit] && !!currentPlan[credit].extra,
  );

  // Calculate the total amount of extra credits
  const totalExtraCreditsAmount = plan.extraCredits.reduce(
    //@ts-expect-error
    (total, extraCredit) => total + extraCredit.unitPrice,
    0,
  );

  // Calculate the new subtotal amount including extra credits
  const subtotalAmountInCents = plan.subscriptionTotal + totalExtraCreditsAmount;

  return (
    <div id="order-summary-ctn">
      <div className="order-summary-wrapper">
        {isFlagOn['showUpdatedCheckoutPage'] && (
          <div className="btn-wrapper">
            <a href="/billing/plans" className="back-home-btn">
              <img src="/assets/images/logo_on_white.png" alt="Logo" className="logo-image" />
              <span className="back-text">
                <FormattedMessage id="billing.backToHome" />
              </span>
            </a>
          </div>
        )}{' '}
        <div className="plan-title-ctn">
          {plan && (
            <p className="plan-title">
              <FormattedMessage id={getMessageId(plan)} values={{ value: `${plan.name} Plan` }} />
            </p>
          )}
        </div>
        {/* PRICING TOP */}
        <div className="total-price-title">
          <div className="total-price-inner">
            <span>
              <NumberFormatter
                value={plan.total * 0.01}
                user={user}
                organizationCredits={organizationCredits}
                currency={true}
                decimals={true}
              />
            </span>
            <p>
              {plan.yearly ? (
                <FormattedMessage id="billing.plan.perYear" />
              ) : (
                <FormattedMessage id="billing.plan.perMonth" />
              )}
            </p>
          </div>
        </div>
        {/* SUBSCRIPTION PLAN */}
        <SubscriptionInfo
          plan={plan}
          currentPlan={currentPlan}
          user={user}
          organizationCredits={organizationCredits}
        />
        {/* EXTRA CREDITS */}
        {(plan.extraCredits.length > 0 || checkCurrentExtraCredits) && (
          <ExtraCreditsTable
            currentPlan={currentPlan}
            plan={plan}
            user={user}
            organizationCredits={organizationCredits}
          />
        )}
        {/* SUBTOTAL */}
        <div id="subtotal-ctn">
          <div className="subtotal-wrapper">
            <p>
              <FormattedMessage id="checkout.subtotal" />
            </p>
            <NumberFormatter
              value={subtotalAmountInCents * 0.01}
              user={user}
              organizationCredits={organizationCredits}
              currency={true}
              decimals={true}
            />
          </div>
        </div>
        {/* DISCOUNT / PROMOTIONAL CODE */}
        {organizationCredits.plan.planId === '0' && (
          <DiscountSection
            couponModalToggle={couponModalToggle}
            setCouponModalToggle={setCouponModalToggle}
            user={user}
            organizationCredits={organizationCredits}
          />
        )}
        {/* VAT 20% */}
        <BillingInfoSection
          plan={plan}
          currentPlan={currentPlan}
          user={user}
          organizationCredits={organizationCredits}
        />
        {/* DUE TODAY */}
        <div id="due-today-section">
          <div className="due-today-wrapper">
            <div>
              <p>
                <FormattedMessage id={'billing.dueToday'} />
              </p>
            </div>
            <NumberFormatter
              value={plan.total * 0.01}
              user={user}
              organizationCredits={organizationCredits}
              currency={true}
              decimals={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(OrderSummary);
