import React, { useEffect, useRef, useState } from 'react';
import './InputTags.css';
export default function InputTags({ onChange, profile, domains, placeholder }: any) {
  const inputRef = useRef(null);
  const tagsRef = useRef(null);
  const [tags, setTags] = useState([]);
  const [isIntersecting, setIntersecting] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

    observer.observe(inputRef.current);
    if (tagsRef.current && inputRef.current) {
      inputRef.current.style.paddingLeft = tagsRef.current.offsetWidth + 5 + 'px';
    }
  }, [tags, isIntersecting]);

  useEffect(() => {
    if (domains) {
      setTags(domains);
    }
  }, [domains]);
  const handleKeyUp = (e: any) => {
    const targetValue = e.target.value.trim();
    if ((e.keyCode === 32 || e.keyCode === 13) && targetValue) {
      setTags([...tags, targetValue]);

      inputRef.current.value = '';
      onChange([...tags, targetValue]);
    }
    if (e.keyCode === 8 && !e.target.value) {
      let newTags = [...tags];
      newTags.pop();
      if (tags.length > 0) {
        setTags([...newTags]);
        onChange([...newTags]);
      } else {
        setTags([]);
        onChange([]);
      }
    }
  };
  return (
    <div className={'input-tags'}>
      <input ref={inputRef} type="text" name="" placeholder={placeholder} onKeyUp={handleKeyUp} />

      <div className={'input-all-tags'} ref={tagsRef}>
        {tags.length >= 4 && (
          <span className={'input-tag'}>{'+' + (tags.length - tags.slice(3, 7).length)}</span>
        )}
        {tags.length < 4
          ? tags.map((value, i) => (
              <span key={i} className={'input-tag'}>
                {value}
              </span>
            ))
          : tags
              .map((value, i) => (
                <span key={i} className={'input-tag'}>
                  {value}
                </span>
              ))
              .slice(3, 7)}
      </div>
    </div>
  );
}
