import React from 'react';
import './ExtraCreditsTable.css';
import { FormattedMessage, useIntl } from 'react-intl';
import NumberFormatter from '../../../../../components/NumberFormatter/NumberFormatter';
import CurrencyDisplay from '../../../../../components/CurrencyDisplay/CurrencyDisplay';
import { CREDIT_COLORS } from '../../../../../constants';

function calculateExtraAmount(plan: any, currentPlan: any, extra: any) {
  if (plan.isPlanSuspended) {
    return extra.extra;
  }

  if (plan.isUpgrade[extra.creditType] === true) {
    return plan.numberOfMonths === currentPlan.numberOfMonths
      ? extra.addOn + extra.extra
      : extra.addOn;
  } else {
    return extra.addOn && plan.numberOfMonths === currentPlan.numberOfMonths
      ? extra.addOn + extra.extra
      : extra.addOn;
  }
}

export function ExtraCreditsTable({ plan, currentPlan, user, organizationCredits }: any) {
  const intl = useIntl();
  let extraCredits = [
    {
      ...currentPlan.phoneCredits,
      title: intl.formatMessage({ id: 'billing.addOnPhoneCredits' }),
      creditType: 'phoneCredits',
      addOn: null,
    },
    {
      ...currentPlan.personalEmailCredits,
      title: intl.formatMessage({ id: 'billing.addOnPersonalEmailCredits' }),
      creditType: 'personalEmailCredits',
      addOn: null,
    },
    {
      ...currentPlan.exportCredits,
      title: intl.formatMessage({ id: 'billing.addOnExportCredits' }),
      creditType: 'exportCredits',
      addOn: null,
    },
  ];

  extraCredits = extraCredits.map((extra) => {
    let addOn = plan.extraCredits.find((plan: any) => plan.creditType === extra.creditType);
    if (addOn) {
      extra.addOn =
        addOn.addedCredits &&
        addOn.addedCredits * (plan.isUpgrade[extra.creditType] === false ? -1 : 1);
      extra.amount = addOn.unitPrice / 100;
    }
    return extra;
  });

  const totalExtraCredits = extraCredits.reduce(
    (previous, current) => previous + (current.amount || 0),
    0,
  );

  if (totalExtraCredits === 0) return;

  return (
    <div id="extra-credits-section">
      <div className="extra-credits-wrapper">
        {extraCredits.map(
          (extra) =>
            calculateExtraAmount(plan, currentPlan, extra) > 0 && (
              <div className="extra-credits-items" key={extra.id}>
                <div>
                  <NumberFormatter
                    value={calculateExtraAmount(plan, currentPlan, extra)}
                    user={user}
                  />{' '}
                  {extra.title}{' '}
                </div>
                <NumberFormatter
                  value={extra.amount || 0}
                  user={user}
                  currency={true}
                  decimals={true}
                />
              </div>
            ),
        )}
      </div>
    </div>
  );
}
