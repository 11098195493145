import React, { useContext, useState } from 'react';
import { Field } from '../../common/common.tools';
import { FormattedMessage } from 'react-intl';
import { getSubscriptionInfos } from '../../../../../services/api/credits.api';
import { actions, BillingContext } from '../../../store/billing.store';
import { formatSubscribeInfo } from '../../../../../services/utils/tools';
import CurrencyDisplay from '../../../../../components/CurrencyDisplay/CurrencyDisplay';

export default function OldDiscountSection({
  couponModalToggle,
  setCouponModalToggle,
  user,
  organizationCredits,
}: any) {
  const [coupon, setCoupon] = useState(null);
  const [applyCoupon, setApplyCoupon] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  //@ts-expect-error
  const { state, dispatch } = useContext(BillingContext);
  const handleSubmitCoupon = async () => {
    const plan = state.selectedPlan;

    setLoading(true);
    const { data, statusCode } = await getSubscriptionInfos({
      numberOfSeats: plan.seats || plan.numberOfSeats,
      planId: plan.planId,
      isAnnual: plan.yearly,
      country: state.checkoutForm.country,
      numberOfMonths: plan.numberOfMonths,
      extraCredits:
        plan.extraCredits &&
        //@ts-expect-error
        plan.extraCredits.map((credit) => ({
          amount: credit.addedCredits,
          creditType: credit.creditType,
        })),
      coupon,
    });
    setLoading(false);

    if (statusCode === 200) {
      //@ts-expect-error
      const discount = data.items.find((item) => item.info === 'discount');
      setApplyCoupon(discount);

      //@ts-expect-error
      if (!data.ok && data.message) {
        //@ts-expect-error
        return setError(data.messageTranslated || data.message);
      }
      const planWithDiscount = {
        //@ts-expect-error
        ...data.plan,
        isPrivate: plan.isPrivate,
        //@ts-expect-error
        isUpgrade: data.isUpgrade,
        discount,
        ...formatSubscribeInfo(data),
        coupon,
      };
      planWithDiscount.subscriptionInfo = state.selectedPlan.subscriptionInfo;
      dispatch({
        type: actions.GET_SUBSCRIPTION_INFOS,
        payload: planWithDiscount,
      });
      setError(null);
    } else {
      //@ts-expect-error
      setError(data.messageTranslated || data.message);
    }
  };
  const handleCancelCoupon = () => {
    dispatch({
      type: actions.ON_CHECKOUT_FORM,
      payload: { ...state.checkoutForm, coupon: null },
    });
  };
  if (applyCoupon) {
    return (
      <div className="inline-info card-detail-ui">
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <FormattedMessage id={'billing.couponCode'} /> :
          <span
            className="section-info"
            style={{ marginLeft: 20, width: 'max-content', color: '#3b53d2' }}
          >
            {applyCoupon.coupon}{' '}
            <span style={{ marginLeft: 4, color: '#777' }}>{applyCoupon.percentage}%</span>
          </span>
        </span>
        <span>
          <strong>
            <CurrencyDisplay
              user={user}
              organizationCredits={organizationCredits}
              amount={applyCoupon.unitPrice / 100}
            />
          </strong>
        </span>
      </div>
    );
  }
  return (
    <div className="inline-info card-detail-ui discount">
      <div className="section-info" onClick={() => setCouponModalToggle(!couponModalToggle)}>
        <span>
          <FormattedMessage id={'billing.haveACoupon'} />
        </span>{' '}
        {!couponModalToggle ? (
          <i className="fas fa-chevron-right"></i>
        ) : (
          <i className="fas fa-chevron-left"></i>
        )}
      </div>
      {couponModalToggle && (
        <div className={'inline-info'}>
          <Field
            //@ts-expect-error
            onChange={(e) => setCoupon(e.target.value)}
            value={coupon}
            placeholder={'coupon'}
          />
          {loading ? (
            <button style={{ zIndex: 0 }} type={'button'} className={'btn btn1'}>
              <img src={'/assets/images/loading.gif'} width={25} />
            </button>
          ) : (
            <button
              style={{ zIndex: 0 }}
              type={'button'}
              className={'btn btn1'}
              onClick={handleSubmitCoupon}
            >
              <FormattedMessage id={'billing.apply'} />
            </button>
          )}
          <div className={'has-error'}>{error}</div>
        </div>
      )}
    </div>
  );
}
