import cookie from 'react-cookies';

/**
 * LOCAL CONSTANTS
 */
//let PUBLIC_API_URL = "http://localhost:5001";

/*let API_URL = "http://localhost:8000";
let DASHBOARD_URL = "http://localhost:3000";
//let PUBLIC_API_URL = "http://localhost:5001";*/
let PUBLIC_API_URL = 'https://public-api.staging.kaspr.io';
let API_URL = 'https://staging.api.kaspr.io';
let DASHBOARD_URL = 'http://localhost:3000';
let BASE_URL = 'localhost';

let STRIPE_PUBLIC_KEY =
  'pk_test_51HUaIrJEQVJnnIbb69k6N7Twb9niS3vRYOX3Plmv5d3JeUceQaOgxEm3aZXls6S1lw5ljQRoB9n9F5AiVUaa8Ats00tDC2blfY';
let CHROME_FAVICON_PLUGIN = 'chrome-extension://pnhcoiphjhieieclnejdjdkabibdnknb/favicon.ico';
let CHROME_STORE =
  'https://chrome.google.com/webstore/detail/kaspr-find-email-addresse/kkfgenjfpmoegefcckjklfjieepogfhg';
let EXTENSION_ID = 'cjhnnajomejiijlhehngoeacnlbheddp';
let CLIENT_ID_SALESFORCE =
  '3MVG9SOw8KERNN08OQVLi_46lcVunscbmBN6IF7qTYjYYQcRR9fI5waAya8a3YNmc0Wbc3w19kdqu8RPFr15W';
let CLIENT_ID_HUBSPOT = 'dc5ab32c-3581-4cd4-aae9-5399b375514d';
let CLIENT_ID_ZOHO = '1000.3Z4GHWQZLSZP8V39OSU2Q15W58F53R';
let CLIENT_ID_LIVE = 'c2212363-0392-40e4-9393-c8f38674108d';
let CLIENT_ID_AIRCALL = 'sMTo6CHSWB8qPm3XoMZW8OlYup05oBDltUSpLfexEXg';
let CLIENT_ID_GMAIL = '24195422270-vk1i9pchot7uqgfa2hus4nu1gl1116dq.apps.googleusercontent.com';
let CLIENT_ID_GOOGLE_SSO =
  '535852896393-9btnc16dgseppabhdgk1j09n8u05fjkm.apps.googleusercontent.com';
let FIRST_PROMOTER_KEY = '2318620db505c18f79dfcbf60e298ae0';
let SENTRY_DSN = null;
let IS_PROD = false;
let MAX_LICENCES = 2000;
let LAUNCHDARKLY_CLIENT_SIDE_ID = '66d58a5735547d0f1f9bb025';
let MIXPANEL_TOKEN = 'c551bbb28a7e050405c9b5406948ff4c';

/**
 * LOCAL ENV
 */

if (process.env.REACT_APP_LOCAL_ENV == 'local') {
  API_URL = 'http://localhost:8000';
  DASHBOARD_URL = 'http://localhost:3000';
  PUBLIC_API_URL = 'http://localhost:5001';
  EXTENSION_ID = 'dcfebfcecpfgaknfpomkfbfhpegfmkfe';
  LAUNCHDARKLY_CLIENT_SIDE_ID = '66d58a5735547d0f1f9bb025';
}

if (process.env.REACT_APP_LOCAL_ENV == 'docker') {
  API_URL = 'http://gateway-api:8000';
  DASHBOARD_URL = 'http://website-dashboard-v2:3000';
  PUBLIC_API_URL = 'http://localhost:5002';
  EXTENSION_ID = 'dcfebfcecpfgaknfpomkfbfhpegfmkfe';
  LAUNCHDARKLY_CLIENT_SIDE_ID = '66d58a5735547d0f1f9bb025';
}

if (process.env.REACT_APP_LOCAL_ENV == 'prod') {
  API_URL = 'https://api.kaspr.io';
  DASHBOARD_URL = 'https://app.kaspr.io';
  PUBLIC_API_URL = 'https://api.developers.kaspr.io';
  EXTENSION_ID = 'dcfebfcecpfgaknfpomkfbfhpegfmkfe';
  LAUNCHDARKLY_CLIENT_SIDE_ID = '66d58a5735547d0f1f9bb025';
}

/**
 * PREPROD CONSTANTS :
 */

if (process.env.REACT_APP_ENVIRONMENT == 'preprod') {
  API_URL = 'https://staging.api.kaspr.io';
  DASHBOARD_URL = 'https://staging.kaspr.io';
  BASE_URL = 'staging.kaspr.io';
  CHROME_FAVICON_PLUGIN = 'chrome-extension://pnhcoiphjhieieclnejdjdkabibdnknb/favicon.ico';
  CHROME_STORE =
    'https://chrome.google.com/webstore/detail/kaspr-find-email-addresse/kkfgenjfpmoegefcckjklfjieepogfhg';
  CLIENT_ID_SALESFORCE =
    '3MVG9SOw8KERNN08OQVLi_46lcUkEOyF2LJdzXCGxYFgJG9SHjPM7SUiBBn96CTM2967Lt7pJ2qlt4p6VmKLw';
  CLIENT_ID_HUBSPOT = 'f6e3d01d-e647-4625-bd7e-ce23bde907f8';
  CLIENT_ID_GMAIL = '107551109911-ujah16vmog5cp263ito87u1lgvfd2o3a.apps.googleusercontent.com';
  CLIENT_ID_GOOGLE_SSO = '535852896393-9btnc16dgseppabhdgk1j09n8u05fjkm.apps.googleusercontent.com';
  SENTRY_DSN = 'https://4d31af50b879457e87ae1ca61047b922@o300958.ingest.sentry.io/1852674';
  STRIPE_PUBLIC_KEY =
    'pk_test_51HUaIrJEQVJnnIbb69k6N7Twb9niS3vRYOX3Plmv5d3JeUceQaOgxEm3aZXls6S1lw5ljQRoB9n9F5AiVUaa8Ats00tDC2blfY';
  EXTENSION_ID = 'cjhnnajomejiijlhehngoeacnlbheddp';
  LAUNCHDARKLY_CLIENT_SIDE_ID = '66d58a5735547d0f1f9bb025';
  MIXPANEL_TOKEN = 'c551bbb28a7e050405c9b5406948ff4c';
}
/**
 * PROD CONSTANTS
 */

if (process.env.REACT_APP_ENVIRONMENT == 'prod') {
  API_URL = 'https://api.kaspr.io';
  PUBLIC_API_URL = 'https://api.developers.kaspr.io';
  DASHBOARD_URL = 'https://app.kaspr.io';
  BASE_URL = 'app.kaspr.io';
  STRIPE_PUBLIC_KEY = 'pk_live_hDNZRXtocrbRFu81AY0kU46U';
  CHROME_FAVICON_PLUGIN = 'chrome-extension://kkfgenjfpmoegefcckjklfjieepogfhg/favicon.ico';
  CHROME_STORE =
    'https://chrome.google.com/webstore/detail/kaspr-find-email-addresse/kkfgenjfpmoegefcckjklfjieepogfhg';
  EXTENSION_ID = 'kkfgenjfpmoegefcckjklfjieepogfhg';
  CLIENT_ID_SALESFORCE =
    '3MVG9tzQRhEbH_K1KGDJlnXhFhZo_X0IVRFvPEBsQxxUJ_ocM1BEvh00CdRPnIQUWs2HY9v8epJE5PNR9jtZF';
  CLIENT_ID_LIVE = '76c12cae-3cd4-420a-a27d-3c5a6c14c4fc';
  CLIENT_ID_AIRCALL = 'nCC9wJmKBtPpe9Z0LrQNdEmq5vJZoNPj6OuwvNLGo7Y';
  CLIENT_ID_GMAIL = '276793292516-b9bdloo496fdn52vt72ipfdr56au8qt4.apps.googleusercontent.com';
  CLIENT_ID_GOOGLE_SSO = '535852896393-ehmmk5576564bpdin5rfflpm2mgq89fs.apps.googleusercontent.com';
  SENTRY_DSN = 'https://2ecb2580c68842b99068b865a4d4e6b8@o448876.ingest.sentry.io/6313698';
  IS_PROD = true;
  LAUNCHDARKLY_CLIENT_SIDE_ID = '66d58a5735547d0f1f9bb026';
  MIXPANEL_TOKEN = 'cb95c6ce611fda52d802be48053c6638';
}
/**
 *  GLOBAL CONSTANTS
 */
export const ACCESS_TOKEN = 'access_token';
export const LANDINGPAGE_URL = 'https://kaspr.fr';
export const headers = {
  Accept: 'application/json, text/plain, */*',
  'Content-Type': 'application/json',
};
const CREDIT_COLORS: any = {
  phoneCredits: {
    color: '#0EB763',
    icon: '/assets/images/phone-credits.svg',
    creditMap: 'phone',
  },
  directEmailCredits: {
    color: '#FF206E',
    icon: '/assets/images/direct-email-credits.svg',
    creditMap: 'workEmail',
  },
  personalEmailCredits: {
    color: '#FF206E',
    icon: '/assets/images/direct-email-credits.svg',
    creditMap: 'personalEmail',
  },
  workEmailCredits: {
    color: '#4D58F3',
    icon: '/assets/images/work-email-credits.svg',
    creditMap: 'workEmail',
  },
  exportCredits: {
    color: '#B149FF',
    icon: '/assets/images/export-credits.svg',
  },
  legacyCredits: {
    color: '#333333',
    icon: '/assets/images/legacy-credits.svg',
  },
};
const CREDITS = [
  'workEmailCredits',
  'exportCredits',
  'phoneCredits',
  'personalEmailCredits',
  'legacyCredits',
];

const CURRENCY_MAP: any = {
  USD: '$',
  EUR: '€',
  GBP: '£',
};
export const TOKEN = () => {
  return cookie.load(ACCESS_TOKEN);
};

const SOURCE_BUSTER_CONFIG = {
  domain: {
    host: 'kaspr.io',
    isolate: true,
  },
  referrals: [
    { host: 't.co', medium: 'social', display: 'twitter.com' },
    { host: 'm.facebook.com', medium: 'social', display: 'facebook.com' },
    { host: 'l.facebook.com', medium: 'social', display: 'facebook.com' },
    {
      host: 'lm.facebook.com',
      medium: 'social',
      display: 'facebook.com',
    },
    { host: 'facebook.com', medium: 'social' },
    { host: 'linkedin.com', medium: 'social' },
    { host: 'instagram.com', medium: 'social' },
    {
      host: 'l.instagram.com',
      medium: 'social',
      display: 'instagram.com',
    },
    {
      host: 'm.instagram.com',
      medium: 'social',
      display: 'instagram.com',
    },
    {
      host: 'lm.instagram.com',
      medium: 'social',
      display: 'instagram.com',
    },
  ],
};

const ADMIN_SETTINGS = {
  type: 'admin',
  permissions: [
    {
      name: 'Leads',
      actions: ['View', 'Edit', 'Export', 'Save'],
    },
    {
      name: 'Workflow',
      actions: ['View', 'Edit', 'Execute'],
    },
    {
      name: 'Integration',
      actions: ['Export', 'Mapping', 'Sync'],
    },
  ],
  limit: {
    legacyCredits: -1,
    workEmailCredits: -1,
    personalEmailCredits: -1,
    phoneCredits: -1,
    exportCredits: -1,
  },
};

export {
  API_URL,
  PUBLIC_API_URL,
  DASHBOARD_URL,
  STRIPE_PUBLIC_KEY,
  CHROME_FAVICON_PLUGIN,
  CHROME_STORE,
  BASE_URL,
  EXTENSION_ID,
  CLIENT_ID_SALESFORCE,
  CLIENT_ID_HUBSPOT,
  CLIENT_ID_LIVE,
  CLIENT_ID_ZOHO,
  CLIENT_ID_AIRCALL,
  SENTRY_DSN,
  IS_PROD,
  FIRST_PROMOTER_KEY,
  CLIENT_ID_GMAIL,
  CLIENT_ID_GOOGLE_SSO,
  MAX_LICENCES,
  LAUNCHDARKLY_CLIENT_SIDE_ID,
  CREDIT_COLORS,
  CREDITS,
  CURRENCY_MAP,
  SOURCE_BUSTER_CONFIG,
  ADMIN_SETTINGS,
  MIXPANEL_TOKEN,
};
