import React from 'react';
import NumberFormatter from '../../../../../components/NumberFormatter/NumberFormatter';
import './BillingInfoSection.css';
import { FormattedDate, FormattedMessage } from 'react-intl';

import CurrencyDisplay from '../../../../../components/CurrencyDisplay/CurrencyDisplay';

export default function BillingInfoSection({ plan, user, organizationCredits }: any) {
  return (
    <div id="vat-section">
      <div className="vat-section-wrapper">
        {plan.vat && (
          <div className="vat-row">
            <p>
              <FormattedMessage
                id={'billing.vat'}
                values={{
                  value: plan.vat.percentage,
                }}
              />
            </p>

            {plan.vat.unitPrice && (
              <div className="vat-amount">
                <NumberFormatter
                  value={plan.vat.unitPrice * 0.01}
                  user={user}
                  currency={true}
                  decimals={true}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
