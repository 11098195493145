import React from 'react';
import './ConnectionModalLeft.css';
import { integrationsDetailedDescriptions } from '../../../../constants';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';

function ConnectionModalLeft({ integration }: any) {
  //@ts-expect-error
  const IntegrationData = integrationsDetailedDescriptions[integration];
  const intl = useIntl();
  return (
    <div id="connection-modal-left-container">
      <div className="description-container">
        {IntegrationData.sections.map((section: any, key: any) => (
          <div className="paragraph" key={key}>
            <h1>
              <FormattedMessage
                id={section.title}
                values={{
                  value: IntegrationData.name,

                  br: <br />,
                }}
              />
            </h1>

            <p>
              <FormattedMessage
                id={section.text}
                values={{
                  value: IntegrationData.name,

                  br: <br />,
                }}
              />
            </p>
          </div>
        ))}
        {IntegrationData.help && (
          <div className="help">
            <h1>
              <FormattedMessage id="settings.integration.help" />
            </h1>
            {IntegrationData.help.map((link: any, index: any) => (
              <a
                href={link[intl.locale]}
                target="_blank"
                rel="noopener noreferrer"
                className="btn9"
                key={index}
              >
                <FormattedMessage id={link.title} values={{ value: IntegrationData.name }} />
              </a>
            ))}
          </div>
        )}
      </div>

      <div>
        <img
          src={`/assets/images/${IntegrationData.imageSrc}.png`}
          alt={IntegrationData.name + ' image'}
        />
      </div>
    </div>
  );
}

export default ConnectionModalLeft;
