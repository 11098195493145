import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../Store/auth.store';
import { Col, Container, Row } from 'react-bootstrap';
import { Navigate, NavLink } from 'react-router-dom';
import './Signin.css';

import { Field } from '../common/Field/Field';
import actions from '../Store/auth.actions';
import { checkEmail, login } from '../../../services/api/auth.api';
import { saveAsCookie } from '../../../services/utils';
import queryString from 'query-string';
import { isMobile, isMobileSafari } from 'react-device-detect';
import { injectIntl } from 'react-intl';
import { EXTENSION_ID } from '../../../constants';
import { FormattedMessage } from 'react-intl';
import { useGoogleLogin } from '@react-oauth/google';
import { googleSSO } from '../../../services/api/auth.api';

function SigninForm({ intl }: any) {
  //@ts-expect-error
  const { state, dispatch } = useContext(AuthContext);
  const [error, setError] = useState(null);
  const [errorSSO, setErrorSSO] = useState(null);
  const [isCheck, setCheckEmail] = useState(true);
  const [hidePassword, setHidePassword] = useState(true);
  const [mobilePage, setMobilePage] = useState({});
  const [extensionLoginTab, setExtentionLoginTab] = useState(null);
  const [loadingSSO, setLoadingSSO] = useState(null);

  //@ts-expect-error
  useEffect(() => {
    let { redirect, email, tab }: any = queryString.parse(window.location.search);
    if (tab) {
      setExtentionLoginTab(tab);
    }
    if (email) {
      const encodedMail = btoa(email);

      window.googleTagManager('try-kaspr');
      //@ts-expect-error
      return (window.location = '/signup/' + encodedMail);
    }
    if (redirect) {
      setCheckEmail(false);
    }
  }, []);

  const handleOnSubmit = async (e: any) => {
    e.preventDefault();
    const { data, statusCode } = await login({
      ...state.loginForm,
      email: state.loginForm.email.trim(),
    });
    if (statusCode === 200) {
      //@ts-expect-error
      saveAsCookie(data.jwt);

      if (window.chrome && window.chrome.runtime && window.chrome.runtime.sendMessage) {
        if (extensionLoginTab) {
          window.chrome.runtime.sendMessage(EXTENSION_ID, {
            action: 'LOG_IN_FROM_EXTENSION',
            input: { tabId: extensionLoginTab },
          });
        } else {
          window.chrome.runtime.sendMessage(EXTENSION_ID, {
            action: 'REFRESH_WHEN_CONNECTED',
            input: { isConnected: true },
          });
        }
      }
      //@ts-expect-error
      window.location = '/';
    } else {
      setCheckEmail(false);
      //@ts-expect-error
      setError(data.messageTranslated || data.message);
    }
  };

  const handleCheckEmail = async (e: any) => {
    e.preventDefault();
    const { data, statusCode } = await checkEmail({
      email: state.loginForm.email.trim(),
      isMobile: isMobile || isMobileSafari,
    });
    if (statusCode === 200 && (isMobile || isMobileSafari)) {
      return setMobilePage({
        isMobilePage: true,
        //@ts-expect-error
        isKasprUser: data.isKasprUser,
      });
    }
    if (statusCode >= 400) {
      //@ts-expect-error
      setError(data.messageTranslated || data.message);
      return;
    }
    setError(null);
    //@ts-expect-error
    if (data.isKasprUser) {
      setCheckEmail(false);
    } else {
      dispatch({
        type: actions.SIGNUP,
        payload: { email: state.loginForm.email },
      });
      const encodedMail = btoa(state.loginForm.email);

      window.location.href = '/signup/' + encodedMail;
    }
  };

  //@ts-expect-error
  const handleLoginSuccess = async (credentialResponse) => {
    // Prepare signup form data
    const signupForm = {
      ...state.signupForm,
      token: credentialResponse.code,
    };

    setLoadingSSO(true);

    const { data, statusCode } = await googleSSO(signupForm);

    if (statusCode === 200) {
      // Save token in local storage
      //@ts-expect-error
      saveAsCookie(data.jwt);
      // Redirect user after connection
      window.location.href = '/';
    } else if (statusCode >= 400) {
      //@ts-expect-error
      if (data.message === 'USER_DOES_NOT_EXIST') {
        setErrorSSO(intl.formatMessage({ id: 'checkout.userDoesNotExist' }));
      } else {
        //@ts-expect-error
        if (data.messageTranslated) setErrorSSO(data.messageTranslated);
      }
    }

    setLoadingSSO(false);
  };

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    //@ts-expect-error
    onSuccess: (credentialResponse) => handleLoginSuccess(credentialResponse),
  });

  return (
    <div id="signin-main-container">
      <FormWrapper intl={intl}>
        <form onSubmit={isCheck ? handleCheckEmail : handleOnSubmit}>
          <div className="form-blocks">
            <Field
              //@ts-expect-error
              label={intl.formatMessage({ id: 'preSignUp.workEmail' })}
              className={'active'}
              onChange={(email: any) => dispatch({ type: actions.LOGIN, payload: { email } })}
              value={state.loginForm.email}
              formType={'signin'}
            />
            {!isCheck && (
              <div>
                <Field
                  //@ts-expect-error
                  label={intl.formatMessage({ id: 'info.password' })}
                  onChange={(password: any) =>
                    dispatch({ type: actions.LOGIN, payload: { password } })
                  }
                  type={hidePassword ? 'password' : 'text'}
                  formType={'signin'}
                />

                <div style={{ textAlign: 'center', marginBottom: 10 }}>
                  <NavLink to="/forgot" className="forgot-btn" style={{ marginRight: '10px' }}>
                    {intl.formatMessage({ id: 'auth.forgotPassword' })}
                  </NavLink>
                </div>
              </div>
            )}
          </div>
          {isCheck ? (
            <div className="btn-grp text-center">
              <button className="btn btn-blue btn-block h-52 proceed-btn" type={'submit'}>
                {intl.formatMessage({ id: 'auth.proceed' })}
              </button>
            </div>
          ) : (
            <div className="btn-grp text-center">
              <button className="btn btn-blue btn-block h-52" type={'submit'}>
                {intl.formatMessage({ id: 'auth.letsGo' })}
              </button>
            </div>
          )}
          <div className="separator-ctn">
            <div className="hr"></div>
            <p style={{ textTransform: 'lowercase', color: '#797F99' }}>
              <FormattedMessage id="organization.or" />
            </p>
            <div className="hr"></div>
          </div>
          {/* GOOGLE SSO SIGNIN */}
          <div
            onClick={() => {
              if (!loadingSSO) {
                setErrorSSO(null);
                googleLogin();
              }
            }}
            className="sso-btn"
          >
            {loadingSSO ? (
              <div className="sso-centered">
                <img src="/assets/images/loading.gif" alt="" style={{ margin: 'auto' }} />{' '}
              </div>
            ) : (
              <span className="sso-centered">
                <img src="/assets/images/google-icon.svg" alt="google icon" />
                <FormattedMessage id="preSignUp.loginWithGoogle" />
              </span>
            )}
          </div>
          {error && (
            <div
              className="btn-grp text-center  error  kaspr-form-text-error"
              style={{
                top: '0.75rem',
                width: '100%',
              }}
            >
              {error + ' '}
            </div>
          )}
          {errorSSO && (
            <div
              className="btn-grp text-center  error  kaspr-form-text-error"
              style={{
                top: '0.75rem',
                width: '100%',
              }}
            >
              {errorSSO + ' '}
            </div>
          )}
        </form>
      </FormWrapper>

      <Footer intl={intl} />
      {
        //@ts-expect-error
        mobilePage.isMobilePage && (
          <Navigate
            to={{
              pathname: '/checkdevice',
              //@ts-expect-error
              state: { isKasprUser: mobilePage.isKasprUser },
            }}
            replace
          />
        )
      }
    </div>
  );
}

function Footer({ intl }: any) {
  return (
    <footer>
      <div className="container">
        <ul className="footer-list">
          <li>©{new Date().getFullYear()} Kaspr</li>

          <li>·</li>

          <li>
            <p>
              <a target="_blank" href="https://www.kaspr.io/privacy-policy">
                {intl.formatMessage({ id: 'auth.privacy' })}
              </a>{' '}
              &amp;{' '}
              <a target="_blank" href="https://kaspr.io/terms/">
                {intl.formatMessage({ id: 'auth.terms' })}
              </a>
            </p>
          </li>
        </ul>
      </div>
    </footer>
  );
}

function FormWrapper({ children, intl }: any) {
  return (
    <section className="signup-wrapper">
      <Container>
        <Row>
          <Col md={6} className="mx-auto">
            <div
              className="white-bg padding40 border-radius-12 mx-auto mt-5"
              style={{ maxWidth: '450px' }}
            >
              <div className="form-heading-grp text-center">
                <h4 className="h4">{intl.formatMessage({ id: 'auth.welcome' })}</h4>

                <p>{intl.formatMessage({ id: 'auth.itsGreatToSeeYou' })}</p>
              </div>
              {children}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default injectIntl(SigninForm);
