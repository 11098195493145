import React, { Component, useEffect, useState } from 'react';
import qs from 'querystring';
import './style.css';
import { integrationAuthConnect } from '../../../services/api/integrations.api';
import { messages_en } from '../../../services/translations';
import { CLIENT_ID_ZOHO } from '../../../constants';
import { useLocation } from 'react-router-dom';

export default function AuthComplete(props: any) {
  const [state, setState] = useState({
    loading: false,
    error: false,
    login: '',
    isCrmVerified: '',
  });
  const location = useLocation();

  useEffect(() => {
    (async () => {
      const code = qs.parse(location.search)['?code'];
      setState({ ...state, loading: true });
      let error = null;
      let email = '';
      let result = null;
      let isCrmVerified = false;
      if (code && code !== 'undefined') {
        try {
          if (location.pathname.indexOf('hubspot') > -1) {
            result = await integrationAuthConnect({
              code,
              integrationName: 'hubspot',
            });

            if (result && result.statusCode >= 400) {
              setState({
                ...state,
                //@ts-expect-error
                error: result.data.messageTranslated || result.data.message,
              });
              //@ts-expect-error
              error = result.data.messageTranslated || result.data.message;
            } else {
              //@ts-expect-error
              email = result && result.data && result.data['hubspotEmail'];
            }
          } else if (location.pathname.indexOf('zoho') > -1) {
            //@ts-expect-error
            const domain = qs.parse(location.search)['location'].toUpperCase();
            result = await integrationAuthConnect({
              code,
              integrationName: 'zohocrm',
              domain,
            });
            //@ts-expect-error
            email = result && result.data && result.data['zohocrmEmail'];
            if (result && result.statusCode >= 400) {
              setState({
                ...state,
                //@ts-expect-error
                error: result.data.messageTranslated || result.data.message,
              });
              //@ts-expect-error
              error = result.data.messageTranslated || result.data.message;
            }
          } else if (location.pathname.indexOf('salesforce') > -1) {
            result = await integrationAuthConnect({
              code,
              integrationName: 'salesforce',
            });
            if (result && result.statusCode >= 400) {
              setState({
                ...state,
                //@ts-expect-error
                error: result.data.messageTranslated || result.data.message,
              });
              //@ts-expect-error
              error = result.data.messageTranslated || result.data.message;
            }
            //@ts-expect-error
            email = result && result.data && result.data['salesforceEmail'];
          }
          setState({
            ...state,
            login: email,
            //@ts-expect-error
            isCrmVerified: result && result.data && result.data.isCrmVerified,
          });
          //@ts-expect-error
          isCrmVerified = result && result.data && result.data.isCrmVerified;
        } catch (err) {
          setState({ ...state, loading: false, error: err, login: 'err' });
          error = err;
        } finally {
          localStorage.setItem('login', state.login || email);
          //@ts-expect-error
          localStorage.setItem('isCrmVerified', state.isCrmVerified || isCrmVerified);
          //@ts-expect-error
          if (state.error) localStorage.setItem('loginError', state.error);
          if (error) {
            localStorage.setItem('loginError', error);
            localStorage.setItem('login', 'err');
          }
          setState({ ...state, loading: false });
          window.open('', '_parent', '');
          window.close();
        }
      }
    })();
  }, []);
  return (
    <div className="AuthComplete">
      {state.loading && (
        <img src={'/assets/images/loading.gif'} alt={'loading'} style={{ height: '110px' }} />
      )}

      {state.error && <div>{state.error}</div>}
    </div>
  );
}
