import React, { useContext } from 'react';
import { Pagination, Permissions, SelectPicker } from '../../../../components';
import { FormattedMessage } from 'react-intl';
import { WorkflowContext, actions } from '../../store/workflow.store';
import { getWorkflows } from '../../../../services/api/workflows.api';
export default function MyWorkflowsFilters({
  page,
  handleGoToPage,
  setModalToggle,
  checkPlanPermissions,
  checkMemberPermissions,
}: any) {
  //@ts-expect-error
  const { state, dispatch } = useContext(WorkflowContext);
  const handleSort = async (sortBy: any) => {
    dispatch({
      type: actions.WORKFLOWS_FILTERS,
      payload: { sortBy, filterBy: state.workflowsFilters.filterBy },
    });
    getWorkflows({
      filters: state.workflowsFilters.filterBy ? { type: [state.workflowsFilters.filterBy] } : {},
      sortOrder: sortBy,
      page: page - 1,
    }).then(({ data, statusCode }) => {
      if (statusCode == 200) {
        dispatch({ type: actions.GET_WORKFLOWS, payload: data });
      }
    });
  };
  const handleFilter = (filterBy: any) => {
    dispatch({
      type: actions.WORKFLOWS_FILTERS,
      payload: { filterBy, sortBy: state.workflowsFilters.sortBy },
    });
    getWorkflows({
      filters: filterBy ? { type: [filterBy] } : {},
      sortOrder: state.workflowsFilters.sortBy || null,
    }).then(({ data, statusCode }) => {
      if (statusCode == 200) {
        dispatch({ type: actions.GET_WORKFLOWS, payload: data });
      }
    });
  };
  return (
    <div>
      <div className="head_block" style={{ marginTop: 0 }}>
        <div className="left_block" style={{ alignItems: 'center' }}>
          <div className="title-box">
            {state.workflows.pages && state.workflows.pages.totalWorkflows > 0 && (
              <p>{state.workflows.pages.totalWorkflows} Workflows</p>
            )}
          </div>

          <div className="cstm-select date-sorting">
            <div className="sorting-block">
              <label>
                {' '}
                <FormattedMessage id={'filters.sortBy'} />:
              </label>

              <SelectPicker
                doNotCapitalize
                values={['descending', 'ascending']}
                onChange={handleSort}
                type={'sortBy'}
                labelsStyle={{ width: 'max-content' }}
              />
            </div>
          </div>

          <div className="sorting-block" style={{ marginLeft: 20 }}>
            <label>
              <FormattedMessage id={'workflow.type'} />
            </label>

            <SelectPicker
              doNotCapitalize
              values={[
                { id: null, name: 'All' },
                { id: 'integration', name: 'Integration' },
                { id: 'enrichment', name: 'Enrichment' },
                { id: 'linkedin', name: 'Linkedin' },
              ]}
              onChange={handleFilter}
              withId
              type={'sortBy'}
              labelsStyle={{ width: 'max-content' }}
            />
          </div>
        </div>

        <div className="right_block">
          <div className="cstm-pagination pagination_one">
            <Pagination
              pages={state.workflows.pages.pages}
              currentPage={page}
              goToPage={handleGoToPage}
              top
            />
          </div>
          {!checkPlanPermissions('Edit') || !checkMemberPermissions('Edit') ? (
            <Permissions.CallToAction
              isButton={true}
              isPlan={!checkPlanPermissions('Edit')}
              toolTipStyle={{ width: 250 }}
              toolTipPosition={'left'}
            >
              <a className="border-plus-icon">
                <img src="/assets/images/ic_insert_variable-w.svg" alt="" />
              </a>

              <FormattedMessage id={'workflow.createWorkflow'} />
            </Permissions.CallToAction>
          ) : (
            <button className="btn1" onClick={() => setModalToggle(true)}>
              <a className="border-plus-icon">
                <img src="/assets/images/ic_insert_variable-w.svg" alt="" />
              </a>

              <FormattedMessage id={'workflow.createWorkflow'} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
