import React, { useEffect, useState, memo } from 'react';
import './Unpaid.css';
import { isAdmin } from '../../services/utils/tools';
import { PreLoader } from '../../components';
import { FormattedMessage } from 'react-intl/lib';
import { getOrgAdmins } from '../../services/api/organization.api';

const Unpaid = memo((props) => {
  const [isOwnerAdmin, setIsOwnerAdmin] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [admins, setAdmins] = useState([]);

  useEffect(() => {
    //@ts-expect-error
    if (props.organization.organization) {
      setIsLoading(false);
      //@ts-expect-error
      setIsOwnerAdmin(isAdmin(props.organization));
      //@ts-expect-error
      getOrgAdmins().then(({ data, statusCode }) => {
        if (statusCode == 200) {
          setAdmins(
            //@ts-expect-error
            data.admins.map((admin: any) => ({
              email: admin.email,
              checked: false,
            })),
          );
        }
      });
    } else {
      setIsLoading(true);
    }
    //@ts-expect-error
  }, [props.organization]);

  if (isLoading) {
    return <PreLoader />;
  }
  const showPopup =
    //@ts-expect-error
    props.credits &&
    //@ts-expect-error
    props.credits.organizationCredits &&
    //@ts-expect-error
    props.credits.organizationCredits.plan &&
    //@ts-expect-error
    props.credits.organizationCredits.plan.planId !== 'plan_1' &&
    //@ts-expect-error
    props.credits.organizationCredits.plan.maxAdmins === admins.length &&
    //@ts-expect-error
    !isAdmin(props.organization);

  return (
    <div className="unpaid-container">
      <div className="top-container">
        <img src="/assets/images/logo_on_white.png" alt="Kaspr logo" />

        <p>
          <strong>
            {' '}
            <FormattedMessage id={'unpaid.mainText'} />
          </strong>

          <br />
          {/* @ts-expect-error */}
          {!isAdmin(props.organization) ? (
            <FormattedMessage id={'unpaid.proceedPaymentOrgContactAdmin'} />
          ) : (
            <FormattedMessage id={'unpaid.proceedPaymentText'} />
          )}

          <FormattedMessage id={'unpaid.descriptionText'} />
        </p>
      </div>

      <div className="buttons">
        {/* @ts-expect-error */}
        {!isAdmin(props.organization) && (
          <a href="/notify" className="btn btn2 btn-md">
            <FormattedMessage id={'unpaid.warnAdmin'} />
          </a>
        )}

        {/* <a
          href={"/checkout" + (showPopup ? "?admins=true" : "")}
          className="btn btn1 btn-md"
        >
          <FormattedMessage id={"unpaid.updateBillingInfo"} />
        </a>*/}

        <a
          //@ts-expect-error
          href={props.credits && props.credits.hosted_invoice_url}
          target={'_blank'}
          className="btn btn1 btn-md"
        >
          <FormattedMessage id={'unpaid.updateBillingInfo'} />
        </a>
      </div>
    </div>
  );
});

export default Unpaid;
