import { useMemo } from 'react';

const useUserSteps = (redux: any, nextStep: any) => {
  const steps = redux.profile.steps || [];

  const isStepDone = (stepId: any) => {
    const step = steps.find((stp: any) => stp.id === stepId);
    return step && step.done;
  };

  const isStepPending = (stepId: any) => {
    const step = steps.find((stp: any) => stp.id === stepId);
    return step && !step.done;
  };

  const isStepExists = (stepId: any) => {
    return steps.some((stp: any) => stp.id === stepId);
  };

  // Check if the user got the joinWorkspace step
  const oldUser = useMemo(
    () => !isStepExists('joinWorkspace') || !isStepExists('postsignup'),
    [steps],
  );

  // Steps status
  const isEmailValidationDone = useMemo(() => isStepDone('emailValidation'), [steps]);
  const isPostSignUpDone = useMemo(() => isStepDone('postSignUp'), [steps]);
  const isJoinWorkspaceDone = useMemo(() => isStepDone('joinWorkspace'), [steps]);
  const isPluginInstallationDone = useMemo(() => isStepDone('pluginInstallation'), [steps]);
  const isLinkedinValidationDone = useMemo(() => isStepDone('linkedinValidation'), [steps]);

  // Specific cases
  const canAccessMainApp = useMemo(() => {
    return (
      nextStep === -1 ||
      (isEmailValidationDone &&
        isPostSignUpDone &&
        isJoinWorkspaceDone &&
        isPluginInstallationDone &&
        isLinkedinValidationDone &&
        !oldUser) ||
      (isEmailValidationDone && oldUser && isPluginInstallationDone && isLinkedinValidationDone)
    );
  }, [
    nextStep,
    isEmailValidationDone,
    isPostSignUpDone,
    isJoinWorkspaceDone,
    isPluginInstallationDone,
    isLinkedinValidationDone,
    oldUser,
  ]);

  const showEmailValidation = useMemo(() => {
    return isStepExists('emailValidation') && !isEmailValidationDone;
  }, [isEmailValidationDone]);

  const showPostSignUp = useMemo(() => {
    return isStepExists('postSignUp') && !isPostSignUpDone;
  }, [isPostSignUpDone]);

  const showJoinWorkspace = useMemo(() => {
    return isEmailValidationDone && isStepExists('joinWorkspace') && !isJoinWorkspaceDone;
  }, [isEmailValidationDone, isJoinWorkspaceDone]);

  const needsOnboarding = useMemo(() => {
    if (!isEmailValidationDone || !isPostSignUpDone) return false;

    if (!oldUser && isJoinWorkspaceDone && !isPluginInstallationDone) {
      return true;
    }
    if (!oldUser && isJoinWorkspaceDone && !isLinkedinValidationDone) {
      return true;
    }
    if (oldUser && !isPluginInstallationDone) {
      return true;
    }
    if (oldUser && !isLinkedinValidationDone) {
      return true;
    }

    return false;
  }, [
    isEmailValidationDone,
    isPostSignUpDone,
    oldUser,
    isJoinWorkspaceDone,
    isPluginInstallationDone,
    isLinkedinValidationDone,
  ]);

  const redirectTo = useMemo(() => {
    if (showEmailValidation) return '/emailvalidation';
    if (showPostSignUp) return '/postSignup';
    if (showJoinWorkspace) return '/joinWorkspace';
    if (needsOnboarding) return '/welcome';
    return null;
  }, [showEmailValidation, showJoinWorkspace, needsOnboarding]);

  const showLegacyPopup = useMemo(() => {
    const inBillingPage = window.location.pathname.includes('billing');
    const hasOrganizationCredits = redux.credits.organizationCredits;
    const shouldShowPopUp = redux.credits.showPopUp;

    return (
      (nextStep === -1 || canAccessMainApp) &&
      hasOrganizationCredits &&
      !inBillingPage &&
      shouldShowPopUp
    );
  }, [nextStep, canAccessMainApp, redux.credits]);

  return {
    oldUser,
    canAccessMainApp,
    showEmailValidation,
    showPostSignUp,
    showJoinWorkspace,
    needsOnboarding,
    redirectTo,
    showLegacyPopup,
    isEmailValidationDone,
    isPostSignUpDone,
    isJoinWorkspaceDone,
    isPluginInstallationDone,
    isLinkedinValidationDone,
    isStepExists,
  };
};

export default useUserSteps;
