import { convertToJsonAndHandleErrors, getToken } from '../utils';
import { API_URL } from '../../constants';

export const getCredits = () => {
  return fetch(`${API_URL}/credits`, {
    method: 'GET',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const getRemainingCredits = (email: any, limit: any) => {
  let url = email ? `${API_URL}/credits/remaining?email=${email}` : `${API_URL}/credits/remaining`;
  return fetch(url, {
    method: 'GET',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const getPlans = () => {
  return fetch(`${API_URL}/credits/plans`, {
    method: 'GET',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const getSubscriptionInfos = (options: any) => {
  return fetch(`${API_URL}/credits/subscribeInfo`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(options),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const getLegacyPopupInfos = () => {
  return fetch(
    `${API_URL}/credits/legacyPopupInfos
`,
    {
      method: 'GET',

      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        Authorization: getToken(),
      },
    },
  )
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const createOrUpdateCustomer = (options: any) => {
  return fetch(`${API_URL}/credits/customer`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(options),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const cancelDowngrade = (options: any) => {
  return fetch(`${API_URL}/credits/cancelDowngrade`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(options),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const createOrUpdateSepaCustomer = (options: any) => {
  return fetch(`${API_URL}/credits/customer`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(options),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const subscribe = (options: any) => {
  return fetch(`${API_URL}/credits/subscribe`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(options),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const payNow = (data: any) => {
  return fetch(`${API_URL}/credits/payNow`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const subscribeMigration = (options: any) => {
  return fetch(`${API_URL}/credits/subscribeMigration`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(options),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const creditsPackInfo = ({ credits, country }: any) => {
  return fetch(`${API_URL}/credits/creditsPackInfo`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify({ credits, country }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const creditsPack = (credits: any) => {
  return fetch(`${API_URL}/credits/packs`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(credits),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const getPaymentHistory = () => {
  return fetch(`${API_URL}/credits/history`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const getCoupon = (code: any) => {
  return fetch(`${API_URL}/credits/plans/coupon`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify({ code }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const getCreditUsage = (dispatch: any, page: any) => {
  return fetch(`${API_URL}/credits/creditusage/${page}`, {
    method: 'GET',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      dispatch({
        type: 'GET_CREDITS_USAGE',
        creditsUsage: data,
      });
      return data;
    });
};

export const getRemaining = () => {
  return fetch(`${API_URL}/credits/remaining`, {
    method: 'GET',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const getCreditsHistory = (input: any) => {
  return fetch(`${API_URL}/credits/stats`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(input),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const getUsageByMember = ({ startDate = null, search, page }: any) => {
  return fetch(`${API_URL}/credits/usageByMember `, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify({ startDate, page, search }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const getOrgMembersFromCreditsUsages = (input: any) => {
  return fetch(`${API_URL}/credits/getOrgMembersFromCreditsUsages`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(input),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const exportCreditsUsages = (input: any) => {
  input.exportType = 'csv';
  return fetch(`${API_URL}/credits/exportCreditStats`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(input),
  }).then(async (res) => {
    const data = await res.blob();
    const statusCode = res.status;
    return { statusCode, data };
  });
};

export const getActivity = (data: any) => {
  return fetch(`${API_URL}/credits/activity `, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const exportCreditsActivity = (input: any) => {
  input.exportType = 'csv';
  return fetch(`${API_URL}/credits/exportCreditActivity`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(input),
  }).then(async (res) => {
    const data = await res.blob();
    const statusCode = res.status;
    return { statusCode, data };
  });
};
export const exportCreditsActivityExcel = (input: any) => {
  input.exportType = 'excel';
  return fetch(`${API_URL}/credits/exportCreditsActivity`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(input),
  }).then(async (res) => {
    const data = await res.blob();
    const statusCode = res.status;
    return { statusCode, data };
  });
};
export const getPromoPlan = ({ code, minUsers, numberOfMonths }: any) => {
  return fetch(`${API_URL}/credits/plans/custom/`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify({ planId: code, minUsers, numberOfMonths }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const legacyTracking = (action: any) => {
  return fetch(`${API_URL}/credits/updateOrganizationMigrationPopup`, {
    method: 'PUT',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify({ action }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
