export const checkDataSet = (dataset: any) => {
  return Object.keys(Object.assign({}, dataset)).includes('trackingId');
};

export const getAllChildren = (target: any) => {
  let children = [...target];
  children.forEach((child) => {
    children = children.concat(...child.childNodes);
  });
  return children;
};

export const getAllParents = (target: any) => {
  let nodes = [];
  let element = target;
  let i = 0;
  while (element.parentNode && i < 5) {
    nodes.push(element.parentNode);
    element = element.parentNode;
    i++;
  }
  return nodes;
};

export const checkClickable = (node: any) => {
  let clickableButton = node.tagName === 'A' || node.tagName === 'BUTTON' || !!node.onclick;
  let parents = getAllParents(node);
  return (
    !!clickableButton || !!parents.find((parent) => parent.tagName === 'A' || !!parent.onclick)
  );
};

export const getTrackingId = (node: any, messagesToMap: any) => {
  let page = window.location.pathname;
  let pageName = page ? page.replace('/', '') : '';
  if (node.nodeValue)
    return (
      messagesToMap[node.nodeValue] && 'page-' + pageName + '-' + messagesToMap[node.nodeValue]
    );
  if (node.tagName === 'IMG') {
    if (node.src) {
      let imgName = node.src.split('/');
      imgName = imgName[imgName.length - 1].split('.')[0];
      return 'page-' + pageName + '-' + imgName;
    }
  }
  return null;
};
