import React from 'react';
import { Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Permissions } from '../../../../../components';
import { oauthVariables, services } from '../../constants';

import AuthServ from '../../../../Auth/AuthServ/AuthServ';
import { integrationsDetailedDescriptions } from '../../constants';

function OauthIntegrationCard({
  category,
  name,
  imagesrc,
  account,
  text,
  disabled,
  moreInfoLink,
  setAccount,
  apiKeyLink,
  handleShowApiIntegration,
  handleConnectionModal,
  handleMappingModal,
  selectedIntegration,
  setSelectedIntegration,
  isLoading,
  checkMemberPermissions,
}: any) {
  //@ts-expect-error
  const IntegrationData = integrationsDetailedDescriptions[name];

  const selectIntegration = () => {
    if (!disabled)
      setSelectedIntegration({
        integration: name,
        category,
        imagesrc,
        account,
        text,
        disabled,
        moreInfoLink,
        apiKeyLink,
      });
  };

  const AuthConnect = () => {
    //@ts-expect-error
    return services[name] === 'oauth' ? (
      <AuthServ
        //@ts-expect-error
        clientId={oauthVariables.clientId[name]}
        //@ts-expect-error
        scopes={oauthVariables.scopes[name]}
        //@ts-expect-error
        oauthUrl={oauthVariables.oauthUrl[name]}
        type={name}
        responseType={'code'}
        redirectUri={window.location.origin + `/socialAuth/${name}/`}
        onLogin={setAccount}
        dim={{ height: 700, width: 800 }}
        inModal={false}
      />
    ) : !checkMemberPermissions('Sync') ? (
      <Permissions.CallToAction withoutIcon={true} toolTipStyle={{ width: 200 }}>
        <button className="connect-btn">
          <FormattedMessage id={'settings.profile.connect'} />
        </button>
      </Permissions.CallToAction>
    ) : (
      <button
        className="connect-btn"
        onClick={(e) => {
          selectIntegration();
          handleShowApiIntegration(e);
        }}
      >
        <FormattedMessage id={'settings.profile.connect'} />
      </button>
    );
  };

  return (
    <Col sm={6} md={6} lg={4}>
      <div
        className={'integrations-card ' + (disabled ? 'disabled-card' : '')}
        onClick={(e) => {
          if (!disabled) {
            selectIntegration();
            handleConnectionModal(e);
          }
        }}
      >
        {/* HEADER*/}

        <div className="top-block">
          <div className="left-ui">
            <label>{category}</label>

            <p className="title username">{IntegrationData.name}</p>
          </div>

          <div className="right-ui">
            <figure>
              <img src={imagesrc} alt="" />
            </figure>
          </div>
        </div>

        {/* BODY */}

        <div className="middle-content-ui">
          <p>{text}</p>
        </div>

        {/* FOOTER */}
        {!disabled && (
          <div className="bottom-ui">
            {account ? (
              <div className="connect-btn active">{account}</div>
            ) : !checkMemberPermissions('Sync') ? (
              <Permissions.CallToAction withoutIcon={true} toolTipStyle={{ width: 200 }}>
                <AuthConnect />
              </Permissions.CallToAction>
            ) : (
              <AuthConnect />
            )}
            {account ? (
              <button
                className="more-info-ui"
                onClick={(e) => {
                  selectIntegration();
                  handleMappingModal(e);
                }}
              >
                {isLoading && selectedIntegration && selectedIntegration.integration === name ? (
                  <img
                    src={'/assets/images/loading.gif'}
                    alt={'loading'}
                    style={{ height: '20px' }}
                  />
                ) : (
                  <FormattedMessage id={'mapping'} />
                )}
              </button>
            ) : (
              <button className="more-info-ui">
                {' '}
                <FormattedMessage id={'info.moreInfo'} />
              </button>
            )}
          </div>
        )}
      </div>
    </Col>
  );
}

export default OauthIntegrationCard;
