import React, { useState } from 'react';
import './BillingCycle.css';

import PriceInfo from '../../../common/CutomizePlanModal/common/PriceInfoBis/PriceInfoBis';
import { FormattedMessage } from 'react-intl';

import FixPriceInfoFreePlan from '../../../common/CutomizePlanModal/common/FixPriceInfoFreePlan/FixPriceInfoFreePlan';

const BillingCycle = ({
  selectedPlan,
  addOnsPrice,
  onYearlySwitch,
  subscriptionType,
  organizationCredits,
  user,
  nbLicenses,
}: any) => {
  const [isActive, setIsActive] = useState(
    selectedPlan.planId === 'plan_3'
      ? 'annual'
      : subscriptionType === 'yearly'
        ? 'annual'
        : 'monthly',
  );
  const handleAnnualClick = () => {
    setIsActive('annual');
    onYearlySwitch('yearly');
  };

  const handleMonthlyClick = () => {
    setIsActive('monthly');
    onYearlySwitch('monthly');
  };

  return (
    <div id="billing-cycle">
      <h1>
        <FormattedMessage id="billing.customizePlan.chooseBillingCycle" />
      </h1>

      <div className="billing-cycle-ctn">
        <div
          className={`billing-option annually-ctn-wrapper ${isActive === 'annual' ? 'active' : ''}`}
          onClick={handleAnnualClick}
        >
          <div className="annually-ctn">
            <div className="left">
              <p>
                <FormattedMessage id="billing.annual" />
              </p>

              <div className="price">
                <h2>
                  <FixPriceInfoFreePlan
                    selectedPlan={selectedPlan}
                    addOnsPrice={addOnsPrice}
                    onYearlySwitch={onYearlySwitch}
                    organizationCredits={organizationCredits}
                    user={user}
                    planType={'yearly'}
                    nbLicenses={nbLicenses}
                  />
                </h2>

                <span style={{ textTransform: 'lowercase' }}>
                  /<FormattedMessage id="billing.customizePlan.mo" />
                </span>
              </div>
            </div>
            {(selectedPlan.planId === 'plan_1' || selectedPlan.planId === 'plan_2') && (
              <div className="save-percent">
                <p>
                  <FormattedMessage
                    id="billing.plan.saveXPercent"
                    values={{
                      value:
                        selectedPlan.planId === 'plan_1'
                          ? '25'
                          : selectedPlan.planId === 'plan_2'
                            ? '20'
                            : '0',
                    }}
                  />
                </p>
              </div>
            )}
          </div>
        </div>
        {selectedPlan.planId !== 'plan_3' && (
          <div
            className={`billing-option monthly-ctn-wrapper ${
              isActive === 'annual' ? '' : 'active-monthly'
            }`}
            onClick={handleMonthlyClick}
          >
            <div className="monthly-ctn">
              <div className="left">
                <p>
                  <FormattedMessage id="billing.billMonthly" />
                </p>

                <div className="price">
                  <h2>
                    <FixPriceInfoFreePlan
                      selectedPlan={selectedPlan}
                      addOnsPrice={addOnsPrice}
                      onYearlySwitch={onYearlySwitch}
                      organizationCredits={organizationCredits}
                      user={user}
                      planType={'monthly'}
                      nbLicenses={nbLicenses}
                    />
                  </h2>

                  <span style={{ textTransform: 'lowercase' }}>
                    /<FormattedMessage id="billing.customizePlan.mo" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BillingCycle;
