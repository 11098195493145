import React, { useState, useEffect } from 'react';
import { capitalize } from '../../services/utils';

import Avatars from '../Avatars/Avatars';

import DisplayWorkspaces from '../DisplayWorkspaces/DisplayWorkspaces';
import { FormattedMessage } from 'react-intl';
import {
  joinOrganization,
  autoJoin,
  getPublicOrganization,
  createOrganization,
} from '../../services/api/organization.api';
import { toast } from 'react-toastify';

import PreLoader from '../PreLoader/PreLoader';
import './WorkspacesWithAvatars.css';

import JoinWorkspaceNonExistingDomain from '../../container/Auth/Onboarding/JoinWorkspace/JoinWorkspaceNonExistingDomain/JoinWorkspaceNonExistingDomain';
import { useNavigate } from 'react-router-dom';

const WorkspacesWithAvatars = ({ profile }: any) => {
  const [publicOrganizations, setPublicOrganizations] = useState([]);
  const [organizationsRequested, setOrganizationsRequested] = useState([]);
  const [mainPublicOrganization, setMainPublicOrganization] = useState(null);
  const [loadingPublicOrgs, setLoadingPublicOrgs] = useState(true);
  const [seeMoreWorkspaces, setSeeMoreWorkspaces] = useState(false);
  const [userDomain, setUserDomain] = useState('');
  const [matchedPublicOrganizations, setMatchedPublicOrganizations] = useState([]);
  const [createAWorkspaceToggle, setCreateAWorkspaceToggle] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    const fetchPublicOrganizations = async () => {
      try {
        // Extract the domain from the user's email
        if (profile.email) {
          const domain = profile.email.split('@')[1];
          setUserDomain(domain);
        }

        // Retrieve public organizations
        const { data: publicOrganizationData, statusCode } = await getPublicOrganization();
        if (statusCode === 200 && profile.email.split('@')[1] !== 'gmail.com') {
          if (
            publicOrganizationData &&
            //@ts-expect-error
            publicOrganizationData.organizations &&
            //@ts-expect-error
            publicOrganizationData.organizations.length > 0
          ) {
            // Filter organizations where hasSentJoinRequest is true
            //@ts-expect-error
            const matchedOrgs = publicOrganizationData.organizations.filter(
              (org: any) => org.hasSentJoinRequest,
            );
            setMatchedPublicOrganizations(matchedOrgs);

            // Remove matched organizations from publicOrganizations

            //@ts-expect-error
            const remainingOrgs = publicOrganizationData.organizations.filter(
              (org: any) => !org.hasSentJoinRequest,
            );
            setPublicOrganizations(remainingOrgs);

            // Set the main public organization
            setMainPublicOrganization(matchedOrgs[0] || remainingOrgs[0] || null);
          } else {
            setPublicOrganizations([]);
            setMainPublicOrganization(null);
            setMatchedPublicOrganizations([]);
          }
        } else {
          setPublicOrganizations([]);
          setMainPublicOrganization(null);
          setMatchedPublicOrganizations([]);
        }
      } catch (error) {
        console.error('Error fetching public organizations:', error);
      } finally {
        setLoadingPublicOrgs(false);
      }
    };

    fetchPublicOrganizations();

    return () => {
      setPublicOrganizations([]);
      setLoadingPublicOrgs(true);
      setMatchedPublicOrganizations([]);
    };
  }, [profile.email]);

  const onCreateWorkspace = async (e: any) => {
    e.preventDefault();
    const organization = {
      name: capitalize(userDomain.split('.')[0]) + ' Workspace',
      domains: [userDomain],
    };
    const formData = new FormData();

    formData.append('picture', null);
    formData.append('organization', JSON.stringify(organization));
    const { data, statusCode } = await createOrganization(formData);
    if (statusCode === 200) {
      toast.success(<FormattedMessage id="organization.workspaceSuccessfullyCreated" />);
      setTimeout(() => {
        window.location.href = '/workspace';
      }, 2000);
    } else {
      //@ts-expect-error
      toast.error(data.messageTranslated || data.message);
    }
  };

  const handleRequestJoinOrganization = async (organizationId: any) => {
    if (organizationId) {
      try {
        const { statusCode, data } = await joinOrganization({
          organizationId,
          clientDate: new Date(),
        });
        if (profile && profile.nextStep === -1 && statusCode == 200) {
          navigate('/workspace');
        } else if (statusCode === 200) {
          navigate('/welcome');
        } else {
          //@ts-expect-error
          toast.error(data.messageTranslated);
        }
      } catch (error) {
        toast.error('An error occurred while joining the organization.');
      }
    }
  };

  const handleAutoJoinOrganization = async (organizationId: any) => {
    if (organizationId) {
      try {
        const { statusCode, data } = await autoJoin({
          organizationId,
          clientDate: new Date(),
        });
        if (profile && profile.nextStep === -1 && statusCode == 200) {
          navigate('/workspace');
        } else if (statusCode === 200) {
          navigate('/welcome');
        } else {
          //@ts-expect-error
          toast.error(data.messageTranslated);
        }
      } catch (error) {
        toast.error('An error occurred while auto-joining the organization.');
      }
    }
  };

  if (loadingPublicOrgs) return <PreLoader />;

  if (publicOrganizations.length === 0) {
    return (
      <div id="non-existing-dashboard">
        <JoinWorkspaceNonExistingDomain
          userDomain={profile.email.split('@')[1]}
          profile={profile}
        />
      </div>
    );
  }

  return (
    <div id="workspaces-avatars">
      <div className="workspaces-avatars-header">
        {mainPublicOrganization && mainPublicOrganization.hasSentJoinRequest ? (
          <>
            <h1>
              <FormattedMessage id="organization.requestIsPending" />
            </h1>

            <p>
              <FormattedMessage
                id="organization.requestIsPendingText"
                values={{ value: mainPublicOrganization?.name }}
              />
            </p>
          </>
        ) : (
          <>
            <h1>
              <FormattedMessage id="signupWk.weFoundYourTeammates" />
            </h1>

            <p>
              <FormattedMessage id="signupWk.joinYourTeamsWorkspace" />
            </p>
          </>
        )}
      </div>

      <div className="wk-ctn">
        <div className="top-wk-ctn">
          <Avatars
            members={[...mainPublicOrganization.admins, ...mainPublicOrganization.members]}
          />

          <h2>{mainPublicOrganization?.name || <FormattedMessage id={'loading'} />}</h2>

          <p>
            {capitalize(mainPublicOrganization.admins[0].firstName)}{' '}
            {capitalize(mainPublicOrganization.admins[0].lastName)}
            {mainPublicOrganization.totalMembersCount - 1 > 0 && (
              <span> and {mainPublicOrganization.totalMembersCount} other members</span>
            )}
            {mainPublicOrganization.totalMembersCount - 1 > 0 &&
              (mainPublicOrganization.totalMembersCount - 1 === 0 ? (
                <span>
                  {' '}
                  <FormattedMessage
                    id="signUpWk.andOneOtherMember"
                    values={{ value: mainPublicOrganization.totalMembersCount }}
                  />
                </span>
              ) : (
                <span>
                  {' '}
                  <FormattedMessage
                    id="signUpWk.andXOtherMembers"
                    values={{ value: mainPublicOrganization.totalMembersCount }}
                  />
                </span>
              ))}
          </p>
        </div>

        <div className="bottom-wk-ctn">
          {mainPublicOrganization && mainPublicOrganization.hasSentJoinRequest ? (
            <button
              className="btn2"
              style={{ backgroundColor: '#C0C2CF', cursor: 'not-allowed', color: 'white' }}
              disabled
            >
              <FormattedMessage id="organization.requestPending" />
            </button>
          ) : mainPublicOrganization && mainPublicOrganization.couldUserAutoJoin ? (
            <button
              className="btn1"
              onClick={() => handleAutoJoinOrganization(mainPublicOrganization._id)}
            >
              <FormattedMessage id="signupWk.join" />
            </button>
          ) : (
            <button
              className="btn1"
              onClick={() => handleRequestJoinOrganization(mainPublicOrganization._id)}
            >
              <FormattedMessage id="signupWk.requestToJoin" />
            </button>
          )}
          {!seeMoreWorkspaces &&
          (publicOrganizations.length === 1 || publicOrganizations.length === 0) ? (
            <button className="btn btn-link" onClick={onCreateWorkspace}>
              <FormattedMessage id="signupWk.createANewWorkspace" />
            </button>
          ) : !seeMoreWorkspaces && publicOrganizations.length > 1 ? (
            <button className="btn btn-link" onClick={() => setSeeMoreWorkspaces(true)}>
              <FormattedMessage id="signupWk.seeMoreWorkspaces" />
            </button>
          ) : null}
        </div>
        {seeMoreWorkspaces && (
          <div className="show-all-workspaces">
            {matchedPublicOrganizations && matchedPublicOrganizations.length > 0 && (
              <h3>
                <FormattedMessage id="organization.WorkspacesYouveRequestedToJoin" />
              </h3>
            )}
            {matchedPublicOrganizations &&
              matchedPublicOrganizations.length > 0 &&
              matchedPublicOrganizations.map((organization, index) => (
                <DisplayWorkspaces
                  key={index}
                  organization={organization}
                  handleAutoJoinOrganization={handleAutoJoinOrganization}
                  handleRequestJoinOrganization={handleRequestJoinOrganization}
                />
              ))}
            {publicOrganizations && publicOrganizations.length > 0 && (
              <h3 style={{ marginTop: '20px' }}>
                <FormattedMessage id="signupWk.otherWorkspacesWithYourEmailDomain" />
              </h3>
            )}
            {publicOrganizations.map((organization, index) => (
              <DisplayWorkspaces
                key={index}
                organization={organization}
                handleAutoJoinOrganization={handleAutoJoinOrganization}
                handleRequestJoinOrganization={handleRequestJoinOrganization}
              />
            ))}
          </div>
        )}
      </div>
      {seeMoreWorkspaces && (
        <div className="no-match">
          <p>
            <FormattedMessage id="signupWk.noMatch" />
          </p>
          {/* CREATE WORKSPACE DIRECTLY TODO */}

          <button className="btn btn3" onClick={onCreateWorkspace}>
            + <FormattedMessage id={'signupWk.createANewWorkspace'} />
          </button>
        </div>
      )}
    </div>
  );
};

export default WorkspacesWithAvatars;
