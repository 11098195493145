import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { ContentWrapperUi, Header, SideNav } from '../../components';

import WorkflowProvider from './store/workflow.provider';

import SettingsProvider from '../Settings/store/Settings.provider';

import CreateWk from './CreateWorkflow/CreateWorkflow';
import './Workflow.css';

import MyWorkflows from './MyWorkflows/MyWorkflows';

import MyWorkflow from './MyWorkflows/MyWorkflow/MyWorflow';
import { workflowInitialState, workflowReducer, actions } from './store/workflow.store';
import { useParams } from 'react-router-dom';
import { getWorkflow } from '../../services/api/workflows.api';
import { FormattedMessage } from 'react-intl';

export function Workflow(props: any) {
  const [state, dispatch] = useReducer(workflowReducer, workflowInitialState);
  const store = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      getWorkflow(id).then(({ data, statusCode }) => {
        if (statusCode == 200) {
          //@ts-expect-error
          dispatch({ type: actions.SELECTED_TEMPLATE, payload: data.workflow });
        }
      });
    }
  }, []);

  const backToWorkflows = () => {
    dispatch({ type: actions.SELECTED_TEMPLATE, payload: null });
    window.location.href = '/workflow';
  };

  return (
    <>
      <WorkflowProvider store={store}>
        <SettingsProvider store={store}>
          <Header
            redux={props.redux}
            setNavToggle={() => props.redux.setNavBarToggle(!props.redux.globalReducer.navToggle)}
            navToggle={props.redux.globalReducer.navToggle}
            currentPage={<FormattedMessage id={'sidenav.workflow'} />}
            profile={props.redux.profile}
            isWkCreation={state.selectedTemplate}
            backToWorkflows={backToWorkflows}
            remaining={props.redux.credits.remaining}
            organization={props.redux.organization}
          />

          <ContentWrapperUi>
            <SideNav navToggle={props.redux.globalReducer.navToggle} />
            {state.selectedTemplate ? (
              <MyWorkflow
                workflow={state.selectedTemplate}
                profile={props.redux.profile}
                organization={props.redux.organization}
                organizationCredit={props.redux.credits && props.redux.credits.organizationCredits}
              />
            ) : (
              //   <div>CURRENT WORKFLOW</div>

              <MyWorkflows
                navToggle={props.redux.globalReducer.navToggle}
                credits={props.redux.credits}
                profile={props.redux.profile}
                organization={props.redux.organization}
                organizationCredit={props.redux.credits && props.redux.credits.organizationCredits}
                isVersion2={props.redux.credits && props.redux.credits.isVersion(2)}
              />
            )}
          </ContentWrapperUi>
        </SettingsProvider>
      </WorkflowProvider>
    </>
  );
}

export function CreateWorkflow(props: any) {
  const [state, dispatch] = useReducer(workflowReducer, workflowInitialState);
  const store = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);
  return (
    <>
      <WorkflowProvider store={store}>
        <SettingsProvider store={store}>
          <Header
            redux={props.redux}
            setNavToggle={() => props.redux.setNavBarToggle(!props.redux.globalReducer.navToggle)}
            navToggle={props.redux.globalReducer.navToggle}
            currentPage={'Workflow'}
            isWkCreation
            profile={props.redux.profile}
            remaining={props.redux.credits.remaining}
            organization={props.redux.organization}
          />

          <ContentWrapperUi>
            <SideNav navToggle={props.redux.globalReducer.navToggle} />

            <CreateWk
              navToggle={props.redux.globalReducer.navToggle}
              profile={props.redux.profile}
              organizationCredits={props.redux.credits && props.redux.credits.organizationCredits}
            />
          </ContentWrapperUi>
        </SettingsProvider>
      </WorkflowProvider>
    </>
  );
}
