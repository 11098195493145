import React, { useContext, useState } from 'react';

import { actions, LeadsContext } from '../../store/leads.store';
import { getLeadFeatures, leadsFilter, updateLeadInfos } from '../../../../services/api/leads.api';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';

export default function useProfileInfo({ profile, memberPermission, planPermission }: any) {
  //@ts-expect-error
  const { state, dispatch } = useContext(LeadsContext);
  const [show, setShow] = useState(false);
  const [moreEmails, showMoreEmails] = useState(null);
  const [morePhones, showMorePhones] = useState(null);
  const [selectToggle, setSelectToggle] = useState(null);
  const [exportToggle, setExportToggle] = useState(null);
  const [editLeadToggle, setEditLeadToggle] = useState(null);
  const [leadToModify, setLeadToModify] = useState(profile);
  const [deleteLeadToggle, setDeleteLeadToggle] = useState(false);
  const [saveAndCancelToggle, setSaveAndCancelToggle] = useState(true);
  const [createNoteToggle, setCreateNoteToggle] = useState(false);
  const [phoneToggle, setPhoneToggle] = useState(false);
  const [aircallToggle, setAircallToggle] = useState(false);
  const [ringoverToggle, setRingoverToggle] = useState(false);

  const onFieldsChange = ({ value, property, index, type }: any) => {
    setSaveAndCancelToggle(true);
    let profileFields = Object.assign({}, leadToModify);

    if (index === -1) {
      // add field
      profileFields[property] = [...profileFields[property], value];
    } else {
      if (index !== undefined && property) {
        if (value === null) {
          // delete
          profileFields[property] = profileFields[property].filter(
            (item: any, i: any) => i !== index,
          );
          if (property == 'currentPersonalEmails' || property == 'currentProEmails') {
            let currentProperty = property.replace('s', '');
            let currentEmail = profile[currentProperty];
            if (profile[property].find((email: any) => email == currentEmail)) {
              profileFields[currentProperty] = profileFields[property][0] || '';
            }
          }
        }
        //edit
        if (value !== null)
          profileFields[property] = profileFields[property].map((field: any, i: any) => {
            if (index == i) return value;
            else return field;
          });
        if ((property == 'currentPersonalEmails' || property == 'currentProEmails') && value) {
          let currentProperty = property.replace('s', '');
          let currentEmail = profile[currentProperty];
          profile[property].find((email: any) => email == currentEmail);
          profileFields[currentProperty] = value;
        }
      } else {
        profileFields[property] = value;
      }
    }
    setLeadToModify(profileFields);
  };
  const onFieldsSubmit = async () => {
    if (!memberPermission) {
      return toast.error(<FormattedMessage id={'permissions.noAccess'} />);
    }
    if (!planPermission) {
      return toast.error(<FormattedMessage id={'permissions.upgradePlan'} />);
    }
    const inputElement = document.querySelector('#input_field');

    //@ts-expect-error
    if (inputElement && !inputElement.checkValidity()) return;

    let rawAddresses = leadToModify.currentPersonalEmails.concat(leadToModify.currentProEmails);
    leadToModify.emails = rawAddresses.map((email: any) => {
      //@ts-expect-error
      return { email, valid: null };
    });
    leadToModify.companyInfo.addresses = leadToModify.companyInfo.rawAddresses;
    const { data, statusCode } = await updateLeadInfos(leadToModify);
    if (statusCode == 200) {
      leadsFilter({ data: state.dataForm }).then((results) => {
        dispatch({
          type: actions.GET_LEADS,
          //@ts-expect-error
          payload: results.data.leads,
          //@ts-expect-error
          pages: results.data.pages,
        });
      });
      leadToModify.emails = leadToModify.emails.map((email: any) => email.email);
      dispatch({ type: actions.SELECT_LEAD, payload: leadToModify });
      setSaveAndCancelToggle(false);

      const { data: featuresData, statusCode } = await getLeadFeatures({
        leadId: profile._id,
      });
      if (statusCode == 200) dispatch({ type: actions.LEAD_DETAILS, payload: featuresData });
    } else {
      //@ts-expect-error
      toast.error(data.messageTranslated || data.message);
    }
  };

  return [
    {
      show,
      moreEmails,
      morePhones,
      selectToggle,
      exportToggle,
      editLeadToggle,
      leadToModify,
      deleteLeadToggle,
      saveAndCancelToggle,
      createNoteToggle,
      phoneToggle,
      aircallToggle,
      ringoverToggle,
    },
    {
      setShow,
      showMoreEmails,
      showMorePhones,
      setSelectToggle,
      setExportToggle,
      setEditLeadToggle,
      setLeadToModify,
      setDeleteLeadToggle,
      setSaveAndCancelToggle,
      setCreateNoteToggle,
      setPhoneToggle,
      setAircallToggle,
      setRingoverToggle,
    },
    { onFieldsChange, onFieldsSubmit },
  ];
}
