import { actions } from '../../../../store/Settings.store';

const handleSetKasprProperties = (properties: any, dispatch: any) => {
  dispatch({ type: actions.SET_KASPR_PROPERTIES, payload: properties });
};
const handleSetIntegrationProperties = (properties: any, dispatch: any) => {
  dispatch({ type: actions.SET_INTEGRATION_PROPERTIES, payload: properties });
};
const handleSetMappedProperties = (properties: any, dispatch: any) => {
  dispatch({ type: actions.SET_MAPPED_PROPERTIES, payload: properties });
};
const handleSetMappedValues = (values: any, dispatch: any) => {
  dispatch({ type: actions.SET_MAPPED_VALUES, payload: values });
};
const handleSetIsCustomValue = (values: any, dispatch: any) => {
  dispatch({ type: actions.SET_IS_CUSTOM_VALUE, payload: values });
};
const handleSetPropertiesAutoComplete = (properties: any, dispatch: any) => {
  dispatch({
    type: actions.SET_PROPERTIES_AUTO_COMPLETE,
    payload: properties,
  });
};
const handleSetShowAutoComplete = (boolean: any, dispatch: any) => {
  dispatch({ type: actions.SET_SHOW_AUTO_COMPLETE, payload: boolean });
};
const handleSetShowKasprPropertiesAutoComplete = (properties: any, dispatch: any) => {
  dispatch({
    type: actions.SET_SHOW_KASPR_PROPERTIES_AUTO_COMPLETE,
    payload: properties,
  });
};
const handleSetPropertyIndex = (nb: any, dispatch: any) => {
  dispatch({ type: actions.SET_PROPERTY_INDEX, payload: nb });
};
const handleSetError = (err: any, dispatch: any) => {
  dispatch({ type: actions.SET_ERROR, payload: err });
};

export {
  handleSetMappedValues,
  handleSetKasprProperties,
  handleSetMappedProperties,
  handleSetIntegrationProperties,
  handleSetShowKasprPropertiesAutoComplete,
  handleSetIsCustomValue,
  handleSetError,
  handleSetPropertiesAutoComplete,
  handleSetPropertyIndex,
  handleSetShowAutoComplete,
};
