import React, { useEffect, useState } from 'react';
import {
  createOrganization,
  getNewInvitations,
  getOrganization,
  getPublicOrganization,
  joinOrganization,
} from '../../../services/api/organization.api';

import ReceivedInvitations from './ReceivedInvitations/ReceivedInvitations';
import { toast, ToastContainer } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { capitalizeFirstLetter } from '../../../services/utils/tools';

import WorkspacesWithAvatars from '../../../components/WorkspacesWithAvatars/WorkspacesWithAvatars';
import './CreateJoinOrganization.css';
import { Col } from 'react-bootstrap';

export default function CreateJoinOrganization({ profile }: any) {
  const [toggle, setToggle] = useState(false);
  const [invitations, setInvitations] = useState(false);
  useEffect(() => {
    getNewInvitations().then(({ data }) => {
      //@ts-expect-error
      setInvitations(data.invitations);
    });
  }, []);

  return (
    <Col md={10} lg={10}>
      <div id="create-join-org" className="">
        <div className="join-organization-inner join-organization-page">
          <div style={{ marginBottom: 30 }}>
            {invitations && <ReceivedInvitations invitations={invitations} />}
          </div>

          <WorkspacesWithAvatars profile={profile} />

          <ToastContainer position="bottom-right" style={{ top: 100 }} />
        </div>
      </div>
    </Col>
  );
}
