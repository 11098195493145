import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink, useNavigate } from 'react-router-dom';
import { calculateRemaining } from '../../../services/utils/tools';

import { useBecomeMember } from '../Home.hooks';
import { getPublicOrganization } from '../../../services/api/organization.api';
import { skipSteps } from '../../../services/api/auth.api';
// b2b emails :#B149FF
// personal emails : #FF206E
// export email credits :#4D58F3
// mobile credits :#0EB763

export function Onboarding({
  selectedStep,
  organization,
  steps,
  sendInvitations,
  deleteInvitations,
  user,
  createWorkSpace,
  remaining,
  personalCredits,
  organizationCredits,
  userInfo,
  handleSkipSteps,
}: any) {
  //@ts-expect-error
  const currentStep = steps.find((stp) => stp.id == selectedStep);
  const [invitationType, setInvitationType] = useState('goldInvitations');

  if (!currentStep) return <div />;
  const { Render } = currentStep;
  return (
    <OnboardingWrapper
      selectedStep={selectedStep}
      //@ts-expect-error
      remaining={calculateRemaining(remaining, { personalCredits })}
      step={currentStep}
      invitationType={invitationType}
      personalCredits={personalCredits}
      organizationCredits={organizationCredits}
      userInfo={userInfo}
      handleSkipSteps={handleSkipSteps}
    >
      <Render
        invitations={currentStep.invitations}
        sendInvitations={sendInvitations}
        deleteInvitations={deleteInvitations}
        createWorkSpace={createWorkSpace}
        user={user}
        organization={organization}
        setInvitationType={setInvitationType}
        invitationType={invitationType}
      />
    </OnboardingWrapper>
  );
}

export function OnBoardingSideModal({
  organization,
  onTabClick,
  onStepClick,
  steps,
  selectedStep,
  createWorkSpace,
  handleSkipSteps,
  profile,
}: any) {
  const navigate = useNavigate();
  let progression = steps.filter((step: any) => step.done).length;
  progression = Math.floor((progression * 100) / steps.length);
  const handleStepClick = (step: any) => {
    onStepClick(step.id);
    navigate(`/onboarding/${step.urlSegment}`);
  };

  const skipOnboardingBtn = async () => {
    try {
      await handleSkipSteps();
    } catch (error) {
      console.error('Error executing handleSkipSteps:', error);
    }
    // Refreshing the page is necessary to update nextStep
    window.location.href = '/leads';
  };

  return (
    <div className={'onBoarding-sideNav'}>
      {/* @ts-expect-error */}
      {steps.map((step, i) => (
        <OnboardingItem
          key={i}
          onStepClick={() => handleStepClick(step)}
          step={step}
          selected={selectedStep}
        />
      ))}
      {/* Only admin/owner type can upgrade plan */}
      {organization &&
        organization.member &&
        (organization.member.type === 'owner' || organization.member.type === 'admin') && (
          <NavLink className={'upgrade'} to="/billing">
            <FormattedMessage id={'upgrade'} />
          </NavLink>
        )}

      <div className={'progress-container'}>
        <progress id="file" max="100" value={progression} />

        <p>
          {' '}
          <FormattedMessage id={'%completed'} values={{ value: progression }} />{' '}
        </p>
      </div>

      {profile.nextStep !== -1 && (
        <div style={{ marginTop: '40px' }}>
          <button className="simple-btn" onClick={skipOnboardingBtn}>
            <FormattedMessage id="onboarding.skipOnboarding" />
          </button>
        </div>
      )}
    </div>
  );
}

function OnboardingItem({ onStepClick, selected, step }: any) {
  return (
    <div
      className={'onBoarding-item ' + (step.done ? 'validated' : '')}
      style={selected === step.id ? { borderColor: '#333' } : {}}
      onClick={
        step.done && step.id === 'becomeMember'
          ? () => (window.location.href = '/workspace')
          : onStepClick
      }
    >
      <div className={'onBoarding-item-icon'}>
        <img src={'/assets/images/' + step.icon} alt="" />{' '}
      </div>

      <div className={'onBoarding-item-content'}>
        <h3>{step.title} </h3>
      </div>
    </div>
  );
}
function OnboardingWrapper({
  children,
  step,
  invitationType,
  organizationCredits,
  userInfo,
  handleSkipSteps,
}: any) {
  useEffect(() => {
    let wrapper = document.querySelector('#onboarding_tab');
    if (wrapper) wrapper.classList.add('global-fade-in');

    setTimeout(() => {
      if (wrapper) wrapper.classList.remove('global-fade-in');
    }, 1000);
  }, [step.title, userInfo.nextStep, handleSkipSteps]);

  return (
    <div id="onboarding_tab" className="onboarding-section">
      <div className={'onboarding-wp'}>
        {organizationCredits && !organizationCredits.subscription && userInfo.denyFreeCredits && (
          <div className={'onboarding-banner'}>
            <i className="fas fa-exclamation-triangle"></i>{' '}
            <FormattedMessage id={'home.sorryConnectWithAnotherAccount'} />
          </div>
        )}

        <h2>{step.title}</h2>
        {step.urlSegment === 'invite' ? (
          invitationType === 'goldInvitations' ? (
            <p className={'subtitile'}>
              <img src={'/assets/images/' + step.coin} alt="" />
              {step.creditsText}{' '}
            </p>
          ) : (
            <p className={'subtitile'}>
              <img src={'/assets/images/' + step.coin2} alt="" />
              <FormattedMessage id={'earn'} /> {step.creditsText2}{' '}
            </p>
          )
        ) : (
          <p className={'subtitile'}>
            {' '}
            <img src={'/assets/images/' + step.coin} alt="" />
            {step.urlSegment !== 'invite' && <FormattedMessage id={'earn'} />} {step.creditsText}{' '}
          </p>
        )}

        <div>{children}</div>
      </div>
    </div>
  );
}
