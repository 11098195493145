import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';

export default function StripeField() {
  return (
    <div
      style={{
        borderRadius: '10px',
        boxShadow: '0 0 2px 0 rgba(20, 24, 47, 0.12), 0 2px 4px 0 rgba(20, 24, 47, 0.1)',
        padding: '0.75rem 1rem',
        border: '1px solid #d5d7e4',
      }}
    >
      <CardElement
        options={{
          hidePostalCode: true,
          style: {
            //@ts-expect-error
            hidePostalCode: true,
            base: {
              fontSize: '16px',
              color: '#424770',
              '::placeholder': {
                color: '#aab7c4',
              },
            },
            invalid: {
              color: '#9e2146',
            },
          },
        }}
      />
    </div>
  );
}
