import React, { useContext, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import actions from '../store/billing.actions';
import { BillingContext } from '../store/billing.store';
import './BillingInfos.css';
import { getCredits, subscribe } from '../../../services/api/credits.api';
import reduxActions from '../../../redux/actions';
import { connect } from 'react-redux';
import { getUser } from '../../../services/api/auth.api';
import { FormattedDate, FormattedMessage } from 'react-intl';
import queryString from 'query-string';

import AddonSection from './AddonSection/AddonSection';

import PaymentInfo from './PaymentInfo/PaymentInfo';
import { PreLoader } from '../../../components';
import { useParams } from 'react-router-dom';

import UnsubscribeModals from '../common/UnsubscribeModals/UnsubscribeModals';
import { useLocation } from 'react-router-dom';

import CurrencyDisplay from '../../../components/CurrencyDisplay/CurrencyDisplay';

function BillingInfos({ credits, updatePaymentInfos, isVersion2, organization, user }: any) {
  //@ts-expect-error
  const { state, dispatch } = useContext(BillingContext);
  const [unsubscribeToggle, setUnsubscribeToggle] = useState(false);
  const [usersManagementToggle, setUsersManagement] = useState(false);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const [error, setError] = useState('');

  const location = useLocation();
  const { usersManagement } = location.state || { usersManagement: false };

  useEffect(() => {
    if (usersManagement) {
      setUsersManagement(true);
    }
  }, [usersManagement]);

  useEffect(() => {
    if (params && params.id === 'unsubscribe') {
      setUnsubscribeToggle(true);

      return window.history.pushState(null, null, '/billing/info');
    }
  }, []);
  useEffect(() => {
    const { changePlan, addLicense } = queryString.parse(window.location.search);
    if (addLicense == 'true') {
      setUsersManagement(true);
    }

    if (changePlan == 'true') {
      const changePlanElement = document.querySelector('#plan_tab');
      changePlanElement && changePlanElement.classList.add('active');
      changePlanElement && changePlanElement.classList.add('show');
      const billingInfoElement = document.querySelector('#billing_tab');
      billingInfoElement && billingInfoElement.classList.remove('active');

      billingInfoElement && changePlanElement.classList.remove('show');
    }
  }, [queryString.parse(window.location.search)]);

  if (loading) {
    return <PreLoader />;
  }

  return (
    <BillingWrapper>
      <Col lg={12}>
        <>
          <PaymentInfo
            customer={credits.organizationCredits.customer}
            updatePaymentInfos={updatePaymentInfos}
            plan={credits.organizationCredits.plan}
            subscription={credits.organizationCredits.subscription}
            organizationCredits={credits.organizationCredits}
            user={user}
          />
          {/* {credits.organizationCredits.pending.plan && (
            <PendingPlan
              customer={credits.organizationCredits.customer}
              updatePaymentInfos={updatePaymentInfos}
              plan={credits.organizationCredits.pending.plan}
              subscription={credits.organizationCredits.subscription}
            />
          )}*/}

          <AddonSection
            plan={credits.organizationCredits.plan}
            organizationCredits={credits.organizationCredits}
            subscription={credits.organizationCredits.subscription}
            openUnsubscribeModal={setUnsubscribeToggle}
            setUsersManagement={setUsersManagement}
            usersManagementToggle={usersManagementToggle}
            isVersion2={isVersion2}
            organization={organization}
            user={user}
          />

          <PaymentHistory
            invoices={state.invoices}
            user={user}
            organizationCredits={credits.organizationCredits}
          />
        </>
      </Col>
      {unsubscribeToggle && (
        <UnsubscribeModals
          subscription={credits.organizationCredits.subscription}
          toggle={setUnsubscribeToggle}
          setError={setError}
          error={error}
        />
      )}
    </BillingWrapper>
  );
}

function PaymentHistory({ invoices, user, organizationCredits }: any) {
  if (!invoices || (invoices && invoices.length == 0)) {
    return <div />;
  }
  return (
    <div className="payment-wrap-ui">
      <div className="heading-ui">
        <label className="inner-title mb-24">
          <FormattedMessage id={'billing.paymentHistory'} />
        </label>
      </div>

      <div className="payment-history-wrapper">
        <table>
          <thead>
            <tr>
              <th>DATE</th>

              <th className="text-center">
                <FormattedMessage id={'billing.amount'} />
              </th>

              <th className="text-center">
                <FormattedMessage id={'billing.download'} />
              </th>
            </tr>
          </thead>

          <tbody>
            {invoices.map((invoice: any, i: any) => (
              <InvoiceItem
                key={i}
                item={invoice}
                user={user}
                organizationCredits={organizationCredits}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function InvoiceItem({ item, user, organizationCredits }: any) {
  return (
    <tr>
      <td>
        <FormattedDate value={new Date(item.date)} year="numeric" month="long" day="numeric" />
      </td>

      <td className="text-center">
        <CurrencyDisplay
          user={user}
          organizationCredits={organizationCredits}
          amount={(item.amount * 0.01).toFixed(2)}
        />
      </td>

      <td className="text-center">
        <a href={item.pdf} className="text-btn pdf-btn">
          <span className="icon">
            <img src="/assets/images/ic-pdf.svg" alt="" />
          </span>
          PDF
        </a>
      </td>
    </tr>
  );
}

function BillingWrapper({ children }: any) {
  return (
    <div id="billing_tab" className="tab-pane  active show ">
      <Row className="billing-inner-block">
        <Col sm={12}>
          <Row>{children}</Row>
        </Col>
      </Row>
    </div>
  );
}

const mapDispatchToProps = (dispatch: any) => ({
  setNavBarToggle: (navToggle: any) => {
    //@ts-expect-error
    dispatch({ type: actions.NAVBAR_TOGGLE, navToggle });
  },

  resetCredit: () => {
    getCredits().then(({ data, statusCode }) => {
      dispatch({ type: reduxActions.GET_CREDITS, payload: data });
    });
    getUser().then(({ data }) => {
      dispatch({ type: reduxActions.GET_USER, payload: data });
    });
  },
});
const mapStateToProps = (state: any) => ({
  redux: state,
});

export default connect(mapStateToProps, mapDispatchToProps)(BillingInfos);
