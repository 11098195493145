import React, { useState } from 'react';
import './Files.css';

import FilesHeader from './FilesHeader/FilesHeader';

import FilesList from './FilesList/FilesList';
import { usePermissions } from '../../../components/Permissions/permissions.hooks';
import { Permissions } from '../../../components';
import { useIntl } from 'react-intl';

const Files = ({ profile, credits }: any) => {
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [checkPlanPermissionsWk, checkMemberPermissionsWk] = usePermissions(
    credits &&
      credits.organizationCredits &&
      credits.organizationCredits.plan &&
      credits.organizationCredits.plan.permissions,
    profile && profile.permissions,
    'Workflow',
  );
  const [checkPlanPermissionsBulk, checkMemberPermissionsBulk] = usePermissions(
    credits &&
      credits.organizationCredits &&
      credits.organizationCredits.plan &&
      credits.organizationCredits.plan.permissions,
    profile && profile.permissions,
    'BulkEnrichment',
  );
  const intl = useIntl();

  if (!checkPlanPermissionsBulk('View') || !checkMemberPermissionsWk('View'))
    return (
      <Permissions.View
        title={intl.formatMessage({ id: 'permissions.denied' })}
        description={intl.formatMessage({ id: 'permissions.noAccess' })}
      />
    );

  return (
    <div id="files" className="files-container">
      <FilesHeader currentPage={currentPage} setCurrentPage={setCurrentPage} />

      <FilesList currentPage={currentPage} profile={profile} credits={credits} />
    </div>
  );
};

export default Files;
