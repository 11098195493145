import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import './App.media.css';
import * as Page from './container';

import JoinWorkspace from './container/Auth/Onboarding/JoinWorkspace/JoinWorkspace';
import { ErrorBar, PreLoader, SideNav } from './components';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { connect, Provider } from 'react-redux';
import actions from './redux/actions';
import { getToken } from './services/utils';

import AuthComplete from './container/Auth/AuthServ/AuthComplete';
import { getCredits, getRemaining } from './services/api/credits.api';
import {
  CHROME_STORE,
  EXTENSION_ID,
  IS_PROD,
  MIXPANEL_TOKEN,
  SOURCE_BUSTER_CONFIG,
} from './constants';
import { getNotifications } from './services/api/notifications.api';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';

import TrackingEvents from './services/TrackingEvents/TrackingEvents';

import LegacyPopup from './components/LegacyPopup/LegacyPopup';
import 'react-toastify/dist/ReactToastify.css';
import { getOrganization, updateMemberInfos } from './services/api/organization.api';
import { getUser } from './services/api/auth.api';
import reduxActions from './redux/actions';
import { credits } from './redux/reducers/credits';
import useUserSteps from './services/utils/useUserSteps';
import mixpanel from 'mixpanel-browser';

import sbjs from 'sourcebuster';

import cookie from 'react-cookies';
import Mixpanel from './providers/Mixpanel/Mixpanel';

function App(reduxProps: any) {
  const [barError, setBarrError] = useState<object | boolean>(false);
  const intl = useIntl();

  // Merge reduxProps and reduxProps.redux
  const redux = { ...reduxProps, ...reduxProps.redux };

  // Delete the redundant 'redux' property
  delete redux.redux;
  const nextStep = redux.profile.nextStep;

  const {
    oldUser,
    canAccessMainApp,
    showEmailValidation,
    showPostSignUp,
    showJoinWorkspace,
    needsOnboarding,
    redirectTo,
    showLegacyPopup,
    isEmailValidationDone,
    isJoinWorkspaceDone,
    isPostSignUpDone,
    isPluginInstallationDone,
    isLinkedinValidationDone,
  } = useUserSteps(redux, nextStep);

  useEffect(() => {
    let sb_utm = cookie.load('sbjs_first', { path: '/', domain: 'kaspr.io' });
    if (!sb_utm) sbjs.init(SOURCE_BUSTER_CONFIG);

    if (getToken()) {
      getNotifications().then(({ data }: any) => {
        if (data.banner) {
          setTimeout(() => {
            setBarrError(data.banner);
          }, 1000);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (
      reduxProps.redux.credits.organizationCredits &&
      reduxProps.redux.credits.organizationCredits.disabled
    ) {
      setBarrError({
        descriptionText: intl.formatMessage({
          id: 'permissions.weHaveAProblemWithYourPurchase',
        }),
      });
    }
  }, [reduxProps.redux.credits]);
  const reactIntl = useIntl();

  useEffect(() => {
    let { redux, intl } = reduxProps;
    if (!intl) intl = reactIntl;
    if (
      redux.profile.steps &&
      window.location.href.indexOf('welcome') == -1 &&
      window.location.href.indexOf('emailvalidation') == -1
    ) {
      if (!isPluginInstallationDone) {
        return setBarrError({
          descriptionText: intl.formatMessage({
            id: 'permissions.ifYouWantToUseKasprPleaseDownloadThePlugin',
          }),
          link: CHROME_STORE,
          buttonText: (
            <>
              plugin
              <img width={13} src="/assets/images/download-solid.svg" alt="" />
            </>
          ),
          type: 'error',
        });
      }
      if (!isLinkedinValidationDone) {
        return setBarrError({
          descriptionText: intl.formatMessage({
            id: 'permissions.youHaveToConnectYourAccountWithLinkedin',
          }),
          link: 'https://www.linkedin.com/feed/?synchro=kaspr&isFirstKasprSynchro=true',
          buttonText: (
            <>
              <FormattedMessage id={'connect'} />

              <img width={13} src="/assets/images/ic-linkedin.png" alt="" />
            </>
          ),
          type: 'error',
        });
      }
    }

    if (getToken()) {
      if (window.chrome && window.chrome.runtime && IS_PROD) {
        window.chrome.runtime.sendMessage(
          EXTENSION_ID,
          {
            action: 'isKasprExtensionInstalled',
          },
          async (response: {}, err: {}) => {
            if (!response) {
              setBarrError({
                descriptionText: intl.formatMessage({
                  id: 'permissions.ifYouWantToUseKasprPleaseDownloadThePlugin',
                }),
                link: CHROME_STORE,
                buttonText: (
                  <>
                    LinkedIn Extension{' '}
                    <img width={13} src="/assets/images/download-solid.svg" alt="" />
                  </>
                ),
                type: 'error',
              });
            }
          },
        );
      }
    }

    if (reduxProps.redux.profile && window.intercomeGetUser) {
      window.intercomeGetUser(reduxProps.redux.profile);
    }
  }, [reduxProps.redux.profile, isPluginInstallationDone, isLinkedinValidationDone, reduxProps]);

  // Check if the user is authenticated; if not, redirect to authentication routes
  if (!getToken()) {
    return (
      <BrowserRouter>
        <Mixpanel
          userEmail={reduxProps.redux.profile && reduxProps.redux.profile.email}
          organization={null}
        >
          <Routes>
            <Route path={'/signin'} element={<Page.Signin />} />
            <Route path={'/signup'} element={<Page.Signup redux={redux} />} />
            <Route path={'/signup/confirm'} element={<Page.SignupFromLinkParams />} />
            <Route path={'/signup/:email'} element={<Page.Signup redux={redux} />} />
            <Route path={'/forgot'} element={<Page.ForgotPasswd />} />

            <Route path={'/reset'} element={<Page.ResetPasswd />} />

            <Route path={'/optout'} element={<Page.OptOut />} />

            <Route path={'/optoutAutomatic'} element={<Page.OptOutFromEmail />} />

            <Route path={'/checkdevice'} element={<Page.ChekDevice />} />

            <Route path={'*'} element={<Navigate to="/signin" replace />} />
          </Routes>
        </Mixpanel>
      </BrowserRouter>
    );
  }

  const isAdminWithSubscription = () => {
    const member = redux.organization && redux.organization.member;
    if (!member) return true;
    if (member && member.type !== 'member') return true;
    return false;
  };

  // Handle cases where the user is blocked or data is not yet loaded
  if (redux.credits.userBlocked) {
    return <Page.UserBlocked />;
  }
  if (!redux.profile.email) {
    return <PreLoader />;
  }

  if (redux.credits && !redux.credits.personalCredits && redux.profile.step === 0) {
    return <PreLoader />;
  }
  if (redux.profile.nextStep === -1 && !redux.credits.remaining) {
    return <PreLoader />;
  }

  // Handle case where the organization's plan is suspended
  if (redux.organization.isPlanSuspended) {
    return (
      <TrackingEvents>
        <BrowserRouter>
          <Routes>
            <Route path={'/'} element={<Page.Unpaid {...redux} />} />

            <Route path={'/notify'} element={<Page.NotifySuccess />} />

            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </BrowserRouter>
      </TrackingEvents>
    );
  }

  // Main application elementing
  return (
    <BrowserRouter>
      <Mixpanel
        userEmail={reduxProps.redux.profile && reduxProps.redux.profile.email}
        organization={reduxProps.redux.organization && reduxProps.redux.organization.organization}
      >
        {showLegacyPopup ||
          (window.location.href.includes('legacy') &&
            redux.credits &&
            redux.credits.isVersion(1) && (
              <LegacyPopup.Choice
                organizationCredits={redux.credits.organizationCredits}
                currentPlan={redux.credits.organizationCredits.plan}
              />
            ))}
        {barError && canAccessMainApp && <ErrorBar info={barError} onHide={setBarrError} />}
        <div className="App">
          {/* Use canAccessMainApp to conditionally render main routes */}
          {canAccessMainApp && (
            <Routes>
              <Route path={'/onboarding'} element={<Page.Home redux={redux} />} />

              <Route path={'/onboarding/:step'} element={<Page.Home redux={redux} />} />

              <Route path={'/leads'} element={<Page.Leads redux={redux} />} />

              <Route path={'/bulk-enrichment'} element={<Page.BulkEnrichment redux={redux} />} />

              <Route
                path={'/bulk-enrichment/files'}
                element={<Page.BulkEnrichment redux={redux} urlSegment={'files'} />}
              />

              <Route
                path={'/bulk-enrichment/file-upload'}
                element={<Page.BulkEnrichment redux={redux} urlSegment={'file-upload'} />}
              />

              <Route path={'leads'} element={<Page.Leads redux={redux} urlSegment={'leads'} />} />

              <Route
                path={'leads/:tabId'}
                element={<Page.Leads redux={redux} urlSegment={'waitinglist'} />}
              />
              <Route path={'/lead/:leadId'} element={<Page.Leads redux={redux} />} />

              <Route path={'/workflow'} element={<Page.Workflow redux={redux} />} />

              <Route path={'/workflow/:id'} element={<Page.Workflow redux={redux} />} />

              <Route
                path={'/createworkflow/:name/:integration'}
                element={<Page.CreateWorkflow redux={redux} />}
              />

              <Route
                path={'/createworkflow/:name'}
                element={<Page.CreateWorkflow redux={redux} />}
              />

              <Route path={'/organization'} element={<Page.Organization redux={redux} />} />

              <Route
                path={'/organization/createjoin'}
                element={<Page.CreateOrganization redux={redux} />}
              />

              <Route path={'/workspace'} element={<Page.Organization redux={redux} />} />

              <Route
                path={'/workspace/createjoin'}
                element={<Page.CreateOrganization redux={redux} />}
              />

              <Route
                path={'/workspace/members'}
                element={<Page.Organization redux={redux} urlSegment={'members'} />}
              />

              <Route
                path={'/workspace/statistics'}
                element={<Page.Organization redux={redux} urlSegment={'statistics'} />}
              />

              <Route
                path={'/workspace/activity'}
                element={<Page.Organization redux={redux} urlSegment={'activity'} />}
              />

              <Route
                path={'/workspace/settings'}
                element={<Page.Organization redux={redux} urlSegment={'settings'} />}
              />

              <Route path={'/settings'} element={<Page.Settings redux={redux} />} />

              <Route
                path={'/settings/profile'}
                element={<Page.Settings redux={redux} urlSegment={'profile'} />}
              />

              <Route
                path={'/settings/workspace'}
                element={<Page.Settings redux={redux} urlSegment={'workspace'} />}
              />

              <Route
                path={'/settings/integration'}
                element={<Page.Settings redux={redux} urlSegment={'integration'} />}
              />

              <Route
                path={'/settings/api'}
                element={<Page.Settings redux={redux} urlSegment={'api'} />}
              />
              {isAdminWithSubscription() && (
                <Route path={'/billing'} element={<Page.Billing redux={redux} />} />
              )}
              {isAdminWithSubscription() && (
                <Route
                  path={'/billing/info'}
                  element={<Page.Billing redux={redux} urlSegment={'info'} />}
                />
              )}
              {isAdminWithSubscription() && (
                <Route
                  path={'/billing/info/:id'}
                  element={<Page.Billing redux={redux} urlSegment={'info'} />}
                />
              )}
              {isAdminWithSubscription() && (
                <Route
                  path={'/billing/plans'}
                  element={<Page.Billing redux={redux} urlSegment={'plans'} />}
                />
              )}
              {isAdminWithSubscription() && (
                <Route
                  path={'/billing/plans/:id'}
                  element={<Page.Billing redux={redux} urlSegment={'plans'} />}
                />
              )}
              {isAdminWithSubscription() && (
                <Route
                  path={'/billing/plans/:id/:type'}
                  element={<Page.Billing redux={redux} urlSegment={'plans'} />}
                />
              )}

              <Route path="/socialAuth/*" element={<AuthComplete />} />

              <Route path={'/optout'} element={<Page.OptOut />} />

              <Route path={'/optoutAutomatic'} element={<Page.OptOutFromEmail />} />
              {nextStep === -1 ? (
                <Route path="*" element={<Navigate to="/leads" replace />} />
              ) : (
                <Route path="*" element={<Navigate to="/onboarding" replace />} />
              )}
            </Routes>
          )}

          <Routes>
            {/* Use variables from the custom hook to conditionally render routes */}
            {showPostSignUp && <Route path="/postSignup" element={<Page.Signup redux={redux} />} />}
            {showEmailValidation && (
              <Route
                path="/emailvalidation"
                element={<Page.SignupCodeConfirmation redux={redux} />}
              />
            )}
            {showJoinWorkspace && (
              <Route path="/joinWorkspace" element={<JoinWorkspace redux={redux} />} />
            )}
            {needsOnboarding && (
              <Route path="/welcome" element={<Page.Onboarding redux={redux} />} />
            )}
            {/* Use redirectTo variable for default redirect */}
            {redirectTo && <Route path="*" element={<Navigate to={redirectTo} replace />} />}
          </Routes>
        </div>
      </Mixpanel>
    </BrowserRouter>
  );
}

const mapDispatchToProps = (dispatch: any) => ({
  setNavBarToggle: (navToggle: any) => {
    dispatch({ type: actions.NAVBAR_TOGGLE, navToggle });
  },

  resetCredits: () => {
    getCredits().then(({ data, statusCode }) => {
      dispatch({ type: actions.GET_CREDITS, payload: data });
    });
  },

  resetRedux: async () => {
    const [{ data: dataCredits }, { data: dataUser }, { data: dataOrg }, { data: dataRemaining }] =
      await Promise.all([getCredits(), getUser(), getOrganization(), getRemaining()]);
    dispatch({
      type: reduxActions.GET_ORGANIZATION,
      payload: dataOrg,
    });

    dispatch({
      type: reduxActions.GET_CREDITS,
      payload: dataCredits,
    });
    dispatch({
      type: reduxActions.GET_USER,
      payload: dataUser,
    });
    return dataOrg;
  },
});

const mapStateToProps = (state: any) => {
  return { redux: state };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
