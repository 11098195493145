import { toast } from 'react-toastify';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  deleteLead,
  exportLeadsAsCsv,
  exportLeadsAsExcel,
  getLeadFeatures,
  leadsFilter,
} from '../../../../services/api/leads.api';
//@ts-expect-error
import download from 'downloadjs';

import { actions, LeadsContext } from '../../store/leads.store';
import React, { useContext, useState } from 'react';
import { checkPermission } from '../../../../services/utils';

import useFullPageLoader from '../../../../components/FullPageLoader/FullPageLoader';
import { download_file } from '../../../../services/utils/tools';

export default function useProfile({ props, account }: any) {
  //@ts-expect-error
  const { state, dispatch } = useContext(LeadsContext);
  const [page, setPage] = useState(1);
  const [loader, handleLoader, setCounter] = useFullPageLoader();
  const [excelModal, setExcelModal] = useState(false);
  const [csvModal, setCsvModal] = useState(false);
  const [exportLeadToIntegration, setExportLeadToIntegration] = useState(false);
  const [deleteLeadErrorMessage, setDeleteLeadErrorMessage] = useState(null);

  const [emailToggle, setEmailToggle] = useState(false);
  const [permissionToggle, setPermissionToggle] = useState(false);
  const [selectedLead, setSelectedLead] = useState({});
  const [templateToggle, setTemplateToggle] = useState(false);
  const [template, setTemplate] = useState('');
  const [subject, setSubject] = useState('');
  const intl = useIntl();
  const memberPermission = checkPermission({
    permissions: props.redux.profile.permissions,
    name: 'Leads',
    action: 'Export',
  });
  const planPermission = checkPermission({
    permissions:
      props.redux.credits.organizationCredits &&
      props.redux.credits.organizationCredits.plan.permissions,
    name: 'Leads',
    action: 'Export',
  });
  const handleGoToPage = async (page: any) => {
    setPage(page);
    const leadsData = { ...state.dataForm, page };
    dispatch({
      type: actions.LEADS_LOADING,
      payload: true,
    });
    if (state.abortController)
      try {
        state.abortController.abort();
      } catch (err) {}
    let controller = new AbortController();
    dispatch({
      //@ts-expect-error
      type: actions.CANCEL_REQUEST,
      payload: controller,
    });
    const { data } = await leadsFilter({
      data: leadsData,
      signal: controller.signal,
    });
    dispatch({
      type: actions.LEADS_LOADING,
      payload: false,
    });
    dispatch({
      type: actions.LEADS_FILTER,
      payload: { page },
    });
    dispatch({
      type: actions.GET_LEADS,
      //@ts-expect-error
      payload: data.leads,
      //@ts-expect-error
      pages: data.pages,
    });
  };
  const handleExportLeads = async (type: any, leadFromSideModal: any) => {
    if (!memberPermission) {
      return toast.error(<FormattedMessage id={'permissions.noAccess'} />);
    }
    if (!planPermission) {
      return toast.error(<FormattedMessage id={'permissions.upgradePlan'} />);
    }
    const leadsData = {
      ...state.dataForm,
      page: page,
    };
    let data, statusCode;
    let selectedLeads = state.leads.filter((lead: any) => lead.isChecked);
    switch (type) {
      case 'Export CSV':
        if (leadFromSideModal) {
          leadsData.leads = [leadFromSideModal._id];
        } else {
          if (selectedLeads.length > 0)
            leadsData.leads = selectedLeads.map((lead: any) => lead._id);
        }

        ({ data, statusCode } = await exportLeadsAsCsv({
          filters: leadsData,
          sortBy: leadsData.sortBy,
          clientDate: new Date(Date.now()),
        }));
        if (statusCode === 200) {
          toast.success(
            intl.formatMessage(
              { id: 'leads.exportCsvMessage' },
              { value: props.redux.profile.email },
            ),
          );
          // download(data, "leads_kaspr.csv");
        } else {
          //@ts-expect-error
          toast.error(data.messageTranslated || data.message);
        }
        break;

      case 'Export Excel':
        //@ts-expect-error
        handleLoader(true);
        //@ts-expect-error
        setCounter(null);
        if (leadFromSideModal) {
          leadsData.leads = [leadFromSideModal._id];
        } else {
          if (selectedLeads.length > 0) {
            leadsData.leads = selectedLeads.map((lead: any) => lead._id);
          }
        }
        ({ data, statusCode } = await exportLeadsAsExcel({
          filters: leadsData,
          sortBy: leadsData.sortBy,
          clientDate: new Date(Date.now()),
        }));
        if (statusCode === 200) {
          toast.success(
            intl.formatMessage(
              { id: 'leads.exportExcelMessage' },
              { value: props.redux.profile.email },
            ),
          );
        } else {
          //@ts-expect-error
          toast.error(data.messageTranslated || data.message);
        }
        // if (statusCode === 200) download(data, "leads_kaspr.xlsx");

        break;

      case 'Integrations':
        //  setIntegrationsModal(true);
        if (leadFromSideModal) setExportLeadToIntegration(leadFromSideModal);
        else setExportLeadToIntegration(state.leads.filter((lead: any) => lead.isChecked));
        break;
    }
    ({ data } = await getLeadFeatures({ leadId: props.profile._id }));
    dispatch({ type: actions.LEAD_DETAILS, payload: data });
    //@ts-expect-error
    handleLoader(false);
  };
  const handleDeleteLead = async (lead: any) => {
    let ids = [lead._id];
    const { data, statusCode } = await deleteLead(ids);
    if (statusCode == 200) {
      setDeleteLeadErrorMessage('success');
      leadsFilter({ data: state.dataForm }).then((results: any) => {
        dispatch({
          type: actions.GET_LEADS,

          payload: results.data.leads,

          pages: results.data.pages,
        });
      });
    } else {
      //   setErrorMessage(data.messageTranslated || data.message);
      //@ts-expect-error
      setDeleteLeadErrorMessage(data.messageTranslated || data.message);
    }
  };
  const handleEmailToggle = (lead: any) => {
    if (
      props.redux.credits &&
      props.redux.credits.organizationCredits &&
      !!props.redux.credits.organizationCredits.plan.permissions.find(
        (permission: any) =>
          permission.name === 'Integration' && permission.actions.includes('Export'),
      ) &&
      account
    )
      setEmailToggle(!emailToggle);
    else setPermissionToggle(true);
    if (lead) setSelectedLead(lead);
  };
  const handleExportCsvFromModal = async (chunk: any) => {
    if (excelModal) {
      let { data, statusCode } = await exportLeadsAsExcel({
        filters: state.dataForm,
        sortBy: state.dataForm.sortBy,
      });
      if (statusCode === 200) download_file(data, 'leads_kaspr.xlsx');
    } else {
      let { data, statusCode } = await exportLeadsAsCsv({
        filters: state.dataForm,
        sortBy: state.dataForm.sortBy,
      });
      if (statusCode === 200) download(data, 'leads_kaspr.csv');
    }
  };
  return [
    {
      page,
      exportLeadToIntegration,
      emailToggle,
      deleteLeadErrorMessage,
      permissionToggle,
      selectedLead,
      excelModal,
      csvModal,
      templateToggle,
      template,
      subject,
    },
    {
      handleLoader,
      setCounter,
      setPage,
      setEmailToggle,
      setExcelModal,
      setCsvModal,
      setExportLeadToIntegration,
      setDeleteLeadErrorMessage,
      setPermissionToggle,
      setSelectedLead,
      setTemplateToggle,
      setTemplate,
      setSubject,
    },
    {
      handleExportLeads,
      handleDeleteLead,
      handleEmailToggle,
      handleGoToPage,
      handleExportCsvFromModal,
    },
  ];
}
