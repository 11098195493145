import React, { useEffect, useState } from 'react';
import './Notes.css';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';

import FieldActions from '../../Common/FiedActions/FieldActions';
import { ModalMessage } from '../../../../../components';

import DataNotFound from '../../../../../components/DataNotFound/DataNotFound';
export default function Notes({
  profile,
  notes,
  handleAddNote,
  handleUpdateNote,
  handleDeleteNote,
  user,
  organization,
}: any) {
  const [noteValue, setNoteValue] = useState('');
  const [noteToDelete, setNoteToDelete] = useState(null);
  const [noteToEdit, setNoteToEdit] = useState(null);
  const handleNoteActions = ({ type, field }: any) => {
    if (type === 'delete') {
      setNoteToDelete(field);
      setNoteToEdit(null);
    } else {
      setNoteToEdit(field);
      setNoteToDelete(null);
    }
  };
  const onAddNote = (note: any) => {
    handleAddNote(note);
    setNoteValue('');
  };
  return (
    <div id="notes_tab" className={'tab-pane fade ' + (profile.isViewNotes ? 'active show' : '')}>
      <AddNoteButton onAddNote={onAddNote} noteValue={noteValue} setNoteValue={setNoteValue} />
      {notes && notes.length > 0 ? (
        <div className="notes-listing mt-4 ">
          <label className="s-title">
            <FormattedMessage id={'leads.noteAdded'} />
          </label>

          <div className={'notes-container'}>
            {notes.map((note: any, i: any) => (
              <FieldActions
                key={i}
                onClick={handleNoteActions}
                field={note}
                user={user}
                organization={organization}
              >
                <Note
                  data={note}
                  profile={profile}
                  noteToEdit={noteToEdit}
                  noteToDelete={noteToDelete}
                  onEditCancel={() => setNoteToEdit(null)}
                  onUpdateNote={handleUpdateNote}
                />
              </FieldActions>
            ))}
          </div>
        </div>
      ) : (
        <DataNotFound
          elements={
            <p>
              <FormattedMessage id={'leads.noNotesYet'} />
            </p>
          }
        />
      )}

      {noteToDelete && (
        <ModalMessage
          textElement={
            <p>
              <FormattedMessage id={'leads.note.areYouSureYouWantToDelete'} />
            </p>
          }
          action={'confirm'}
          toggle={true}
          setToggle={() => setNoteToDelete(null)}
          handleAction={() => {
            handleDeleteNote(noteToDelete);
            setNoteToDelete(null);
          }}
        />
      )}
    </div>
  );
}

function Note({ data, profile, noteToEdit, onEditCancel, onUpdateNote }: any) {
  const [editDropDownToggle, setEditDropDownToggle] = useState(false);
  const [editDropDownAnim, setEditDropDownAnim] = useState('');
  const [newNoteValue, setNewNoteValue] = useState('');
  useEffect(() => {
    if (noteToEdit && noteToEdit._id === data._id) {
      setEditDropDownToggle(true);
      setTimeout(() => {
        setEditDropDownAnim('addnote-dropdown-anim');
      }, 0);
    } else {
      setEditDropDownToggle(false);
      setEditDropDownAnim('');
    }
    return () => {
      setEditDropDownToggle(false);
      setEditDropDownAnim('');
    };
  }, [noteToEdit]);
  return (
    <div className="notes-card">
      <div
        className="row"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div className="col-lg-8">
          <div className="note-postby">
            <figure>
              <img
                src={
                  (data.addedBy &&
                    data.addedBy.memberId &&
                    data.addedBy.memberId.picture &&
                    'https://kaspr--pictures.s3.eu-west-3.amazonaws.com/users/' +
                      data.addedBy.memberId.picture) ||
                  '/assets/images/ic_profile.png'
                }
                alt=""
              />
            </figure>
            {data.addedBy && data.addedBy.memberId ? (
              <p>
                {' '}
                {data.addedBy.memberId.firstName + ' ' + data.addedBy.memberId.lastName}{' '}
                <FormattedMessage id={'leads.note.wrote'} /> :
              </p>
            ) : (
              <p>
                {data.addedBy.email} <FormattedMessage id={'leads.note.wrote'} /> :{' '}
              </p>
            )}
          </div>
        </div>

        <div className="col-lg-4">
          <span className="date-ui capital">
            <FormattedDate
              value={data.clientDate || data.createdAt}
              year="numeric"
              month="numeric"
              day="numeric"
              hour={'numeric'}
              minute={'numeric'}
            />{' '}
          </span>
        </div>
      </div>

      <div>
        {editDropDownToggle ? (
          <div className={'desc form-group note-dropdown  ' + editDropDownAnim}>
            <textarea
              //@ts-expect-error
              type="text"
              defaultValue={data.text}
              onChange={(e) => setNewNoteValue(e.target.value)}
            />

            <div className={'add-note-button-container'}>
              <button className={'btn btn2'} onClick={onEditCancel}>
                <FormattedMessage id={'cancel'} />
              </button>

              <button
                className={'btn btn1'}
                onClick={() => {
                  onUpdateNote({ noteValue: newNoteValue, noteId: data._id });
                  setEditDropDownAnim('');
                  setEditDropDownToggle(false);
                }}
              >
                <FormattedMessage id={'edit'} />
              </button>
            </div>
          </div>
        ) : (
          <p className="desc">“{data.text}”</p>
        )}
      </div>
    </div>
  );
}
function AddNoteButton({ setNoteValue, noteValue, onAddNote }: any) {
  const [toggle, setToggle] = useState(false);
  const [toggleAnim, setToggleAnim] = useState('');
  const intl = useIntl();
  const handleToggle = () => {
    setToggleAnim('');
    setToggle(true);
    setTimeout(() => {
      setToggleAnim('addnote-dropdown-anim');
    }, 0);
  };
  const addNote = () => {
    setToggle(false);
    setToggleAnim('');
    onAddNote(noteValue);
  };
  return (
    <div className="form-group">
      {toggle && (
        <div className={'note-dropdown ' + toggleAnim}>
          <label className="s-title">
            <FormattedMessage id={'leads.newNote'} />
          </label>

          <textarea
            className="ck-editor"
            onChange={(e) => setNoteValue(e.target.value)}
            value={noteValue}
            placeholder={intl.formatMessage({ id: 'leads.writeYourNoteHere' })}
          />
        </div>
      )}

      {toggle && (
        <button className={'btn btn1'} onClick={() => onAddNote(noteValue)}>
          Add Note
        </button>
      )}
      <div className="task-tab" style={{ width: '100%', padding: 3 }}>
        {toggle ? (
          <div className={'add-note-button-container'} style={{ margin: 0, marginTop: -25 }}>
            <button className={'btn btn2'} onClick={() => setToggle(false)}>
              <FormattedMessage id={'cancel'} />
            </button>

            <button className={'btn btn1'} onClick={addNote}>
              <FormattedMessage id={'add'} />
            </button>
          </div>
        ) : (
          <button className="new-task-btn" style={{ margin: 0 }} onClick={handleToggle}>
            <FormattedMessage id={'leads.createNewNote'} />
          </button>
        )}
      </div>
    </div>
  );
}
