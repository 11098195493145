import React from 'react';
import { FormattedMessage } from 'react-intl';

import CustomToolTip from '../../../../components/CustomToolTip/CustomToolTip';

export function LeadSaved({ organization }: any) {
  return (
    <div className={'onboarding-content '}>
      <p>
        <FormattedMessage id={'home.onboarding.saveYourFirstLeadDesc'} values={{ br: <br /> }} />
      </p>

      <div className="video">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube-nocookie.com/embed/G243fNYJlow"
          title="Saving your first leads"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>

      {!organization ? (
        <CustomToolTip
          element={<FormattedMessage id={'organization.youHavetoCreateAnOrganization'} />}
        >
          <a
            target={'_blank'}
            href={'https://www.linkedin.com/in/anne-charlotte-lazou/'}
            className="btn btn1"
            style={{ opacity: 0.5, pointerEvents: 'none' }}
            rel="noopener noreferrer nofollow"
          >
            <FormattedMessage
              id={'home.onboarding.saveYourFirstLead'}
              defaultMessage={'Save your first lead'}
            />
          </a>
        </CustomToolTip>
      ) : (
        <a
          target={'_blank'}
          href={'https://www.linkedin.com/in/anne-charlotte-lazou/'}
          className="btn btn1"
          rel="noopener noreferrer nofollow"
        >
          <FormattedMessage
            id={'home.onboarding.saveYourFirstLeadButton'}
            defaultMessage={'Save your first lead'}
          />
        </a>
      )}
    </div>
  );
}
