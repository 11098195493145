import React from 'react';
import { FormattedMessage } from 'react-intl';
import './BulkEnrichmentNav.css';
import { NavLink } from 'react-router-dom';

const BulkEnrichmentNav = ({ currentTab, setCurrentTab }: any) => {
  return (
    <div>
      <ul className="tab-container">
        <li>
          <a
            id="bulk-enrichment-file-upload"
            //@ts-expect-error
            to="/bulk-enrichment/file-upload"
            className={currentTab === 'upload-file' ? 'active' : ' '}
            onClick={() => setCurrentTab('upload-file')}
          >
            <FormattedMessage id={'bulkEnrichment.uploadFiles'} />
          </a>
        </li>

        <li>
          <a
            id="bulk-enrichment-files"
            //@ts-expect-error
            to="/bulk-enrichment/files"
            className={currentTab === 'files' ? 'active' : ' '}
            onClick={() => setCurrentTab('files')}
          >
            <FormattedMessage id={'bulkEnrichment.files'} />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default BulkEnrichmentNav;
