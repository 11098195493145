import React, { useReducer, useMemo, useEffect, useState, memo } from 'react';
import { settingsReducer, settingsInitialState } from './store/Settings.store';

import SettingsWrapper from './SettingsWrapper/SettingsWrapper';

import SettingsTabs from './SettingsTabs/SettingsTabs';

import SettingsProvider from './store/Settings.provider';
import { Header, ContentWrapperUi, SideNav } from '../../components';

import Profile from './Profile/Profile';

import Integration from './Integration/Integration';

import Api from './api/api';
import './Settings.css';
import queryString from 'query-string';
import { FormattedMessage } from 'react-intl';

import Workspace from './Workspace/Workspace';
import { useNavigate } from 'react-router-dom';

const Settings = memo((props: any) => {
  const [state, dispatch] = useReducer(settingsReducer, settingsInitialState);
  const [goToIntegrations, setGoToIntegrations] = useState(null);
  const [currentTab, setCurrentTab] = useState('profile');
  const navigate = useNavigate();

  const store = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  useEffect(() => {
    const { integration } = queryString.parse(window.location.search);

    setCurrentTab(integration === 'true' ? 'integration' : props.urlSegment || 'profile');
  }, [props.urlSegment]);

  const onTabCLick = (tab: any) => {
    navigate(`/settings/${tab}`, { replace: true });
    setCurrentTab(tab);
  };

  // Function to securely access nested properties
  const safelyAccessNestedProperty = (obj: any, ...props: any[]) => {
    return props.reduce((acc, prop) => (acc && acc[prop] ? acc[prop] : null), obj);
  };

  return (
    <SettingsProvider store={store}>
      <Header
        redux={props.redux}
        setNavToggle={() => props.redux.setNavBarToggle(!props.redux.globalReducer.navToggle)}
        navToggle={props.redux.globalReducer.navToggle}
        currentPage={<FormattedMessage id={'sidenav.settings'} />}
        profile={props.redux.profile}
        remaining={safelyAccessNestedProperty(props.redux, 'credits', 'remaining')}
        organization={props.redux.organization}
      />

      <ContentWrapperUi>
        <SideNav navToggle={props.redux.globalReducer.navToggle} />

        <SettingsWrapper>
          <SettingsTabs
            organizationCredits={props.redux.credits.organizationCredits}
            onTabCLick={onTabCLick}
            currentTab={currentTab}
            organization={safelyAccessNestedProperty(props.redux, 'organization', 'organization')}
          />

          <div className="tab-content">
            {currentTab === 'profile' && (
              <Profile
                profile={props.redux.profile}
                organization={props.redux.organization}
                goToIntegrations={goToIntegrations}
                remaining={safelyAccessNestedProperty(props.redux, 'credits', 'remaining')}
                organizationCredits={safelyAccessNestedProperty(
                  props.redux,
                  'credits',
                  'organizationCredits',
                )}
                personalCredits={safelyAccessNestedProperty(
                  props.redux,
                  'credits',
                  'personalCredits',
                )}
              />
            )}
            {currentTab === 'workspace' &&
              safelyAccessNestedProperty(props.redux, 'organization', 'organization') && (
                <Workspace
                  team={safelyAccessNestedProperty(props.redux, 'organization', 'organization')}
                />
              )}
            {currentTab === 'integration' && (
              <Integration goToIntegrations={goToIntegrations} profile={props.redux.profile} />
            )}
            {currentTab === 'api' &&
              safelyAccessNestedProperty(props.redux, 'organization', 'organization') && (
                <Api
                  goToIntegrations={goToIntegrations}
                  organizationCredit={safelyAccessNestedProperty(
                    props.redux,
                    'credits',
                    'organizationCredits',
                  )}
                />
              )}
          </div>
        </SettingsWrapper>
      </ContentWrapperUi>
    </SettingsProvider>
  );
});

export default Settings;
