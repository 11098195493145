import React from 'react';
import './StatusBar.css';

const StatusBar = ({ percent }: any) => {
  return (
    <div className="status-bar-container">
      <div className="status-bar">
        <div className="status-bar__fill" style={{ width: `${percent}%` }}></div>
      </div>
    </div>
  );
};

export default StatusBar;
