import React from 'react';
import { Tooltip as ReactTooltip } from 'react-bootstrap';

function Tooltip(props: any) {
  return (
    <ReactTooltip {...props}>
      <div
        style={
          props.container_style
            ? { ...props.container_style }
            : { padding: 10, textAlign: 'center', position: 'relative' }
        }
      >
        {Array.isArray(props.data)
          ? props.data.map((item: any, i: any) => (
              <p style={{ margin: 5 }} key={i}>
                {item.email ? item.email : item}
              </p>
            ))
          : props.data}
      </div>
    </ReactTooltip>
  );
}
export default Tooltip;
