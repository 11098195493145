import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function Discover({ organization }: any) {
  return (
    <div className={'onboarding-content discover'} style={{ paddingBottom: '32px' }}>
      <p>
        <FormattedMessage id={'home.onboarding.discoverContactDesc'} values={{ br: <br /> }} />
      </p>

      <div className="video">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube-nocookie.com/embed/HjwozM3flxk"
          title="Discovering your first leads"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>

      <a
        target={'_blank'}
        href={'https://www.linkedin.com/in/anne-charlotte-lazou/'}
        className="btn btn1"
        rel="noopener noreferrer nofollow"
      >
        <FormattedMessage id={'home.onboarding.openLinkedin'} />
      </a>
    </div>
  );
}
