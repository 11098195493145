import React, { useEffect, useRef, useState } from 'react';
import './JoinWorkspaceExistingDomain.css';
import { autoJoin, joinOrganization } from '../../../../../services/api/organization.api';

import Avatars from '../../../../../components/Avatars/Avatars';

import DisplayWorkspace from '../../../common/DisplayWorkspace/DisplayWorkspace';
import { toast } from 'react-toastify';

import JoinWorkspaceNonExistingDomain from '../JoinWorkspaceNonExistingDomain/JoinWorkspaceNonExistingDomain';
import { FormattedMessage } from 'react-intl';
import { capitalize } from '../../../../../services/utils';

export default function JoinWorkspaceExistingDomain({
  publicOrganizations,
  userDomain,
  profile,
}: any) {
  const containerRef = useRef(null);
  const [mainPublicOrganization, setMainPublicOrganization] = useState(
    () => (publicOrganizations && publicOrganizations[0]) || null,
  );
  const [seeMoreWorkspaces, setSeeMoreWorkspaces] = useState(false);
  const [containerStyle, setContainerStyle] = useState({});
  const [createAWorkspaceToggle, setCreateAWorkspaceToggle] = useState(false);

  const handleRequestJoinOrganization = async (organizationId: any) => {
    if (organizationId) {
      try {
        const { statusCode, data } = await joinOrganization({
          organizationId,
          clientDate: new Date(),
        });
        if (statusCode === 200) {
          window.location.href = '/welcome';
        } else {
          //@ts-expect-error
          toast.error(data.messageTranslated);
        }
      } catch (error) {
        toast.error('An error occurred while joining the organization.');
      }
    }
  };

  const handleAutoJoinOrganization = async (organizationId: any) => {
    if (organizationId) {
      try {
        const { statusCode, data } = await autoJoin({
          organizationId,
          clientDate: new Date(),
        });
        if (statusCode === 200) {
          window.location.href = '/welcome';
        } else {
          //@ts-expect-error
          toast.error(data.messageTranslated);
        }
      } catch (error) {
        toast.error('An error occurred while auto-joining the organization.');
      }
    }
  };

  // Use effect to update main public organization
  useEffect(() => {
    if (publicOrganizations && publicOrganizations.length > 0) {
      setMainPublicOrganization(publicOrganizations[0]);
    }
  }, [publicOrganizations]);

  // Handle container padding based on its height using CSS only
  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const containerHeight = containerRef.current.offsetHeight;
        if (containerHeight > window.innerHeight) {
          setContainerStyle({ paddingTop: '50px' });
        } else {
          setContainerStyle({});
        }
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial call to set the padding

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (createAWorkspaceToggle) {
    return <JoinWorkspaceNonExistingDomain userDomain={userDomain} profile={profile} />;
  }

  return (
    <div id="join-wk-with-domain-ctn" ref={containerRef} style={containerStyle}>
      <h1 style={{ marginTop: seeMoreWorkspaces ? '100px' : '0px' }}>
        <FormattedMessage id="signupWk.weFoundYourTeammates" />
      </h1>

      <h5>
        <FormattedMessage id="signupWk.joinYourTeamsWorkspace" />
      </h5>

      <div className="wk-ctn">
        <div className="top-wk-ctn">
          <Avatars
            members={
              mainPublicOrganization
                ? [...mainPublicOrganization.admins, ...mainPublicOrganization.members]
                : []
            }
          />

          <h2>
            {(mainPublicOrganization && mainPublicOrganization.name) || (
              <FormattedMessage id={'loading'} />
            )}
          </h2>

          <p>
            {capitalize(mainPublicOrganization.admins[0].firstName)}{' '}
            {capitalize(mainPublicOrganization.admins[0].lastName)}
            {mainPublicOrganization.totalMembersCount - 1 > 0 && (
              <span> and {mainPublicOrganization.totalMembersCount} other members</span>
            )}
            {mainPublicOrganization.totalMembersCount - 1 > 0 &&
              (mainPublicOrganization.totalMembersCount - 1 === 0 ? (
                <span>
                  {' '}
                  <FormattedMessage
                    id="signUpWk.andOneOtherMember"
                    values={{ value: mainPublicOrganization.totalMembersCount }}
                  />
                </span>
              ) : (
                <span>
                  {' '}
                  <FormattedMessage
                    id="signUpWk.andXOtherMembers"
                    values={{ value: mainPublicOrganization.totalMembersCount }}
                  />
                </span>
              ))}
          </p>
        </div>
        {mainPublicOrganization && mainPublicOrganization.couldUserAutoJoin ? (
          <button
            className="btn1"
            onClick={() => handleAutoJoinOrganization(mainPublicOrganization._id)}
          >
            <FormattedMessage id="signupWk.join" />
          </button>
        ) : (
          <button
            className="btn1"
            onClick={() => handleRequestJoinOrganization(mainPublicOrganization._id)}
          >
            <FormattedMessage id="signupWk.requestToJoin" />
          </button>
        )}
        {!seeMoreWorkspaces && publicOrganizations.length === 1 ? (
          <button className="btn btn-link" onClick={() => setCreateAWorkspaceToggle(true)}>
            <FormattedMessage id="signupWk.createANewWorkspace" />
          </button>
        ) : !seeMoreWorkspaces && publicOrganizations.length > 1 ? (
          <button className="btn btn-link" onClick={() => setSeeMoreWorkspaces(true)}>
            <FormattedMessage id="signupWk.seeMoreWorkspaces" />
          </button>
        ) : null}
        {seeMoreWorkspaces && (
          <div className="show-all-workspaces">
            <h3>
              <FormattedMessage id="signupWk.otherWorkspacesWithYourEmailDomain" />
            </h3>
            {publicOrganizations.map(
              (organization: any, index: any) =>
                index > 0 && (
                  <DisplayWorkspace
                    key={index}
                    organization={organization}
                    handleAutoJoinOrganization={handleAutoJoinOrganization}
                    handleRequestJoinOrganization={handleRequestJoinOrganization}
                  />
                ),
            )}
          </div>
        )}
      </div>
      {seeMoreWorkspaces && (
        <div className="no-match">
          <p>
            <FormattedMessage id="signupWk.noMatch" />
          </p>

          <button className="btn btn3" onClick={() => setCreateAWorkspaceToggle(true)}>
            + <FormattedMessage id={'signupWk.createANewWorkspace'} />
          </button>
        </div>
      )}
    </div>
  );
}
