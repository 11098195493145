import React, { useState, useEffect } from 'react';
//@ts-expect-error
import RingoverPhone from 'ringover-sdk';
import { FormattedMessage } from 'react-intl';
import { Animation } from '../../../../../components';
import { ToastContainer } from 'react-toastify';
import { createPhoneActivity } from '../../../../../services/api/organization.api';

function Ringover({ phones, leadId }: any) {
  const [numbersToggle, setNumbersToggle] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState('');
  const [ringoverPhone, setRingoverPhone] = useState(null);

  useEffect(() => {
    const ringover = new RingoverPhone({
      size: 'auto',
      border: false,
      position: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      },
      animation: true,
      container: 'ringoverPhoneContainer',
    });
    ringover.generate();

    if (ringover) {
      ringover.on('ringingCall', async (e: any) => {
        await createPhoneActivity({
          leadId,
          template: 'leadCalled',
          data: {
            callId: e.data.call_id,
            from: e.data.from,
            to: e.data.to,
          },
          clientDate: Date.now(),
        });
      });

      ringover.on('hangupCall', async (e: any) => {
        if (e.data.callDuration) {
          let minutes = Math.floor(e.data.callDuration / 60).toString();
          if (minutes.length === 1) minutes = '0' + minutes;
          let seconds = (e.data.callDuration % 60).toString();
          if (seconds.length === 1) seconds = '0' + seconds;
          await createPhoneActivity({
            leadId,
            template: 'leadCallEnded',
            data: {
              callId: e.data.call_id,
              from: e.data.from,
              to: e.data.to,
              answered: true,
              duration: minutes + ':' + seconds,
            },
            clientDate: Date.now(),
          });
        }
      });
    }
    setRingoverPhone(ringover);
    return () => {
      ringover.off();
      setRingoverPhone(null);
    };
  }, []);

  const dial = (number: any) => {
    ringoverPhone && ringoverPhone.dial(number);
  };

  return (
    <>
      <div>
        <div className="cstm-dropdown add-to-list " style={{ paddingBottom: '10px' }}>
          <div className={'custom-title'}>
            <FormattedMessage id={'leads.properties.phones'} />
          </div>

          <div className="dropdown" style={{ zIndex: 10000000 }}>
            <button
              className="dropdown-toggle select-button "
              onClick={() => setNumbersToggle(!numbersToggle)}
              style={{ minWidth: 'max-content', height: 36, fontSize: 14 }}
            >
              <p style={{ width: 'max-content', fontSize: 14 }}>
                {selectedNumber ? selectedNumber : phones && phones[0]}{' '}
              </p>
            </button>

            <Animation.SlideDown
              className={'dropdown-menu show'}
              toggle={numbersToggle}
              setToggle={setNumbersToggle}
            >
              <ul>
                {phones.map((number: any, i: any) => (
                  <li
                    key={i}
                    onClick={() => {
                      setSelectedNumber(number);
                      dial(number);
                      setNumbersToggle(false);
                    }}
                  >
                    <a
                      href="#"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        margin: '1s0px 0',
                      }}
                    >
                      <span className="name_ui">{number}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </Animation.SlideDown>
          </div>
        </div>

        <ToastContainer position="bottom-right" style={{ top: 100 }} />
      </div>

      <div
        id={'ringoverPhoneContainer'}
        style={{
          height: '540px',
          width: '370px',
          border: '1px solid rgb(0, 221, 208)',
          borderRadius: '20px',
        }}
      />
    </>
  );
}

export default Ringover;
