import React from 'react';
import './DisplayWorkspace.css';
import { capitalize } from '../../../../services/utils';
import { FormattedMessage } from 'react-intl';

export default function DisplayWorkspace({
  organization,
  handleRequestJoinOrganization,
  handleAutoJoinOrganization,
}: any) {
  const allMembers = [...organization.admins, ...organization.members];

  const requestToJoin = () => {
    if (organization && organization._id) handleRequestJoinOrganization(organization._id);
  };
  const autoJoin = () => {
    if (organization && organization._id) handleAutoJoinOrganization(organization._id);
  };

  return (
    <div id="display-wk-info">
      <div className="display-wk-left">
        <img src="/assets/images/ic-create.png" width={'34px'} alt="Organization icon" />

        <div className="display-wk-left-inner">
          <h1>{organization.name}</h1>

          <p>
            {capitalize(allMembers && allMembers[0] && allMembers[0].firstName)}{' '}
            {capitalize(allMembers && allMembers[0] && allMembers[0].lastName)}
            {organization.totalMembersCount - 1 > 0 &&
              (organization.totalMembersCount - 1 === 0 ? (
                <span>
                  {' '}
                  <FormattedMessage
                    id="signUpWk.andOneOther"
                    values={{ value: organization.totalMembersCount }}
                  />
                </span>
              ) : (
                <span>
                  {' '}
                  <FormattedMessage
                    id="signUpWk.andXOthers"
                    values={{ value: organization.totalMembersCount }}
                  />
                </span>
              ))}
          </p>
        </div>
      </div>
      {organization.couldUserAutoJoin ? (
        <button className="btn btn3" onClick={autoJoin}>
          + <FormattedMessage id="signupWk.join" />
        </button>
      ) : (
        <button className="btn btn3" onClick={requestToJoin}>
          + <FormattedMessage id="signupWk.requestToJoin" />
        </button>
      )}
    </div>
  );
}
