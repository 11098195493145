import React from 'react';
import './CustomToolTip.css';

export default function CustomToolTip({
  children,
  element,
  style,
  position,
  tooltipContainerStyle,
  onlyTooltip,
}: any) {
  if (element && Array.isArray(element)) {
    return (
      <div id="custom-toolTip-container" style={tooltipContainerStyle}>
        <div
          className={`custom-toolTip ${position} ${onlyTooltip ? 'onlyTooltip' : ''}`}
          style={style || {}}
        >
          {element.map((item, index) => (
            <div key={index} style={{ margin: '4px 0' }}>
              {item.email ? item.email : item}
            </div>
          ))}
          {!onlyTooltip && <div className={`custom-toolTip-index ${position}`}></div>}
        </div>
        <span className="custom-toolTip-content">{children}</span>
      </div>
    );
  }
  return (
    <div id="custom-toolTip-container" style={tooltipContainerStyle}>
      {element && (
        <div
          className={`custom-toolTip ${position} ${onlyTooltip ? 'onlyTooltip' : ''}`}
          style={style || {}}
        >
          {element}
          {!onlyTooltip && <div className={`custom-toolTip-index ${position}`}></div>}
        </div>
      )}
      <span className="custom-toolTip-content">{children}</span>
    </div>
  );
}
