import actions from '../actions';
import {
  messages_en,
  messages_fr,
  messages_de,
  messages_es,
  messages_it,
} from '../../services/translations';

let language = navigator.language.split(/[-_]/)[0];
language =
  language !== 'fr' && language !== 'en' && language !== 'de' && language !== 'es'
    ? // && language !== "it"
      'en'
    : language;
const messages = {
  fr: messages_fr,
  en: messages_en,
  de: messages_de,
  es: messages_es,
  it: messages_it,
};
const initState = {
  navToggle: false,
  lang: language,
  messages,
};

export const globalReducer = (state = initState, action: any) => {
  switch (action.type) {
    case actions.NAVBAR_TOGGLE:
      state = {
        ...state,
        navToggle: action.navToggle,
      };
      break;
    case actions.SET_LANGUAGE:
      state = {
        ...state,
        lang: action.lang,
      };
      break;
  }
  return state;
};
