import React from 'react';

function SettingsWrapper({ children }: any) {
  return (
    <div className="col-md-10 content-wrap">
      <div className="content-block">
        <div className="inner-content-wrap">
          <div className="wd-wrapper">{children}</div>
        </div>
      </div>
    </div>
  );
}

export default SettingsWrapper;
