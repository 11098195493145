import { convertToJsonAndHandleErrors, getToken } from '../utils';
import { API_URL } from '../../constants';

export const getEnrichedCSV = (formData: any) => {
  return fetch(`${API_URL}/workflows/bulkEnrichment/csv`, {
    method: 'POST',

    headers: {
      //@ts-expect-error
      Accept: { 'Content-Type': 'multipart/form-data' },

      Authorization: getToken(),
    },
    body: formData,
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const bulkPagination = (bodyData: any) => {
  return fetch(`${API_URL}/workflows/bulkEnrichment`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(bodyData),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const bulkExport = (bodyData: any) => {
  return fetch(`${API_URL}/workflows/bulkEnrichment/export`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(bodyData),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const deleteBulkEnrichment = (_id: any) => {
  return fetch(`${API_URL}/workflows/bulkEnrichment`, {
    method: 'DELETE',

    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
    },
    body: JSON.stringify({ _id }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
