import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { MIXPANEL_TOKEN } from '../../constants';

const PAGES_MAPPING = [
  { page: '/onboarding/discover', name: 'Onboarding discover' },
  { page: '/onboarding/invite', name: 'Refer colleagues' },
  { page: '/onboarding/synccrm', name: 'Integrate your tools' },
  { page: '/onboarding/automation', name: 'Start your first automation' },

  { page: '/leads/waitinglist', name: 'Leads waiting list' },

  { page: '/workspace/statistics', name: 'Workspace statistics' },
  { page: '/workspace/activity', name: 'Workspace activity' },

  { page: '/billing/plans?p=customize', name: 'Customize your plan' },
  { page: '/billing/plans?p=checkout', name: 'Checkout' },
  { page: '/billing/plans', name: 'Billing plans' },
  { page: '/billing/info', name: 'Billing info' },

  { page: '/settings/workspace', name: 'Workspace settings' },
  { page: '/settings/integration', name: 'Settings integrations' },
  { page: '/settings/api', name: 'Settings api' },

  { page: '/settings', name: 'Settings' },
  { page: '/workflow', name: 'Sales Automation' },
  { page: '/workspace', name: 'Workspace' },
  { page: '/leads', name: 'Leads' },
  { page: '/bulk-enrichment', name: 'Bulk enrichment' },
];

const getPageName = (path) => {
  const page = PAGES_MAPPING.find((pageInfo) => path.includes(pageInfo.page));
  return page && page.name;
};

export default function Mixpanel({ children, userEmail, organization }) {
  const location = useLocation();
  const distinctId = mixpanel.get_distinct_id();

  useEffect(() => {
    if (
      (!distinctId ||
        distinctId === mixpanel.get_property('$device_id') ||
        distinctId === '$device:' + mixpanel.get_property('$device_id')) &&
      userEmail
    ) {
      mixpanel.identify(userEmail);
    }
  }, [userEmail]);

  useEffect(() => {
    let options = {
      'page title': getPageName(location.pathname + location.search),
      'page url': location.pathname + location.search,
    };

    if (organization) options['workspace id'] = organization._id;

    mixpanel.track('Page View', options);
  }, [location]);

  return <>{children}</>;
}
