import React, { useEffect, useState } from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import { Tooltip } from '../../../../components';
import { injectIntl } from 'react-intl';

function CheckBox({ onChange, element, required, id, inputValidationId, intl }: any) {
  const [idValidation, setIdValidation] = useState('');
  useEffect(() => {
    if (inputValidationId) {
      setIdValidation(inputValidationId);
    }
  }, [inputValidationId]);
  let handeOnChange = (e: any) => {
    if (e.target.checked) {
      setIdValidation('');
    } else {
      setIdValidation(id);
    }
    onChange(e.target.checked);
  };
  return (
    <div className="checkbox-wrap">
      <label>
        <input type="checkbox" value="" onChange={handeOnChange} id={id} />
        <span className="checkbox-icon"></span>
        {element}
      </label>
    </div>
  );
}
export default injectIntl(CheckBox);
