import React, { useState } from 'react';
import './ModifyUsersClient.css';
import { FormattedMessage } from 'react-intl';

import CustomToolTip from '../../../../../components/CustomToolTip/CustomToolTip';

// Component for the decrement button
const DecrementButton = ({ canDecrement, handleDecrement }: any) =>
  canDecrement ? (
    <div className="decrement-button" onClick={handleDecrement}>
      <div className="minus-stick"></div>
    </div>
  ) : (
    <CustomToolTip
      style={{
        maxWidth: '20vw',
        pointerEvents: 'none',
      }}
      element={<FormattedMessage id="billing.customizePlan.cantDowngradeSubscriptionTooltip" />}
    >
      <div className="decrement-button button-disabled">
        <div className="minus-stick"></div>
      </div>
    </CustomToolTip>
  );

// Component for the increment button
const IncrementButton = ({ handleIncrement }: any) => (
  <div className="increment-button" onClick={handleIncrement}>
    <div className="plus-stick"></div>

    <div className="plus-stick vertical"></div>
  </div>
);

const ModifyUsers = ({
  selectedPlan,
  onUserSelect,
  minSeats,
  nbLicenses,
  setNbLicenses,
  isNoticePeriod,
}: any) => {
  // Handler to increment the number of seats
  const handleIncrement = () => {
    const newNumberOfSeats = selectedPlan.numberOfSeats + 1;
    setNbLicenses(newNumberOfSeats);
    onUserSelect(newNumberOfSeats, selectedPlan);
  };

  const handleDecrement = () => {
    // Handler to decrement the number of seats
    if (selectedPlan.numberOfSeats > 1) {
      const newNumberOfSeats = selectedPlan.numberOfSeats - 1;
      setNbLicenses(newNumberOfSeats);
      onUserSelect(newNumberOfSeats, selectedPlan);
    }
  };

  // Determine if the decrement button should be disabled
  const canDecrement = !(minSeats === nbLicenses && isNoticePeriod);

  return (
    <div id="modify-users-client">
      <div className="modify-left-ctn">
        <img src="/assets/images/modify-users.svg" alt="Modify Users" />

        <div>
          <h2>
            <FormattedMessage id="organizations.addLicences" />
          </h2>

          <h3>
            <FormattedMessage id="billing.customizePlan.selectTotalNbOfLicenses" />
          </h3>
        </div>
      </div>

      <div className="plan-ctn">
        <DecrementButton canDecrement={canDecrement} handleDecrement={handleDecrement} />

        <span className="number-of-seats">{selectedPlan.numberOfSeats}</span>

        <IncrementButton handleIncrement={handleIncrement} />
      </div>
    </div>
  );
};

export default ModifyUsers;
