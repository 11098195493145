import React from 'react';

import IntegrationsList from './Steps/Integrations';

import { FormattedMessage } from 'react-intl';

import BecomeMember from './Steps/BecomeMember';

import InviteFriends from './Steps/inviteFriens';

import { LeadSaved } from './Steps/LeadSaved';

import { LaunchAutomation } from './Steps/LaunchAutomation';

import Discover from './Steps/Discover';
export default {
  discover: {
    Render: (props: any) => <Discover {...props} />,
    title: (
      <FormattedMessage
        id={'home.onboarding.discoverContact'}
        defaultMessage={'Discover contact'}
      />
    ),
    icon: 'world-icon.svg',
    color: '#0EB763',

    creditsText: <FormattedMessage id={'phoneCredits'} values={{ value: 10 }} />,
    urlSegment: 'discover',
    coin: 'phone-credits.svg',
  },
  becomeMember: {
    Render: (props: any) => <BecomeMember {...props} />,
    title: (
      <FormattedMessage
        id={'organization.createOrganization'}
        defaultMessage={'Create a workspace'}
      />
    ),
    icon: 'monitor-icon.svg',
    color: '#B149FF',

    creditsText: <FormattedMessage id={'exportCredits'} values={{ value: 10 }} />,
    urlSegment: 'createworkspace',
    coin: 'export-credits.svg',
  },
  leadSaved: {
    Render: (props: any) => <LeadSaved {...props} />,
    title: (
      <FormattedMessage
        id={'home.onboarding.saveYourFirstLead'}
        defaultMessage={'Save your first lead'}
      />
    ),
    icon: 'folder-icon.svg',
    color: '#B149FF',

    creditsText: <FormattedMessage id={'exportCredits'} values={{ value: 10 }} />,
    urlSegment: 'savelead',
    coin: 'export-credits.svg',
  },
  inviteFriends: {
    Render: (props: any) => <InviteFriends {...props} />,
    title: (
      <FormattedMessage
        id={'home.onboarding.getUnlimitedB2b'}
        defaultMessage={'Get unlimited B2B credits'}
      />
    ),
    icon: 'userplus-icon.svg',
    color: '#4D58F3',
    creditsText: (
      <FormattedMessage
        id={'home.onboarding.freeB2bForLife'}
        values={{
          value: <FormattedMessage id={'unlimited'} />,

          strong: (chunk) => <strong>{chunk}</strong>,
        }}
      />
    ),

    creditsText2: <FormattedMessage id={'phoneCredits'} values={{ value: 10 }} />,
    urlSegment: 'invite',
    coin: 'work-email-credits.svg',
    coin2: 'phone-credits.svg',
  },
  connectIntegration: {
    Render: (props: any) => {
      return <IntegrationsList {...props} />;
    },

    title: <FormattedMessage id={'home.onboarding.syncYourCrm'} />,
    icon: 'sync-icon.png',
    color: '#B149FF',

    creditsText: <FormattedMessage id={'exportCredits'} values={{ value: 10 }} />,
    urlSegment: 'synccrm',
    coin: 'export-credits.svg',
  },
  launchAutomation: {
    Render: (props: any) => <LaunchAutomation {...props} />,
    title: (
      <FormattedMessage
        id={'home.onboarding.startYourFirstAutomation'}
        defaultMessage={'Launch an Enrichment Automation'}
      />
    ),
    icon: 'arrowsend-icon.svg',
    color: '#B149FF',

    creditsText: <FormattedMessage id={'exportCredits'} values={{ value: 10 }} />,
    urlSegment: 'automation',
    coin: 'export-credits.svg',
  },
};
