import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

export default function AuthHeader({ type }: any) {
  return (
    <header className="signup-header">
      <Container>
        <Row className="align-items-center">
          {type !== 'signup' && (
            <Col>
              <div className="back-home-ui"></div>
            </Col>
          )}

          <Col>
            <div className={'logo ' + (type == 'signup' ? 'text-left' : 'text-center')}>
              <a className="" href="/">
                <img src="/assets/images/logo_on_white.png" alt="logo" width="125" />
              </a>
            </div>
          </Col>

          <Col>
            {type !== 'confirmAccount' && (
              <>
                <div className="header-right d-flex align-items-center justify-content-end">
                  {type !== 'signup' && (
                    <p className="p16 p-color pr-3">
                      <FormattedMessage id={'auth.newToKaspr'} />

                      <NavLink className="text-btn" to="/signup/ ">
                        <FormattedMessage id={'auth.signUp'} />
                      </NavLink>
                    </p>
                  )}
                </div>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </header>
  );
}
