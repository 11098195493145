export const getUpdatedSelectedPlan = (currentPlan: any, organizationCreditsPlan: any) => {
  const updatedSelectedPlan: any = {};

  // Merge keys from both objects
  const keys = new Set([...Object.keys(currentPlan)]);

  keys.forEach((key) => {
    const selectedPlanValue = currentPlan[key];
    const organizationCreditsPlanValue = organizationCreditsPlan[key];

    switch (key) {
      case 'pricePerSeat':
        // Specific handling for pricePerSeat

        updatedSelectedPlan[key] =
          organizationCreditsPlanValue !== undefined &&
          organizationCreditsPlan.numberOfMonths % 12 !== 0
            ? organizationCreditsPlanValue
            : currentPlan.pricePerSeat;
        break;
      case 'yearlyPricePerSeat':
        // Specific handling for yearlyPricePerSeat if the plan is annual

        updatedSelectedPlan[key] =
          organizationCreditsPlan.numberOfMonths % 12 === 0
            ? organizationCreditsPlan.pricePerSeat
            : currentPlan.yearlyPricePerSeat;
        break;

      case 'credits':
        updatedSelectedPlan[key] = {
          workEmailCredits:
            organizationCreditsPlan.workEmailCredits.perSeat *
            organizationCreditsPlan.numberOfSeats,
          personalEmailCredits:
            organizationCreditsPlan.personalEmailCredits.perSeat *
            organizationCreditsPlan.numberOfSeats,
          phoneCredits:
            organizationCreditsPlan.phoneCredits.perSeat * organizationCreditsPlan.numberOfSeats,
          exportCredits:
            organizationCreditsPlan.exportCredits.perSeat * organizationCreditsPlan.numberOfSeats,
        };
        break;

      case 'exportCredits':
        updatedSelectedPlan[key] =
          organizationCreditsPlan.exportCredits.perSeat * organizationCreditsPlan.numberOfSeats;
        break;
      case 'exportCreditsPerSeat':
        updatedSelectedPlan[key] = organizationCreditsPlan.exportCredits.perSeat;
        break;
      case 'personalEmailCredits':
        updatedSelectedPlan[key] =
          organizationCreditsPlan.personalEmailCredits.perSeat *
          organizationCreditsPlan.numberOfSeats;
        break;
      case 'personalEmailCreditsPerSeat':
        updatedSelectedPlan[key] = organizationCreditsPlan.personalEmailCredits.perSeat;
        break;
      case 'phoneCredits':
        updatedSelectedPlan[key] =
          organizationCreditsPlan.phoneCredits.perSeat * organizationCreditsPlan.numberOfSeats;
        break;
      case 'phoneCreditsPerSeat':
        updatedSelectedPlan[key] = organizationCreditsPlan.phoneCredits.perSeat;
        break;
      case 'workEmailCreditsPerSeat':
        updatedSelectedPlan[key] = organizationCreditsPlan.workEmailCredits.perSeat;
        break;

      default:
        updatedSelectedPlan[key] = organizationCreditsPlanValue ?? selectedPlanValue;
    }
  });

  return updatedSelectedPlan;
};
