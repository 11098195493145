import actions from '../actions';
const initState = { member: {} };

export const members = (state = initState, action: any) => {
  switch (action.type) {
    case actions.GET_MEMBER:
      state = {
        ...state,
        ...action.payload,
      };
      break;
  }
  return state;
};
