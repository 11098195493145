import React, { useContext, useEffect, useRef, useState } from 'react';

import { Field, PhoneField } from '../common/Field/Field';
import actions from '../Store/auth.actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Container, Row } from 'react-bootstrap';
import { AuthContext } from '../Store/auth.store';

import CheckBox from '../common/CheckBox/CheckBox';
import queryString from 'query-string';
import { resetPassword, signup } from '../../../services/api/auth.api';
import { IS_PROD } from '../../../constants';
import { saveAsCookie } from '../../../services/utils';
import './SignupFromLink.css';

function isValidPhoneNumber() {
  return true;
}
export default function SignupFromLink() {
  //@ts-expect-error
  const { dispatch, state } = useContext(AuthContext);
  const [inputValidationId, setInputValidation] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const passwordRef = useRef(null);

  const intl = useIntl();
  useEffect(() => {
    let { email, firstName, lastName, phone } = queryString.parse(window.location.search);
    dispatch({
      type: actions.SIGNUP,
      payload: {
        email,
        firstName,
        lastName,
        phone: { phoneNumber: phone, countryCode: null },
        isExternal: true,
      },
    });
  }, []);
  const handleOnSubmit = async (e: any) => {
    e.preventDefault();

    setInputValidation('');
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    let password = document.getElementById('password');
    let terms = document.getElementById('terms');
    let phone = document.getElementById('phone');
    //@ts-expect-error
    if (!passwordRegex.test(password.value)) return setInputValidation(password.id);
    if (state.signupForm.password !== state.signupForm.confirmPassword) {
      return setError(intl.formatMessage({ id: 'auth.pleaseMakeSureYourPasswordsMatch' }));
    }
    //@ts-expect-error
    if (phone.value.length == 0 || !isValidPhoneNumber(phone.value)) {
      return setInputValidation(phone.id);
    }
    //@ts-expect-error
    if (!terms.checked) return setInputValidation(terms.id);
    setLoading(true);
    const { data, statusCode } = await signup(state.signupForm);
    if (statusCode === 200) {
      if (IS_PROD) {
        window.googleTagManager('registration');
      }
      //@ts-expect-error
      saveAsCookie(data.jwt);
      //@ts-expect-error
      window.location = '/emailvalidation';
    } else {
      //@ts-expect-error
      setError(data.messageTranslated || data.message);
    }
    setLoading(false);
  };

  return (
    <>
      <FormWrapper>
        <form onSubmit={handleOnSubmit}>
          <div className="form-blocks">
            <Field
              //@ts-expect-error
              label={intl.formatMessage({ id: 'info.email' })}
              className={'active'}
              formType={'signin'}
              value={state.signupForm.email}
              disabled={true}
              inputValidationId={inputValidationId}
            />

            <Field
              //@ts-expect-error
              label={intl.formatMessage({ id: 'info.password' })}
              onChange={(password: any) =>
                dispatch({
                  type: actions.SIGNUP,
                  payload: { password },
                })
              }
              type={'password'}
              id={'password'}
              passwordRef={passwordRef}
              setInputValidation={setInputValidation}
              inputValidationId={inputValidationId}
            />

            <Field
              //@ts-expect-error
              label={intl.formatMessage({ id: 'info.confirmPassword' })}
              onChange={(confirmPassword: any) =>
                dispatch({
                  type: actions.SIGNUP,
                  payload: { confirmPassword },
                })
              }
              type={'password'}
              formType={'signin'}
              inputValidationId={inputValidationId}
            />

            <PhoneField
              onChange={(countryCode: any, phoneNumber: any, isValidPhone: any) => {
                dispatch({
                  type: actions.SIGNUP,
                  payload: { phone: { phoneNumber, countryCode } },
                });
              }}
              propsValue={
                state.signupForm.phone.countryCode && state.signupForm.phone.phoneNumber
                  ? state.signupForm.phone.countryCode + state.signupForm.phone.phoneNumber
                  : null
              }
              id={'phone'}
              inputValidationId={inputValidationId}
              setInputValidation={setInputValidation}
            />

            <div className="checkbox-listing" style={{ marginBottom: 20, marginTop: 40 }}>
              <CheckBox
                element={
                  <span className="checkbox-text">
                    <FormattedMessage id={'auth.iAgreeTo_'} />{' '}
                    <a target="_blank" href="https://kaspr.io/terms/">
                      <FormattedMessage id={'auth.termsOfService'} />
                    </a>{' '}
                    <FormattedMessage id={'auth.and'} />{' '}
                    <a target="_blank" href="https://kaspr.io/privacy-policy/">
                      <FormattedMessage id={'auth.privacyPolicy'} />
                    </a>
                  </span>
                }
                onChange={(acceptedTerms: any) =>
                  dispatch({ type: actions.SIGNUP, payload: { acceptedTerms } })
                }
                id={'terms'}
                inputValidationId={inputValidationId}
                required
              />

              <CheckBox
                element={
                  <span className="checkbox-text">
                    <FormattedMessage id={'auth.usefulTipsAndSpecial'} />
                  </span>
                }
                onChange={(receiveNewsletter: any) =>
                  dispatch({
                    type: actions.SIGNUP,
                    payload: { receiveNewsletter: !receiveNewsletter },
                  })
                }
              />
            </div>
          </div>
          {error && (
            <div
              className="btn-grp text-center  error  kaspr-form-text-error"
              style={{ top: -10, width: '90%' }}
            >
              {error + ' '}
            </div>
          )}

          <div className="btn-grp text-center">
            <button className="btn btn-blue btn-block h-58" type={'submit'}>
              {loading ? (
                <div>
                  <img src="/assets/images/loading.gif" alt="" style={{ width: 30 }} />{' '}
                </div>
              ) : (
                <FormattedMessage id={'auth.letsGo'} />
              )}
            </button>
          </div>
        </form>
      </FormWrapper>

      <Footer />
    </>
  );
}
function FormWrapper({ children }: any) {
  return (
    <section className="signup-wrapper" id={'signup-from-link'}>
      <Container>
        <Row>
          <Col md={6} className="mx-auto">
            <div
              className="white-bg padding40 border-radius-12 mx-auto "
              style={{ maxWidth: '450px', marginTop: 0 }}
            >
              <div className="form-heading-grp text-center">
                <h4 className="h4">
                  <FormattedMessage id={'auth.confirmYourAccount'} />
                </h4>
              </div>
              {children}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
function Footer() {
  return (
    <footer>
      <div className="container">
        <ul className="footer-list" style={{ position: 'static', marginTop: 20 }}>
          <li>©{new Date().getFullYear()} Kaspr</li>

          <li>·</li>

          <li>
            <p>
              <a target="_blank" href="https://www.kaspr.io/privacy-policy">
                <FormattedMessage id={'auth.privacy'} />
              </a>{' '}
              &amp;{' '}
              <a target="_blank" href="https://kaspr.io/terms/">
                <FormattedMessage id={'auth.terms'} />
              </a>
            </p>
          </li>
        </ul>
      </div>
    </footer>
  );
}
