import React, { useContext, useEffect, useRef, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { actions, AuthContext } from '../Store/auth.store';
import { resendCode, validateEmail } from '../../../services/api/auth.api';
//@ts-expect-error
import { Redirect } from 'react-router-dom';
import CodeVerification from 'react-verification-input';
import queryString from 'query-string';
import { IS_PROD } from '../../../constants';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import './ConfirmAccount.css';
function ConfirmAccount(props: any) {
  //@ts-expect-error
  const { state, dispatch } = useContext(AuthContext);
  const [counter, setCounter] = useState(0);
  const [loading, setLoading] = useState(false);
  const [activeButton, setActiveButton] = useState(false);
  const [codeConfirmAccount, setCodeConfirmAccount] = useState('');
  const intl = useIntl();
  const countdown = async () => {
    setCounter(60);
  };
  useEffect(() => {
    let { token } = queryString.parse(window.location.search);
    if (token) {
      validateEmail(token).then(({ data, statusCode }) => {
        //@ts-expect-error
        if (data.success) {
          //@ts-expect-error
          window.location = '/welcome';
        }
      });
    }
  }, []);
  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);

    return () => clearInterval(timer);
  }, [counter]);

  const handleOnSubmit = async (e: any) => {
    let codeConfirmAccount = state.codeConfirmAccount;
    if (e.preventDefault) e.preventDefault();
    else codeConfirmAccount = e;

    if (codeConfirmAccount && codeConfirmAccount.length === 6) {
      setLoading(true);
      const { data, statusCode } = await validateEmail(codeConfirmAccount);
      setLoading(false);
      if (statusCode !== 200) {
        props.showToastMessage(
          intl.formatMessage({
            id: 'emailValidation.toastError',
          }),
        );
        setCodeConfirmAccount(''); // Reset code
      }
      //@ts-expect-error
      if (data.success) {
        if (IS_PROD) {
          window.googleTagManager('registration');
        }
        //@ts-expect-error
        window.location = '/welcome';
      }
    }
  };

  const handleResendCode = async () => {
    const { data, statusCode } = await resendCode();
    if (statusCode === 200) {
      props.showToastMessage('success');
      await countdown();
    } else {
      props.showToastMessage(
        intl.formatMessage({
          id: 'auth.anErrorHasOccurredWhileResending',
          //@ts-expect-error
        }) + data.message,
      );
    }
  };

  const handleOnChange = (code: any) => {
    setCodeConfirmAccount(code);
    dispatch({ type: actions.ACCOUNT_VALIDATION, payload: code });
  };

  return (
    <ConfirmAccountWrapper profile={props.profile}>
      <div className="confirm-acc-ctn">
        <div className="confirm-acc-title">
          <h1>
            <FormattedMessage id="emailValidation.pleaseCheckYourEmail" />
          </h1>
          <p>
            <FormattedMessage
              id="emailValidation.verificationCodeSent"
              values={{
                value: <span>{props.profile.email}</span>,
              }}
            />

            <br />
          </p>
        </div>
        <label className="verif-code">
          <p>
            <FormattedMessage id="emailValidation.verificationCode" />:
          </p>
        </label>
        <form className="bottom-ctn">
          <div className="code-verification">
            <CodeVerification
              value={codeConfirmAccount}
              onComplete={handleOnSubmit}
              onChange={handleOnChange}
              //@ts-expect-error
              type={'number'}
              placeholder=" "
              autoFocus
              validChars="0-9"
              classNames={{
                character: 'character',
                characterFilled: 'character-filled',
              }}
            />
            {loading && <img src="/assets/images/loading.gif" className="code-loading" />}
          </div>

          <p className="info-text">
            {counter > 0 ? (
              <a className="text-btn">
                <FormattedMessage id="auth.tryAgainInSeconds." values={{ value: counter }} />
              </a>
            ) : (
              <>
                <FormattedMessage id="emailValidation.didntReceivedEmail" />{' '}
                <a href="#" className="text-btn resend-code" onClick={handleResendCode}>
                  <FormattedMessage id="auth.resendCode" />
                </a>
              </>
            )}
          </p>
        </form>
      </div>
    </ConfirmAccountWrapper>
  );
}

function ConfirmAccountWrapper({ children, profile }: any) {
  return (
    <div className="main-wraper">
      <div id="confirm-account-wrapper">{children}</div>
    </div>
  );
}

export default ConfirmAccount;
