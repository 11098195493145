import React, { useEffect, useRef, useState } from 'react';
import './AnimatedPopup.css';
import { FormattedMessage } from 'react-intl';
function AnimatedPopup({
  toggle,
  deleteLeadErrorMessage,
  onCloseProfileSideModal,
  children,
  themeColor,
}: any) {
  const [show, setShow] = useState(false);
  const [containerAnimation, setContainerAnimation] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const containerRef = useRef(null);
  useEffect(() => {
    document.addEventListener('mouseup', clickOutside);
    return () => {
      document.removeEventListener('mouseup', clickOutside);
    };
  }, []);
  const clickOutside = (e: any) => {
    const element = document.querySelector('.action-btn-listing');

    if (element.contains(e.target)) {
      onCloseProfileSideModal();
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setShow(toggle);
    }, 0);
    setContainerAnimation(toggle);
  }, [toggle]);

  useEffect(() => {
    if (deleteLeadErrorMessage) {
      setErrorMessage(deleteLeadErrorMessage);
      setTimeout(() => {
        onCloseProfileSideModal();
      }, 1000);
    }
  }, [deleteLeadErrorMessage]);

  return (
    <div
      className={'animated-popup-container ' + (containerAnimation ? 'slide-up-toggle' : '')}
      style={{ background: themeColor.background }}
    >
      <div
        className={'animated-popup popup-slide-up' + (show ? 'popup-slide-up' : '')}
        ref={containerRef}
      >
        <div style={{ borderColor: themeColor.borderColor }} className={'animated-popup-content'}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default AnimatedPopup;
