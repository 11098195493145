import React, { useContext, useEffect, useRef, useState } from 'react';
import { Tooltip } from '../../../../../components';

import { LeadsContext, actions } from '../../../store/leads.store';
import companyInfoData from '../../../LeadsInfo/Common/CompanyInfos/data';
import { FormattedMessage, useIntl } from 'react-intl';
import './LeadsItem.css';
import { imageExists } from '../../../../../services/utils/tools';
import { CREDIT_COLORS } from '../../../../../constants';
import CustomToolTip from '../../../../../components/CustomToolTip/CustomToolTip';
function LeadsItem({
  lead,
  properties,
  onLeadSelect,
  handleTemplateToggle,
  onDataDiscover,
  isVersion2,
  organization,
  hidePersonalEmails,
  itemIndex,
}: any) {
  //@ts-expect-error
  const { dispatch } = useContext(LeadsContext);
  const checkbox_ref = useRef(null);
  const [picture, setPicture] = useState(null);
  const intl = useIntl();
  useEffect(() => {
    const domain = lead.companyInfo && lead.companyInfo.domains && lead.companyInfo.domains[0];
    const url = 'https://logo.clearbit.com/' + domain;
    imageExists(url).then((result) => {
      if (result) setPicture(url);
      else setPicture('/assets/images/ic_profile.png');
    });
  }, []);
  const toggleTemplate = (e: any) => {
    if (e) e.stopPropagation();
    handleTemplateToggle(lead);
  };
  const handleOnItemClick = (e: any, type: any) => {
    //@ts-expect-error
    if (type === 'view_details') return (window.location = '/lead/' + lead._id);
    else lead.isViewDetails = false;
    if (!lead.companyInfo) lead.companyInfo = companyInfoData;

    if (!checkbox_ref.current.contains(e.target)) {
      dispatch({ type: actions.SELECT_LEAD, payload: lead });
    }
  };
  const findProperty = (leadProperty: any) => {
    let prop = Object.assign({}, lead);
    if (!prop.tags) {
      prop.tags = [];
    }
    try {
      const splittedProperty = leadProperty.split('.');
      splittedProperty.forEach((p: any) => {
        prop = prop[p];
      });
      return prop;
    } catch (err) {}
  };
  return (
    <tr>
      <td className={'checkbox-container'}>
        <div className="action-btns" style={{ transform: 'translateX(100px)' }}>
          <CustomToolTip
            element={intl.formatMessage({ id: 'leads.quickView' })}
            style={{ left: '21px' }}
          >
            <button
              className="act-btn view-btn"
              onClick={(e) => handleOnItemClick(e, 'quick_View')}
              data-tracking-id={'leads.leadsList-quickView'}
            >
              <span className="icon" />
            </button>
          </CustomToolTip>
          <CustomToolTip
            element={intl.formatMessage({ id: 'leads.viewDetails' })}
            style={{ left: '21px' }}
          >
            <button
              className="act-btn list-btn"
              data-tracking-id={'leads.leadsList-viewLeadDetails'}
              onClick={(e) => handleOnItemClick(e, 'view_details')}
            >
              <span className="icon" />
            </button>
          </CustomToolTip>
        </div>

        <div className="checkbox-wrap" ref={checkbox_ref}>
          <label>
            <input
              type="checkbox"
              defaultChecked={lead.isChecked}
              onChange={(e) => onLeadSelect(e.target.checked, lead)}
            />

            <span className="checkbox-icon"></span>
          </label>
        </div>
      </td>
      {properties.map((property: any, i: any) => {
        if (property.property === 'addedBy') {
          return (
            <td key={i}>
              <div>{lead[property.property].email}</div>
            </td>
          );
        }
        if (property.property === 'name') {
          return (
            <td key={i}>
              <div className="username view-profile">
                <span className={'company-logo'}>
                  <img src={picture} alt="" />
                </span>

                <a
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOnItemClick(e, 'view_details');
                  }}
                  href={'http://linkedin.com/in/' + lead.linkedin}
                  target={'_blank'}
                  data-tracking-id={'leads.leadsList-likedinProfile'}
                  rel="noopener noreferrer nofollow"
                  style={{ margin: 0, whiteSpace: 'nowrap' }}
                >
                  {lead.firstName + ' ' + lead.lastName}
                </a>

                {lead.linkedin ? (
                  <a
                    onClick={(e) => e.stopPropagation()}
                    href={'http://linkedin.com/in/' + lead.linkedin}
                    target={'_blank'}
                    data-tracking-id={'leads.leadsList-likedinProfile'}
                    rel="noopener noreferrer nofollow"
                    style={{ marginLeft: 10 }}
                  >
                    <img width={20} src="/assets/images/linkedin.png" alt="" />
                  </a>
                ) : (
                  <p>{lead.firstName + ' ' + lead.lastName}</p>
                )}
              </div>
            </td>
          );
        }
        if (property.property == 'currentPersonalEmail' && !findProperty(property.property)) {
          return (
            <td key={i}>
              {!hidePersonalEmails ? (
                <ShowDataButton
                  lead={lead}
                  type={'personalEmailCredits'}
                  onDataDiscover={onDataDiscover}
                  isVersion2={isVersion2}
                />
              ) : (
                <span></span>
              )}
            </td>
          );
        }
        if (property.property == 'currentProEmail' && !findProperty(property.property)) {
          return (
            <td key={i}>
              <ShowDataButton
                lead={lead}
                type={'workEmailCredits'}
                onDataDiscover={onDataDiscover}
                isVersion2={isVersion2}
              />
            </td>
          );
        }
        if (
          typeof findProperty(property.property) === 'string' ||
          typeof findProperty(property.property) === 'number' ||
          findProperty(property.property) === null
        ) {
          if (
            property.property == 'currentProEmail' ||
            property.property == 'currentPersonalEmail'
          ) {
            return (
              <td key={i}>
                {findProperty(property.property + 's')[0]}
                {findProperty(property.property + 's').length > 1 && (
                  <CustomToolTip
                    element={findProperty(property.property + 's')}
                    position={itemIndex < 2 ? 'right' : 'top'}
                  >
                    <p className="contact-more tip">{findProperty(property.property).length - 1}</p>
                  </CustomToolTip>
                )}
              </td>
            );
          }

          return (
            <td key={i}>
              {findProperty(property.property) && findProperty(property.property).length > 25 ? (
                <CustomToolTip element={findProperty(property.property)}>
                  <span>{findProperty(property.property).substring(0, 25) + '...'}</span>
                </CustomToolTip>
              ) : (
                <span>
                  {findProperty(property.property) === 'Default list' ? (
                    <FormattedMessage id={'leads.defaultList'} />
                  ) : (
                    findProperty(property.property)
                  )}{' '}
                </span>
              )}
            </td>
          );
        }
        if (property.property === 'tags') {
          return (
            <td key={i}>
              <div style={{ width: 'max-content' }}>
                {findProperty(property.property)[0] && (
                  <span
                    style={{
                      background: findProperty(property.property)[0].color + '1A',
                      color: findProperty(property.property)[0].color,
                      borderRadius: 20,
                      margin: '5px 0',
                      padding: 5,
                    }}
                  >
                    {findProperty(property.property)[0].value}{' '}
                  </span>
                )}
                {findProperty(property.property).length > 1 && (
                  <CustomToolTip
                    position={itemIndex < 2 ? 'right' : 'top'}
                    element={
                      <div
                        style={{
                          background: '#fff',
                          borderRadius: 5,
                          padding: 10,
                        }}
                      >
                        {findProperty(property.property)
                          .slice(1)
                          //@ts-expect-error
                          .map((tag, i) => (
                            <div
                              key={i}
                              style={{
                                background: tag.color + '1A',
                                color: tag.color,
                                borderRadius: 20,
                                margin: '5px 0',
                                padding: '2px 5px',
                              }}
                            >
                              {tag.value}
                            </div>
                          ))}
                      </div>
                    }
                  >
                    <p className="contact-more tip">{findProperty(property.property).length - 1}</p>
                  </CustomToolTip>
                )}
              </div>
            </td>
          );
        }
        if (Array.isArray(findProperty(property.property))) {
          if (property.property == 'phones' && findProperty(property.property).length == 0) {
            return (
              <td key={i}>
                <ShowDataButton
                  lead={lead}
                  type={'phoneCredits'}
                  onDataDiscover={onDataDiscover}
                  isVersion2={isVersion2}
                />
              </td>
            );
          }

          return (
            <td key={i}>
              {findProperty(property.property)[0]}
              {findProperty(property.property).length > 1 && (
                <CustomToolTip
                  element={findProperty(property.property)}
                  position={itemIndex < 2 ? 'right' : 'top'}
                >
                  <p className="contact-more tip">{findProperty(property.property).length - 1}</p>
                </CustomToolTip>
              )}
            </td>
          );
        }
      })}
    </tr>
  );
}
function ShowDataButton({ lead, type, onDataDiscover, isVersion2 }: any) {
  if (!isVersion2) return <span>--</span>;
  return (
    <button
      className={'btn btn2 show-data'}
      onClick={() => onDataDiscover({ lead, type: CREDIT_COLORS[type].creditMap })}
      style={{
        borderColor: CREDIT_COLORS[type].color,

        color: CREDIT_COLORS[type].color,
      }}
    >
      <img src={CREDIT_COLORS[type].icon} alt="" />{' '}
      <FormattedMessage id={'show'} defaultMessage={'Show'} />
    </button>
  );
}
export default LeadsItem;
