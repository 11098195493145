import React from 'react';

import Notifications from './notifications/notifications';

import PreLeads from './preLeads/preLeads';
import { ToastContainer } from 'react-toastify';

export default function WaitingList(props: any) {
  return (
    <div id="waitingList_tab active" className="tab-pane fade global-fade-in">
      <Notifications {...props} />

      <PreLeads {...props} />
    </div>
  );
}
