const initState = {};

export const notifications = (state = initState, action: any) => {
  switch (action.type) {
    case 'GET_NOTIFICATIONS':
      state = {
        ...action.notifications,
      };
      break;
  }
  return state;
};
