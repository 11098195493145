import { checkPermission } from '../../services/utils';

export function usePermissions(planPermissions: any, memberPermissions: any, permissionName: any) {
  let checkPlanPermission: any = () => true;
  let checkMemberPermission: any = () => true;
  if (planPermissions) {
    checkPlanPermission = (action: any) =>
      checkPermission({
        permissions: planPermissions,
        name: permissionName,
        action,
      });
  }

  if (memberPermissions) {
    checkMemberPermission = (action: any) =>
      checkPermission({
        permissions: memberPermissions,
        name: permissionName,
        action,
      });
  }

  return [checkPlanPermission, checkMemberPermission];
}
