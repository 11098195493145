import React, { useEffect, useState } from 'react';

import PopUp from '../../../../../components/PopUp/PopUp';
import { FormattedMessage, useIntl } from 'react-intl/lib';
import './FinalConfirmationModal.css';
import { FormattedDate } from 'react-intl';

function FinalConfirmationModal({
  subscription,
  toggle,
  optionSelected,
  handleUnsubscribe,
  handlePreviousModal,
  handleCloseModal,
}: any) {
  const [checkboxes, setCheckBoxes] = useState({
    credits_checkbox: false,
    features_checkbox: false,
    date_checkbox: false,
    warning_checkbox: false,
    all_checked: false,
  });

  const handleUnsubscribeBtn = () => {
    handleUnsubscribe(optionSelected.reason);
  };

  useEffect(() => {
    const allChecked =
      checkboxes.credits_checkbox &&
      checkboxes.features_checkbox &&
      checkboxes.date_checkbox &&
      checkboxes.warning_checkbox;
    if (checkboxes.all_checked !== allChecked) {
      setCheckBoxes((prev) => ({ ...prev, all_checked: allChecked }));
    }
  }, [
    checkboxes.credits_checkbox,
    checkboxes.features_checkbox,
    checkboxes.date_checkbox,
    checkboxes.warning_checkbox,
  ]);

  return (
    <PopUp
      toggle={toggle}
      onClose={handleCloseModal}
      style={{
        width: '80vw',
        maxWidth: '500px',
        height: 'auto',
        position: 'absolute',
        top: '7vh',
      }}
    >
      <div id="final-confirmation-modal">
        <div className="final-confirmation-title">
          <img src="/assets/images/lock.svg" alt="lock" />

          <h2>
            <FormattedMessage id="cancellationflow.finalConfirmation" />
          </h2>
        </div>

        <h1>
          <FormattedMessage id="cancellationflow.areYouSure" />
        </h1>

        <h3>
          <FormattedMessage id="cancellationflow.youWillLose" />
        </h3>

        <div className="checkboxes">
          <label id="checkboxes-list-container">
            <input
              type="checkbox"
              onChange={() =>
                setCheckBoxes((prev) => ({
                  ...prev,
                  credits_checkbox: !prev.credits_checkbox,
                }))
              }
              checked={checkboxes.credits_checkbox}
            />

            <span className="checkbox-icon icon" />

            <span className="checkbox-text">
              <FormattedMessage
                id={'cancellationflow.remainingCreditsWillBeDeletedOn'}
                values={{ strong: (chunk) => <strong> {chunk} </strong> }}
              />
            </span>

            <span className="checkbox-date">
              <FormattedDate
                value={new Date(subscription.endDate)}
                year="2-digit"
                month="2-digit"
                day="2-digit"
              />
            </span>
          </label>

          <label id="checkboxes-list-container">
            <input
              type="checkbox"
              onChange={() =>
                setCheckBoxes((prev) => ({
                  ...prev,
                  features_checkbox: !prev.features_checkbox,
                }))
              }
              checked={checkboxes.features_checkbox}
            />

            <span className="checkbox-icon icon" />

            <span className="checkbox-text">
              <FormattedMessage
                id={'cancellationflow.noAccessToKasprFeatures'}
                values={{ strong: (chunk) => <strong> {chunk} </strong> }}
              />
            </span>

            <span style={{ fontWeight: 600 }}>
              <FormattedDate
                value={new Date(subscription.endDate)}
                year="2-digit"
                month="2-digit"
                day="2-digit"
              />
            </span>
          </label>

          <label id="checkboxes-list-container">
            <input
              type="checkbox"
              onChange={() =>
                setCheckBoxes((prev) => ({
                  ...prev,
                  date_checkbox: !prev.date_checkbox,
                }))
              }
              checked={checkboxes.date_checkbox}
            />

            <span className="checkbox-icon icon" />

            <span className="checkbox-text">
              <FormattedMessage
                id="cancellationflow.subscriptionCancellationWillTakeEffect"
                values={{ strong: (chunk) => <strong> {chunk} </strong> }}
              />
            </span>

            <span className="checkbox-date">
              <FormattedDate
                value={new Date(subscription.endDate)}
                year="2-digit"
                month="2-digit"
                day="2-digit"
              />
            </span>
          </label>

          <label id="checkboxes-list-container icon">
            <input
              type="checkbox"
              onChange={() =>
                setCheckBoxes((prev) => ({
                  ...prev,
                  warning_checkbox: !prev.warning_checkbox,
                }))
              }
              checked={checkboxes.warning_checkbox}
            />

            <span className="checkbox-icon icon" />

            <span className="checkbox-text">
              <FormattedMessage
                id={'cancellationflow.thisActionIsImmediateIrreversibleAndDefinitive'}
                values={{ strong: (chunk) => <strong> {chunk} </strong> }}
              />
            </span>
          </label>
        </div>

        <div className="buttons-container">
          <button className=" btn2 ripple-btn" onClick={handlePreviousModal}>
            <FormattedMessage id={'cancellationflow.Back'} />
          </button>

          <button
            className={
              checkboxes.all_checked
                ? 'btn ripple-btn btn-outline-danger'
                : 'btn ripple-btn btn-secondary'
            }
            disabled={!checkboxes.all_checked}
            style={checkboxes.all_checked ? { cursor: 'pointer' } : { cursor: 'not-allowed' }}
            onClick={handleUnsubscribeBtn}
          >
            <FormattedMessage id="cancellationflow.unsubscribe" />
          </button>
        </div>
      </div>
    </PopUp>
  );
}

export default FinalConfirmationModal;
