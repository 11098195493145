import React, { useReducer, useMemo, useEffect, useState, useRef } from 'react';
import { homeReducer, homeInitialState } from './store/home.store';
import { FormattedMessage } from 'react-intl';
import HomeProvider from './store/home.provider';
import './Home.css';
import { Header, ContentWrapperUi, SideNav, PreLoader } from '../../components';
import { Col } from 'react-bootstrap';
import { leadsFilter } from '../../services/api/leads.api';
import actions from './store/home.actions';
import { ToastContainer } from 'react-toastify';
import { OnBoardingSideModal, Onboarding } from './Onboarding/Onboarding';
import { useUserSteps } from './Home.hooks';
import { getWorkflows } from '../../services/api/workflows.api';
import { getNotifications } from '../../services/api/notifications.api';
import SettingsProvider from '../Settings/store/Settings.provider';
import { useNavigate } from 'react-router-dom';

/**
 * Home page
 * @component
 * @param props {Object} redux props
 * @returns {JSX.Element} The Home page
 */
function Home(props: any) {
  const [state, dispatch] = useReducer(homeReducer, homeInitialState);
  const [isAdmin, setIsAdmin] = useState(null);
  const [onboardingToggle, setOnboardingToggle] = useState(null);

  const [currentTab, setCurrentTab] = useState('onboarding');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const contentWrapRef = useRef(null);

  const targetSelectors = [
    'a[href]', // Targets only links with an href attribute
    'a[href] *', // Also targets child elements within links with href
  ];

  // Selectors for elements to exclude from triggering handleSkipSteps
  const excludedSelectors = [
    '.exclude-skip', // CSS class
  ];

  const {
    steps,
    selectedStep,
    setSelectedStep,
    onSendInvitations,
    onDeleteInvitations,
    onCreateWorkspace,
    urlSegment,
    location,
    handleSkipSteps,
  } = useUserSteps(
    props.redux.profile,
    contentWrapRef,
    props.redux.profile.nextStep,
    setIsLoading,
    excludedSelectors,
    targetSelectors,
  );

  const store = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  let isVersion2 = props.redux.credits && props.redux.credits.isVersion(2);

  // Handle initial state and onboarding-related side effects
  useEffect(() => {
    if (!isVersion2) return handleToggle('home');
    let oldUser = false;
    const emailValidation = props.redux.profile.steps.find(
      (stp: any) => stp.id === 'emailValidation',
    );
    const joinWorkspace = props.redux.profile.steps.find((stp: any) => stp.id === 'joinWorkspace');
    if (!joinWorkspace) {
      oldUser = true;
    }
    const pluginInstallation = props.redux.profile.steps.find(
      (stp: any) => stp.id === 'pluginInstallation',
    );
    const linkedinValidation = props.redux.profile.steps.find(
      (stp: any) => stp.id === 'linkedinValidation',
    );

    if (
      urlSegment ||
      location.pathname === '/onboarding' ||
      (emailValidation &&
        emailValidation.done &&
        joinWorkspace &&
        joinWorkspace.done &&
        pluginInstallation &&
        pluginInstallation.done &&
        linkedinValidation &&
        linkedinValidation.done &&
        !oldUser &&
        props.redux.profile.nextStep !== -1) ||
      (oldUser &&
        emailValidation &&
        emailValidation.done &&
        pluginInstallation &&
        pluginInstallation.done &&
        linkedinValidation &&
        linkedinValidation.done &&
        props.redux.profile.nextStep !== -1)
    ) {
      handleToggle('onboarding');
    } else {
      const sideNavIcon = document.querySelector('#onboarding-icon');
      if (sideNavIcon) {
        sideNavIcon.classList.remove('onBoarding-home-icon-change-style');
      }
    }
  }, [state]);

  // Load initial data
  useEffect(() => {
    if (
      props.redux.organization &&
      props.redux.organization.organization &&
      props.redux.organization.organization.members
    ) {
      if (
        props.redux.organization.member.type === 'owner' ||
        props.redux.organization.member.type === 'admin'
      ) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    }
    dispatch({
      //@ts-expect-error
      type: actions.LEADS_LOADING,
      payload: true,
    });
    leadsFilter({}).then(({ data }) => {
      dispatch({ type: actions.GET_LEADS, payload: data });
      dispatch({
        //@ts-expect-error
        type: actions.LEADS_LOADING,
        payload: false,
      });
    });
    //@ts-expect-error
    getWorkflows().then(({ data, statusCode }) => {
      if (statusCode === 200) {
        dispatch({ type: actions.GET_WORKFLOWS, payload: data });
      }
    });
    getNotifications().then(({ data }) => {
      dispatch({ type: actions.GET_NOTIFICATIONS, payload: data });
    });

    const params = new URLSearchParams(window.location.search);
    const openChatbot = params.get('openChatbot');
    if (openChatbot === 'true') {
      const chatButton = document.querySelector('.intercom-launcher');

      if (chatButton) {
        //@ts-expect-error
        chatButton.click();
      }
    }
  }, []);

  // Show a preloader if data is not yet loaded
  if (!state.leads || !props.redux.credits.remaining || isLoading) {
    return <PreLoader />;
  }

  /**
   * Handle toggling onboarding state
   * @param {string} type - The type of toggle ('onboarding' or 'home')
   */
  function handleToggle(type: any) {
    const sideNavElement = document.querySelector('.side-nav-container');
    const logoElement = document.querySelector('.site-logo');
    const expandElement = document.querySelector('.content-wrap');
    const sideNavIcon = document.querySelector('#onboarding-icon');

    if (sideNavElement) {
      sideNavElement.classList[type === 'onboarding' ? 'add' : 'remove']('fix-0-top');
      sideNavElement.classList[type === 'onboarding' ? 'add' : 'remove']('small-navbar');
    }

    if (logoElement) logoElement.classList[type === 'onboarding' ? 'add' : 'remove']('s-logo');
    if (expandElement) expandElement.classList[type === 'onboarding' ? 'add' : 'remove']('expand');

    setOnboardingToggle(type === 'onboarding');
    setCurrentTab(type);

    navigate(`/onboarding/${urlSegment || 'discover'}`);
  }

  return (
    <HomeProvider store={store}>
      <SettingsProvider store={store}>
        <Header
          redux={props.redux}
          currentPage={<FormattedMessage id={'sidenav.leads'} />}
          profile={props.redux.profile}
          credits={props.redux.credits}
          remaining={props.redux.credits.remaining}
          organization={props.redux.organization}
        />

        <ContentWrapperUi>
          <SideNav navToggle={props.redux.globalReducer.navToggle} isOnboarding />
          <Col md={10} className="content-wrap" ref={contentWrapRef}>
            <div className={'home-container'}>
              {onboardingToggle && isVersion2 && (
                <OnBoardingSideModal
                  organization={props.redux.organization}
                  onTabClick={handleToggle}
                  onStepClick={setSelectedStep}
                  steps={steps}
                  selectedStep={selectedStep}
                  handleSkipSteps={handleSkipSteps}
                  profile={props.redux.profile}
                />
              )}

              <div className="content-block">
                <div className="inner-content-wrap dashboard-warpper">
                  <div className="wd-wrapper">
                    <HomeNav
                      onTabClick={handleToggle}
                      stepsAreDone={selectedStep === 'all_done'}
                      currentTab={currentTab}
                      isVersion2={isVersion2}
                    />

                    <div className="tab-content">
                      {currentTab === 'onboarding' && isVersion2 && (
                        <Onboarding
                          selectedStep={selectedStep}
                          steps={steps}
                          sendInvitations={onSendInvitations}
                          deleteInvitations={onDeleteInvitations}
                          createWorkSpace={onCreateWorkspace}
                          user={props.redux.profile}
                          organization={
                            props.redux.organization && props.redux.organization.organization
                          }
                          remaining={props.redux.credits.remaining}
                          organizationCredits={props.redux.credits.organizationCredits}
                          personalCredits={props.redux.credits.personalCredits}
                          userInfo={props.redux.profile}
                          onCreateWorkspace={onCreateWorkspace}
                          handleSkipSteps={handleSkipSteps}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </ContentWrapperUi>

        <ToastContainer />
      </SettingsProvider>
    </HomeProvider>
  );
}

/**
 * Internal navigation component for the Home page
 * @param {Object} props - Props passed to the component
 * @returns {JSX.Element} The internal navigation for Home
 */
function HomeNav({ onTabClick, stepsAreDone, currentTab, isVersion2 }: any) {
  const handleExpand = () => {
    const sideNavElement = document.querySelector('.side-nav-container');
    const logoElement = document.querySelector('.site-logo');
    const expandElement = document.querySelector('.content-wrap');

    if (sideNavElement) sideNavElement.classList.add('small-navbar');
    if (logoElement) logoElement.classList.add('s-logo');
    if (expandElement) expandElement.classList.add('expand');
  };

  if (stepsAreDone || !isVersion2) {
    return (
      <div className="workflow-detail-header">
        <div className="cstm-tabs-ui cs-tabs-slider">
          <ul className="nav nav-tabs">
            <li onClick={() => onTabClick('home')}>
              <a id="home" className="active">
                <FormattedMessage id={'tabs.yourDashboard'} />
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  return (
    <div className="workflow-detail-header">
      <div className="cstm-tabs-ui cs-tabs-slider">
        <ul className="nav nav-tabs">
          <li onClick={() => onTabClick('onboarding')}>
            <a id="home-onboarding" className={currentTab === 'onboarding' ? 'active' : ''}>
              <FormattedMessage id={'home.onboarding'} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Home;
