import React, { useMemo, useReducer } from 'react';
import { authInitialState, authReducer } from './Store/auth.store';

import AuthProvider from './Store/auth.provider';

import SigninForm from './Signin/Signin';

import SignupForm from './Signup/Signup';

import ConfirmAccount from './ConfirmAccount/ConfirmAccount';

import AuthHeader from './common/AuthHeader/AuthHeader';

import { OnBoardingSteps } from './Onboarding/Onboarding';
import { Header } from '../../components';
import './Auth.css';

import { ForgotPassword, ResetPassword } from './ForgotPassword/ForgotPassword';
import { toast, ToastContainer } from 'react-toastify';
import { useIntl } from 'react-intl';
import SignupFromLink from './SingupFromLink/SignupFromLink';
import useUserSteps from '../../services/utils/useUserSteps';
import StatusBar from '../../components/StatusBar/StatusBar';
import { useEvaluatedFlags } from '../../providers/LDProvider/LDProvider';

function Signin() {
  const [state, dispatch] = useReducer(authReducer, authInitialState);
  const store = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return (
    <AuthProvider store={store}>
      <div className={'main-wraper'}>
        <AuthHeader />

        <SigninForm />
      </div>
    </AuthProvider>
  );
}

function Signup(props: any) {
  const [state, dispatch] = useReducer(authReducer, authInitialState);
  const store = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  const { isEmailValidationDone } = useUserSteps(props.redux, props.redux.profile.nextStep);

  // Feature flag - show progress bar
  const flags: any = useEvaluatedFlags(['showSignUpFlowProgressBar']);

  return (
    <AuthProvider store={store}>
      <div className={'main-wraper'}>
        {isEmailValidationDone && flags.showSignUpFlowProgressBar && <StatusBar percent={30} />}
        {isEmailValidationDone ? (
          <Header
            redux={props.redux}
            currentPage={''}
            profile={props.redux.profile}
            isOnBoardingPage
            organization={props.redux.organization}
          />
        ) : (
          <AuthHeader type={'signup'} />
        )}
        <SignupForm redux={props.redux} />
      </div>
    </AuthProvider>
  );
}

function SignupCodeConfirmation(props: any) {
  const [state, dispatch] = useReducer(authReducer, authInitialState);
  const intl = useIntl();
  const store = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  // Feature flag - show progress bar
  const flags: any = useEvaluatedFlags(['showSignUpFlowProgressBar']);

  return (
    <AuthProvider store={store}>
      <div className={'main-wraper'}>
        {flags.showSignUpFlowProgressBar && <StatusBar percent={30} />}
        <Header
          redux={props.redux}
          currentPage={''}
          profile={props.redux.profile}
          isOnBoardingPage
          organization={props.redux.organization}
        />
        <ConfirmAccount
          profile={props.redux.profile}
          showToastMessage={(response: any) => {
            response == 'success'
              ? toast.success(
                  intl.formatMessage({
                    id: 'auth.codeSuccessfullyResent',
                  }),
                )
              : toast.error(response);
          }}
        />
      </div>

      <ToastContainer position="bottom-right" style={{ top: 100 }} />
    </AuthProvider>
  );
}

function Onboarding(props: any) {
  const flags: any = useEvaluatedFlags(['showCountdownDelay', 'showSignUpFlowProgressBar']);
  const [state, dispatch] = useReducer(authReducer, authInitialState);
  const store = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return (
    <AuthProvider store={store}>
      <div className={'main-wraper'}>
        {flags.showSignUpFlowProgressBar && <StatusBar percent={90} />}
        <Header
          redux={props.redux}
          currentPage={''}
          profile={props.redux.profile}
          isOnBoardingPage
          organization={props.redux.organization}
        />
        <OnBoardingSteps
          profile={props.redux.profile}
          resetCredits={props.redux.resetCredits}
          isFlagOn={flags.showCountdownDelay}
        />
      </div>
    </AuthProvider>
  );
}

function ForgotPasswd() {
  const [state, dispatch] = useReducer(authReducer, authInitialState);
  const store = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return (
    <AuthProvider store={store}>
      <div className={'main-wraper'}>
        <AuthHeader />

        <ForgotPassword />
      </div>
    </AuthProvider>
  );
}

function ResetPasswd() {
  const [state, dispatch] = useReducer(authReducer, authInitialState);
  const store = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return (
    <AuthProvider store={store}>
      <div className={'main-wraper'}>
        <AuthHeader />

        <ResetPassword />
      </div>
    </AuthProvider>
  );
}
function SignupFromLinkParams() {
  const [state, dispatch] = useReducer(authReducer, authInitialState);
  const store = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return (
    <AuthProvider store={store}>
      <div className={'main-wraper'}>
        <AuthHeader type={'confirmAccount'} />
        <SignupFromLink />
      </div>
    </AuthProvider>
  );
}

export {
  Signin,
  Signup,
  Onboarding,
  SignupCodeConfirmation,
  ForgotPasswd,
  ResetPasswd,
  SignupFromLinkParams,
};
