import React, { useContext, useEffect, useState } from 'react';

import LeadsItem from './LeadsItem/LeadsItem';

import { LeadsContext } from '../../store/leads.store';

import { actions } from '../../store/leads.store';
import { ContentWrapperUi, ModalMessage } from '../../../../components';
import { getAccounts } from '../../../../services/api/integrations.api';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { exploreProfile, getProfileData } from '../../../../services/api/profile.api';
import { leadsFilter, updateLeadInfos } from '../../../../services/api/leads.api';
import { toast } from 'react-toastify';

function LeadsList({
  handleEmailToggle,
  setEmailToggle,
  emailToggle,
  setPermissionToggle,
  permissionToggle,
  setSelectedLead,
  selectedLead,
  isVersion2,
  organization,
  hidePersonalEmails,
}: any) {
  //@ts-expect-error
  const { state, dispatch } = useContext(LeadsContext);
  const [templateToggle, setTemplateToggle] = useState(false);
  const [template, setTemplate] = useState('');
  const [subject, setSubject] = useState('');
  const [account, setAccount] = useState(false);
  const intl = useIntl();

  const handleTemplateChange = (value: any, type: any) => {
    if (type === 'template') setTemplate(value);
    else setSubject(value);
  };

  const handleTemplateToggle = () => {
    setEmailToggle(!emailToggle);
    setTemplateToggle(!templateToggle);
  };

  const properties = state.properties.filter((prop: any) => prop.isChecked || prop.isFixed);
  const handleSelectLead = (isChecked: any, selectedLead: any) => {
    setSelectedLead(selectedLead);
    const leads = state.leads.map((lead: any) => {
      if (lead._id === selectedLead._id) {
        lead.isChecked = isChecked;
      }
      return lead;
    });
    dispatch({ type: actions.GET_LEADS, payload: leads, pages: state.pages });
    //@ts-expect-error
    dispatch({ type: actions.LOADING, payload: false });
  };
  const handleSelectAllLeads = (e: any) => {
    const leads = state.leads.map((lead: any) => {
      lead.isChecked = e.target.checked;
      return lead;
    });
    dispatch({ type: actions.GET_LEADS, payload: leads, pages: state.pages });
    //@ts-expect-error
    dispatch({ type: actions.LOADING, payload: false });
  };
  useEffect(() => {
    getAccounts().then(({ data }) => {
      //@ts-expect-error
      if (data && data.accounts && data.accounts.gmail && data.accounts.gmail.gmailEmail)
        setAccount(true);
    });
    return () => {
      setAccount(false);
    };
  }, []);
  const handleDataDiscover = async ({ lead, type }: any) => {
    const { data, statusCode } = await getProfileData({
      name: lead.name,
      id: lead.linkedin,
      types: [type],
      source: 'Linkedin',
    });
    if (statusCode == 200) {
      const dataErrorTypes = {
        legacyCredits: false,
        workEmailCredits: 'No B2B email found',
        personalEmailCredits: 'No direct email found',
        phoneCredits: 'No phone found',
        exportCredits: false,
      };
      //@ts-expect-error
      if (!data.discovered[type + 'Credits']) {
        //@ts-expect-error
        return toast.error(dataErrorTypes[type + 'Credits']);
      }
      //@ts-expect-error
      const leadToUpdate = { ...lead, ...data.profile };
      const { statusCode: updateLeadStatusCode, data: leadUpdatedData } =
        await updateLeadInfos(leadToUpdate);
      if (updateLeadStatusCode == 200) {
        leadsFilter({ data: state.dataForm }).then((results) => {
          dispatch({
            type: actions.GET_LEADS,
            //@ts-expect-error
            payload: results.data.leads,
            //@ts-expect-error
            pages: results.data.pages,
          });
        });
      } else {
        //@ts-expect-error
        toast.error(leadUpdatedData.message);
      }
    } else {
      //@ts-expect-error
      toast.error(data.message);
    }
  };
  return (
    <div className="table-content">
      <table width="100%">
        <thead>
          <tr>
            <th>
              <div className="checkbox-wrap" data-tracking-id={'leads.leadsList-selectAllLeads'}>
                <label>
                  <input type="checkbox" value="" onChange={handleSelectAllLeads} />

                  <span className="checkbox-icon"></span>
                </label>
              </div>
            </th>
            {/*<th></th>*/}
            {properties.map((prop: any, i: any) => {
              return (
                <th key={i}>
                  <FormattedMessage id={'leads.properties.' + prop.property} />
                </th>
              );
            })}

            <th key={properties && properties.length} style={{ width: '50px' }}>
              {' '}
            </th>
          </tr>
        </thead>

        <tbody>
          {state.leads &&
            state.leads.map((lead: any, index: any) => (
              <LeadsItem
                key={lead._id}
                itemIndex={index}
                lead={lead}
                properties={properties}
                onLeadSelect={handleSelectLead}
                handleTemplateToggle={(lead: any) => handleEmailToggle(lead)}
                onDataDiscover={handleDataDiscover}
                isVersion2={isVersion2}
                organization={organization}
                hidePersonalEmails={hidePersonalEmails}
              />
            ))}
        </tbody>
      </table>

      <ModalMessage
        toggle={permissionToggle}
        setToggle={() => setPermissionToggle(!permissionToggle)}
        title={intl.formatMessage({ id: 'permissions.denied' })}
        textElement={
          account ? (
            <>
              <FormattedMessage id={'permissions.upgradePlan'} /> <br />
              <a target="_blank" href="" onClick={() => (window.location.href = '/billing')}>
                <FormattedMessage id={'settings.clickHereToCheckOurDeals'} />
              </a>
            </>
          ) : (
            <>
              <FormattedMessage id={'settings.youNeedConnectToGmail'} /> <br />
              <a
                target="_blank"
                href=""
                onClick={() => (window.location.href = '/settings?integration=true')}
              >
                <FormattedMessage id={'settings.clickHereToConnect'} />
              </a>
            </>
          )
        }
        action={'Ok'}
        handleAction={() => setPermissionToggle(!permissionToggle)}
        forbidCancel={true}
      />
    </div>
  );
}

export default LeadsList;
