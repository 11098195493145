import React from 'react';
import { WorkflowContext } from './workflow.store';
//@ts-expect-error
import { PropTypes } from 'prop-types';

function WorkflowProvider({ children, store }: any) {
  return (
    <WorkflowContext.Provider value={store}>
      <main id="workflow">{children}</main>
    </WorkflowContext.Provider>
  );
}

WorkflowProvider.propTypes = {
  store: PropTypes.shape({
    state: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  }),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default WorkflowProvider;
