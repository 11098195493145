import React from 'react';
import { useIntl } from 'react-intl';
import { getCurrency } from '../../services/utils/tools';

const NumberFormatter = ({
  value,
  user = null, // User object to retrieve currency information
  customer = null, // Customer object to retrieve currency information
  currency = false, // Boolean to enable automatic currency symbol
  decimals = false, // Option to display decimals
}: any) => {
  const intl = useIntl();

  // Retrieve the currency symbol from getCurrency
  const currencySymbol = currency ? getCurrency(user, customer) : '';

  // Define formatting options for the number, based on locale
  const options = {
    minimumFractionDigits: decimals ? 2 : 0,
    maximumFractionDigits: decimals ? 2 : 0,
  };

  // Format the number according to the user's locale
  const formattedNumber = new Intl.NumberFormat(intl.locale, options).format(value);

  // Display the formatted number with the currency symbol
  return (
    <span>
      {currencySymbol && intl.locale.startsWith('en')
        ? `${currencySymbol}${formattedNumber}`
        : `${formattedNumber}${currencySymbol}`}
    </span>
  );
};

export default NumberFormatter;
