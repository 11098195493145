/*
export const checkPermission = ({
  permissions,
  name,
  action,
  planPermissions,
}) => {
  let isAllowed = false;
  let isPlanPermissionAllowed = true;
  if (permissions && name && action) {
    isAllowed = permissions.find((p) => p.name === name);
    if (isAllowed) isAllowed = isAllowed.actions.find((a) => a == action);
  }
  if (planPermissions && name && action) {
    isPlanPermissionAllowed = planPermissions.find((p) => p.name === name);
    if (isPlanPermissionAllowed)
      isPlanPermissionAllowed = isPlanPermissionAllowed.actions.find(
        (a) => a == action
      );
  }
  return (isAllowed && isPlanPermissionAllowed);
};
*/

export const checkPermission = ({ permissions, name, action }: any) => {
  let isAllowed = false;
  let isPlanPermissionAllowed = true;
  if (permissions && name && action) {
    isAllowed = permissions.find((p: any) => p.name === name);
    //@ts-expect-error
    if (isAllowed) isAllowed = isAllowed.actions.find((a: any) => a == action);
  }
  return isAllowed && isPlanPermissionAllowed;
};

export const checkIsPlanLimited = ({ permissions, name }: any) => {
  let isPrevent = false;
  if (permissions && name) {
    isPrevent = permissions.find((p: any) => p.name === name);
  }
  //return isPrevent && isPrevent.limited;
  return false;
};

export const verifyPlanPermission = ({ permissions, name }: any) => {
  let isPrevent = false;
  if (permissions && name) {
    isPrevent = permissions.find((p: any) => p.name === name);
  }
  return !!isPrevent;
};
