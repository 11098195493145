import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react';

import DataNotFound from '../../../../../components/DataNotFound/DataNotFound';
import {
  ModalMessage,
  Pagination,
  PreLoader,
  SelectPicker,
  Tooltip,
} from '../../../../../components';
import {
  deleteProfiles,
  exportCsv,
  exportExcel,
  getFlowInputs,
  getProfiles,
} from '../../../../../services/api/workflows.api';
import { actions, WorkflowContext } from '../../../store/workflow.store';
import { Modal } from 'react-bootstrap';
import { checkPermission } from '../../../../../services/utils';

import { ToastContainer, toast } from 'react-toastify';

import useFullPageLoader from '../../../../../components/FullPageLoader/FullPageLoader';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { download_file } from '../../../../../services/utils/tools';
import CustomToolTip from '../../../../../components/CustomToolTip/CustomToolTip';

export default function Profiles({
  profiles,
  workflow,
  flowInputs,
  profilePermissions,
  planPermissions,
}: any) {
  const intl = useIntl();
  //@ts-expect-error
  const { state, dispatch } = useContext(WorkflowContext);
  const [page, goToPage] = useState(1);
  const [filterValue, setFilterValue] = useState('');
  const [deleteProfileModal, setDeleteProfileModal] = useState('');
  const [loader, handleLoader, setCounter] = useFullPageLoader();
  const EXCEL_LIMIT = 20000;
  const [excelModal, setExcelModal] = useState(false);
  const [error, setError] = useState(false);

  let chunks = [{ start: 1, end: EXCEL_LIMIT }];
  if (state.profiles.pages) {
    while (chunks[chunks.length - 1].end < state.profiles.pages.totalProfiles)
      chunks.push({
        start: chunks[chunks.length - 1].end + 1,
        end: Math.min(
          chunks[chunks.length - 1].end + EXCEL_LIMIT,
          state.profiles.pages.totalProfiles,
        ),
      });
  }

  const memberViewPermission = checkPermission({
    permissions: profilePermissions,
    name: 'Workflow',
    action: 'View',
  });
  const planViewPermission = checkPermission({
    permissions: planPermissions,
    name: 'Workflow',
    action: 'View',
  });
  const memberExecutePermission = checkPermission({
    permissions: profilePermissions,
    name: 'Workflow',
    action: 'Execute',
  });
  const planExecutePermission = checkPermission({
    permissions: planPermissions,
    name: 'Workflow',
    action: 'Execute',
  });
  const getBlocksFilter = (value: any) => {
    let blocks: any = [];
    switch (value) {
      case intl.formatMessage({ id: 'workflow.invitationAccepted' }):
        blocks = [
          '9',
          '10',
          '15',
          'state-15',
          '11',
          '12',
          '14',
          'state-14',
          '13',
          'state-13',
          '8',
          '16',
          '21',
          'state-21',
          '17',
          '18',
          '20',
          'state-20',
          '19',
          'state-19',
        ];
        break;
      case intl.formatMessage({ id: 'workflow.replyMessages' }):
        blocks = ['21', 'state-21', '20', 'state-20', '15', 'state-15', '14', 'state-14'];
        break;
      case intl.formatMessage({ id: 'workflow.matchedProfiles' }):
        blocks = ['state-2', '2'];
        break;
      case intl.formatMessage({ id: 'workflow.unmatchedProfiles' }):
        blocks = ['state-3'];
        break;
      default:
        blocks = [];
    }
    return blocks;
  };
  const handleFilter = (value: any) => {
    setFilterValue(value);

    const blocks = getBlocksFilter(value);
    let query = {
      workflow: workflow._id,
      blocks,
      query: state.search,
    };
    if (profiles.automationId) {
      //@ts-expect-error
      query.inputBlocks = [profiles.automationId];
    }
    getProfiles(query).then(({ data }) => {
      goToPage(1);
      dispatch({
        type: actions.GET_PROFILES,
        payload: {
          ...data,
          automationId: profiles.automationId,
        },
      });
    });
  };

  const handleAutomationFilter = (id: any) => {
    let inputBlocks: any = [];
    let blocks = getBlocksFilter(filterValue);

    if (id) {
      inputBlocks = [id];
    }

    getProfiles({
      workflow: workflow._id,
      inputBlocks,
      blocks,
      query: state.search,
    }).then(({ data }) => {
      dispatch({
        type: actions.GET_PROFILES,
        payload: { ...data, automationId: id },
      });
    });
  };
  const handleGoToPage = (page: any) => {
    const blocks = getBlocksFilter(filterValue);
    let query = {
      workflow: workflow._id,
      page: page - 1,
      blocks,
      query: state.search,
    };
    if (profiles.automationId) {
      //@ts-expect-error
      query.inputBlocks = profiles.automationId;
    }

    getProfiles(query).then(({ data }) => {
      dispatch({
        type: actions.GET_PROFILES,
        payload: {
          ...data,
          automationId: profiles.automationId,
        },
      });
      goToPage(page);
    });
  };
  const handleSearch = (e: any) => {
    dispatch({
      type: actions.SEARCH_PROFILE,
      payload: e.target.value,
    });
    getProfiles({ workflow: workflow._id, query: e.target.value }).then(({ data }) => {
      dispatch({
        type: actions.GET_PROFILES,
        payload: {
          ...data,
        },
      });
    });
    //TODO filter request
  };
  const handleExport = async (type: any) => {
    //{workflow, query, blocks, limited}
    if (!memberViewPermission) {
      return toast.error(<FormattedMessage id={'permissions.noAccess'} />);
    }
    if (!planViewPermission) {
      return toast.error(<FormattedMessage id={'permissions.upgradePlan'} />);
    }

    const blocks = getBlocksFilter(filterValue);
    const allProfiles = profiles.profiles.filter((profile: any) => profile.isChecked);
    const ids = allProfiles.map((profile: any) => profile._id);
    let query: any = {
      workflow: workflow._id,
      page: page - 1,
      blocks,
      query: state.search,
    };
    if (ids.length > 0) {
      query.ids = ids;
    }
    if (profiles.automationId) {
      query.inputBlocks = [profiles.automationId];
    }
    let data, statusCode;

    if (state.profiles.pages.totalProfiles > 20000 && !query.ids) {
      setExcelModal(true);
      return;
    }
    //@ts-expect-error
    handleLoader(true);
    //@ts-expect-error
    setCounter(
      Math.floor(
        ((query.ids && query.ids.length) || (profiles.pages && profiles.pages.totalProfiles)) /
          1500,
      ),
    );
    switch (type) {
      case 'export CSV':
        ({ data, statusCode } = await exportCsv(query));
        if (statusCode === 200) {
          download_file(data, 'profiles_kaspr.csv');
        }
        break;
      case 'export Excel':
        ({ data, statusCode } = await exportExcel(query));
        if (statusCode === 200) {
          download_file(data, 'profiles_kaspr.xlsx');
        }
        break;
    }
    //@ts-expect-error
    handleLoader(false);
  };
  const handleSelectProfile = (e: any, selectedProfile: any) => {
    const allProfiles = profiles.profiles.map((profile: any) => {
      if (profile._id == selectedProfile._id) {
        profile.isChecked = e.target.checked;
      }
      return profile;
    });
    dispatch({
      type: actions.GET_PROFILES,
      payload: { ...state.profiles, profiles: allProfiles },
    });
  };
  const handleSelectAllProfiles = (e: any) => {
    const allProfiles = profiles.profiles.map((profile: any) => {
      profile.isChecked = e.target.checked;
      return profile;
    });
    dispatch({
      type: actions.GET_PROFILES,
      payload: { ...state.profiles, profiles: allProfiles },
    });
  };
  const handleDeleteProfiles = async () => {
    const checkedProfiles = profiles.profiles.filter((lead: any) => lead.isChecked);
    const ids = checkedProfiles.map((profile: any) => profile._id);
    if (!memberExecutePermission) {
      return toast.error(<FormattedMessage id={'permissions.noAccess'} />);
    }
    if (!planExecutePermission) {
      return toast.error(<FormattedMessage id={'permissions.noAccess'} />);
    }

    const { data, statusCode } = await deleteProfiles(ids);
    if (statusCode !== 200) {
      //@ts-expect-error
      toast.error(data.message);
    } else {
      const blocks = getBlocksFilter(filterValue);
      let query = {
        workflow: workflow._id,
        page: page - 1,
        blocks,
      };
      if (profiles.automationId) {
        //@ts-expect-error
        query.inputBlocks = profiles.automationId;
      }

      getProfiles(query).then(({ data }) => {
        dispatch({
          type: actions.GET_PROFILES,
          payload: {
            ...data,
            automationId: profiles.automationId,
          },
        });
        goToPage(page);
      });
    }
    //@ts-expect-error
    setDeleteProfileModal(false);
  };

  const selectedProfiles = () => {
    const count =
      (profiles.profiles && profiles.profiles.filter((lead: any) => lead.isChecked).length) || 0;
    return count == 0 ? false : count;
  };

  const DeleteLeadsButton = () => {
    return (
      <div
        className={'delete-icon-btn'}
        style={{
          marginLeft: '5px',
          width: 'auto',
          padding: '0 10px',
          /*
          color: planPermission ? "#ccc" : "#555",
*/
        }}
        //@ts-expect-error
        onClick={setDeleteProfileModal}
      >
        <img src="/assets/images/icon-delete.svg" alt="" />{' '}
        <span style={{ margin: '0 5px', fontWeight: '500' }}>
          <FormattedMessage id={'delete'} />
        </span>
      </div>
    );
  };
  return (
    <div id="profile_tab" className="tab-pane fade active show">
      {loader as ReactNode}
      <div className="table-wrap profile_detail_block">
        {profiles.profiles.length > 0 && (
          <div className="head_block ">
            {selectedProfiles() == 0 ? (
              <div className="left_block">
                <div className="title-box">
                  <p>
                    <FormattedMessage id={'workflow.profiles'} />
                  </p>
                </div>
                {(workflow.type == 'linkedin' || workflow.type == 'enrichment') && (
                  <div className="sorting-block">
                    <label>
                      {' '}
                      <FormattedMessage id={'workflow.filterBy'} />
                    </label>

                    <div className="cstm-select date-sorting" style={{ marginRight: 20 }}>
                      <SelectPicker
                        doNotCapitalize
                        values={
                          (workflow.type == 'linkedin' && [
                            intl.formatMessage({ id: 'workflow.allProfiles' }),
                            intl.formatMessage({
                              id: 'workflow.invitationAccepted',
                            }),
                            intl.formatMessage({
                              id: 'workflow.replyMessages',
                            }),
                          ]) || [
                            intl.formatMessage({ id: 'workflow.allProfiles' }),
                            intl.formatMessage({
                              id: 'workflow.matchedProfiles',
                            }),
                            intl.formatMessage({
                              id: 'workflow.unmatchedProfiles',
                            }),
                          ]
                        }
                        onChange={handleFilter}
                        labelsStyle={{ left: 0, width: 'max-content' }}
                        selectedLabelStyle={{ width: 'max-content' }}
                      />
                    </div>
                  </div>
                )}

                {flowInputs.length > 0 && (
                  <div className="sorting-block" style={{ display: 'flex', alignItems: 'center' }}>
                    <label>
                      {' '}
                      <FormattedMessage id={'workflow.searches'} />:
                    </label>

                    <div className="cstm-select date-sorting" style={{ marginLeft: 10 }}>
                      <SelectPicker
                        doNotCapitalize
                        values={flowInputs}
                        onChange={handleAutomationFilter}
                        labelsStyle={{ left: 0, width: 'max-content' }}
                        selectedLabelStyle={{ width: 'max-content' }}
                        withId
                        id={profiles.automationId}
                      />
                    </div>
                  </div>
                )}

                <div className={'page-header'}>
                  <div className="search-ui wk-profile-search" style={{ marginLeft: 10 }}>
                    <input
                      type="text"
                      placeholder={intl.formatMessage({
                        id: 'workflow.searchInTheList',
                      })}
                      name={'search'}
                      value={state.search}
                      style={{ width: '200px' }}
                      autoComplete={'off'}
                      onChange={handleSearch}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="left_block" style={{ alignItems: 'center' }}>
                {' '}
                <span className="leads-select-tag">
                  {selectedProfiles() || 0} <FormattedMessage id={'workflow.profilesSelected'} />
                </span>
                <DeleteLeadsButton />
              </div>
            )}

            <div className="right_block">
              <Pagination
                pages={profiles.pages.pages}
                currentPage={page}
                goToPage={handleGoToPage}
                top
              />

              <div className="export-dropdown-ui">
                <div className={'export-btn'}>
                  <SelectPicker
                    values={['export CSV', 'export Excel']}
                    onChange={handleExport}
                    labelsStyle={{ left: 0, width: '100%' }}
                    selectedLabelStyle={{ width: '155px' }}
                    staticLabel={selectedProfiles() ? 'export selected' : 'export all'}
                    lock={!(memberViewPermission && planViewPermission)}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {profiles.profiles.length > 0 ? (
          <div className="table-content table-width-equal custom-wk-profiles">
            <table>
              <thead>
                <tr>
                  <th style={{ width: 100 }}>
                    <div className="checkbox-wrap">
                      <label>
                        <input type="checkbox" onClick={handleSelectAllProfiles} />

                        <span className="checkbox-icon"></span>
                      </label>
                    </div>
                  </th>

                  <th>
                    <FormattedMessage id={'workflow.linkedinProfiles'} />
                  </th>

                  <th>
                    <FormattedMessage id={'workflow.fullName'} />
                  </th>

                  <th>
                    <FormattedMessage id={'workflow.designation'} />
                  </th>

                  <th>
                    <FormattedMessage id={'workflow.company'} />
                  </th>

                  <th>
                    <FormattedMessage id={'workflow.location'} />
                  </th>
                  {workflow.type !== 'linkedin' && (
                    <>
                      <th>
                        <FormattedMessage id={'leads.properties.currentPersonalEmail'} />
                      </th>

                      <th>
                        <FormattedMessage id={'workflow.professionalEmail'} />
                      </th>

                      <th>
                        <FormattedMessage id={'workflow.phone'} />
                      </th>
                    </>
                  )}
                </tr>
              </thead>

              <tbody>
                {profiles.profiles.map((profile: any, i: any) => (
                  <ProfileItem
                    profile={profile}
                    key={i}
                    showLinkedinActionStatus={workflow.type === 'linkedin'}
                    onCheckBoxChange={handleSelectProfile}
                    intl={intl}
                  />
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <DataNotFound
            image={'/assets/images/img_empty_no_profiles.png'}
            imgWith={300}
            style={{ margin: '20px 0 ' }}
            elements={
              <div
                style={{ marginTop: '-10px', paddingBottom: 20 }}
                className="join-organization-inner join-organization-page"
              >
                <h5>
                  <FormattedMessage id={'workflow.noResultsFound'} />
                </h5>

                <p className="desc">
                  <FormattedMessage id={'workflow.theListOfAll'} />
                </p>
              </div>
            }
          />
        )}
      </div>
      {/* <Pagination
        pages={profiles.pages.pages}
        currentPage={page}
        goToPage={handleGoToPage}
        bottom
      />*/}
      {excelModal && (
        <Modal
          show={true}
          onHide={() => setExcelModal(false)}
          className="fade add-member-wrapper  addmember-modal"
        >
          <Modal.Header className="modal-header border-0" closeButton>
            <div className="modal-inner-header">
              <div className="profile-info">
                <h5 className="heading mb-8">
                  <FormattedMessage id={'workflow.excelExport'} />
                </h5>
              </div>
            </div>
          </Modal.Header>

          <div className="modal-body p-0">
            <div className="add-member-fields">
              <form>
                <div className="modal-heading">
                  <span>
                    {' '}
                    You want to generate an Excel file with {state.profiles.pages.totalLeads}{' '}
                    workflow profiles.{' '}
                  </span>

                  <br />

                  <br />

                  <span>
                    We are sorry to inform you that you cannot export more than {EXCEL_LIMIT}{' '}
                    profiles at once using excel.
                  </span>

                  <span>
                    However, you can export all your data in separate files of up to {EXCEL_LIMIT}{' '}
                    profiles each.
                  </span>
                </div>

                <div className="form-wrap">
                  <div className="form-wrap">
                    {chunks.map((chunk, index) => {
                      return (
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <input type="Number" value={chunk.start} disabled={true} />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <input type="Number" value={chunk.end} disabled={true} />
                            </div>
                          </div>

                          <a
                            href="#"
                            className="col-md-4"
                            style={{ paddingTop: '12px', fontSize: '14px' }}
                            onClick={async () => {
                              //@ts-expect-error
                              handleLoader(true);
                              //@ts-expect-error
                              setCounter(null);
                              let { data, statusCode } = await exportExcel({
                                skip: chunk.start - 1,
                                limit: EXCEL_LIMIT,
                                workflow: workflow._id,
                                blocks: getBlocksFilter(filterValue),
                                query: state.search,
                                inputBlocks: profiles.automationId && [profiles.automationId],
                              });
                              if (statusCode === 200) download_file(data, 'profiles_kaspr.xlsx');
                              //@ts-expect-error
                              handleLoader(false);
                            }}
                          >
                            Download file_{index + 1}
                          </a>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </form>
            </div>
          </div>
          {error && (
            <div
              className="text-center  error  kaspr-form-text-error"
              style={{ top: 10, width: '90%' }}
            >
              {error + ' '}
            </div>
          )}

          <div className="modal-footer border-0">
            <a href="#" className="btn4 mr14 btn48" onClick={() => setExcelModal(false)}>
              Cancel
            </a>
          </div>
        </Modal>
      )}
      {deleteProfileModal && (
        <ModalMessage
          toggle={deleteProfileModal}
          setToggle={() => {
            //@ts-expect-error
            setDeleteProfileModal(false);
          }}
          title={'Delete ' + selectedProfiles() + ' profile(s)'}
          textElement={
            <>
              <strong>
                <FormattedMessage id={'workflow.pleaseConfirmRemovalOf'} /> {selectedProfiles()}{' '}
                <FormattedMessage id={'workflow.profiles_'} />
              </strong>
            </>
          }
          action={'Confirm'}
          handleAction={handleDeleteProfiles}
        />
      )}

      <ToastContainer position="bottom-right" style={{ top: 100 }} />
    </div>
  );
}

function ProfileItem({ profile, showLinkedinActionStatus, onCheckBoxChange, intl }: any) {
  return (
    <tr>
      <td>
        <div className="checkbox-wrap">
          <label>
            <input
              type="checkbox"
              onClick={(e) => onCheckBoxChange(e, profile)}
              checked={profile.isChecked}
            />

            <span className="checkbox-icon"></span>
          </label>
        </div>
      </td>

      <td>
        <a
          href={'https://www.linkedin.com/in/' + profile.linkedin.id}
          target="_blank"
          className="ic_link"
          style={{ marginRight: '15px' }}
          rel="noopener noreferrer nofollow"
        >
          <FormattedMessage id={'workflow.viewProfile'} />
        </a>
      </td>

      <td>{profile.firstName + ' ' + profile.lastName}</td>

      <td>
        {profile.job.length > 25 ? (
          <CustomToolTip element={profile.job}>
            <div> {profile.job.substring(0, 25) + '...'} </div>
          </CustomToolTip>
        ) : (
          profile.job
        )}
      </td>

      <td>
        {profile.company.length > 25 ? (
          <CustomToolTip element={profile.company}>
            <div> {profile.company.substring(0, 25) + '...'} </div>
          </CustomToolTip>
        ) : (
          profile.company
        )}
      </td>

      <td>
        {profile.location.length > 25 ? (
          <CustomToolTip element={profile.location}>
            <div> {profile.location.substring(0, 25) + '...'} </div>
          </CustomToolTip>
        ) : (
          profile.location
        )}
      </td>
      {showLinkedinActionStatus && (
        <>
          <td>
            {profile.isContact != undefined
              ? profile.isContact
                ? intl.formatMessage({ id: 'workflow.accepted' })
                : intl.formatMessage({ id: 'workflow.notAccepted' })
              : intl.formatMessage({ id: 'pending' })}
          </td>

          <td>
            {profile.hasResponded != undefined
              ? profile.hasResponded
                ? intl.formatMessage({ id: 'workflow.answered' })
                : intl.formatMessage({ id: 'workflow.notAnswered' })
              : intl.formatMessage({ id: 'pending' })}
          </td>
        </>
      )}
      {!showLinkedinActionStatus && (
        <>
          <td>{profile.currentPersonalEmail || '---'}</td>

          <td>{profile.currentProEmail || '---'}</td>
          {profile.phones ? (
            <td>
              {profile.phones[0] ? profile.phones[0] : '---'}{' '}
              {profile.phones.length > 1 && (
                <CustomToolTip element={profile.phones}>
                  <p className="contact-more tip">{profile.phones.length - 1}</p>
                </CustomToolTip>
              )}
            </td>
          ) : (
            <td>---</td>
          )}
        </>
      )}
    </tr>
  );
}
