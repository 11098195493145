import React from 'react';
import { FormattedMessage } from 'react-intl';
import './RenderButton.css';
import { capitalizeFirstLetter } from '../../../../services/utils/tools';
import { integrationUpdatedNames } from '../../../Settings/Integration/constants';

const RenderButton = ({ type, startOAuth, inModal, onHide }: any) => {
  return (
    <button onClick={startOAuth} className={inModal ? 'btn1 ripple-btn' : 'connect-btn'}>
      {inModal ? (
        <div onClick={() => onHide()} className="responsive-text">
          <FormattedMessage
            id="settings.connectTo"
            values={{
              //@ts-expect-error
              value: capitalizeFirstLetter(integrationUpdatedNames[type]),
            }}
          />
        </div>
      ) : (
        <FormattedMessage id={'settings.profile.connect'} />
      )}
    </button>
  );
};

export default RenderButton;
