import React, { useEffect, useState } from 'react';

import CurrencyDisplay from '../../../../../../components/CurrencyDisplay/CurrencyDisplay';
import { getPlans } from '../../../../../../services/api/credits.api';
import './FixPriceInfoFreePlan.css';

export default function FixPriceInfoFreePlan({
  selectedPlan,
  addOnsPrice,
  organizationCredits,
  user,
  planType,
  nbLicenses,
}: any) {
  const [plan, setPlan] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getPlans().then(({ data, statusCode }) => {
      if (statusCode === 200) {
        //@ts-expect-error
        const plans = data.plans.filter((plan: any) => plan.planId === selectedPlan.planId);
        setPlan(plans[0]);
      }
      setLoading(false);
    });
  }, [selectedPlan.planId]);

  const formatPrice = (price: any) => {
    const formattedPrice = price.toFixed(2);
    return formattedPrice.endsWith('.00') ? formattedPrice.slice(0, -3) : formattedPrice;
  };

  if (loading) {
    return <div className="spinner"></div>;
  }

  if (selectedPlan.yearly && planType === 'yearly') {
    return (
      <div>
        <CurrencyDisplay
          user={user}
          organizationCredits={organizationCredits}
          amount={formatPrice(
            selectedPlan.subscriptionTotal / (selectedPlan.remainingMonths || 12) / 100,
          )}
        />
      </div>
    );
  }

  if (!selectedPlan.yearly && planType === 'monthly') {
    return (
      <div>
        <CurrencyDisplay
          user={user}
          organizationCredits={organizationCredits}
          amount={formatPrice(selectedPlan.subscriptionTotal / 100)}
        />
      </div>
    );
  }

  let toDeduct;
  if (selectedPlan.yearly && planType === 'monthly') {
    if (organizationCredits.plan.numberOfMonths === 1) {
      toDeduct = organizationCredits.plan.numberOfSeats * organizationCredits.plan.pricePerSeat;
    }
    //@ts-expect-error
    const price = plan.pricePerSeat * nbLicenses - (toDeduct || 0);

    return (
      <div>
        <CurrencyDisplay
          user={user}
          organizationCredits={organizationCredits}
          amount={formatPrice(price)}
        />
      </div>
    );
  }

  if (!selectedPlan.yearly && planType === 'yearly') {
    return (
      <div>
        <CurrencyDisplay
          user={user}
          organizationCredits={organizationCredits}
          //@ts-expect-error
          amount={plan.yearlyPricePerSeat * nbLicenses}
        />
      </div>
    );
  }

  return <div>NaN</div>;
}
