import React, { useContext } from 'react';
import './ProgressSteps.css';
import { FormattedMessage } from 'react-intl';
import { BulkEnrichmentContext } from '../../store/bulkEnrichment.store';

const ProgressSteps = () => {
  const stepCompleted = <i className="fas fa-check-circle"></i>;

  const stepCurrent = <i className="far fa-check-circle"></i>;

  const stepPending = <i className="fas fa-check-circle" style={{ color: '#D5D7E4' }}></i>;
  //@ts-expect-error
  const { state } = useContext(BulkEnrichmentContext);
  return (
    <div className={'progress-steps-container'}>
      <div className={'progress-steps-container-main'}>
        <div className={'steps'}>
          {state.currentStep <= 1 ? stepCurrent : stepCompleted}

          <h3 style={{ marginRight: '40px' }}>
            <FormattedMessage id={'bulkEnrichment.settings.settings'} />
          </h3>
          {state.currentStep <= 1 ? stepPending : stepCurrent}

          <h3 className={state.currentStep <= 1 ? 'pending' : ''}>
            <FormattedMessage id={'bulkEnrichment.uploadFiles.enrichmentOptions'} />
          </h3>
        </div>

        <progress className={'progress-bar'} id="steps" max="2" value={state.currentStep} />
      </div>

      <span>{state.currentStep}/2</span>
    </div>
  );
};

export default ProgressSteps;
