import React, { useContext, useEffect, useState, useRef } from 'react';
import { AuthContext } from '../Store/auth.store';
import './Signup.css';
import { Field, PhoneField } from '../common/Field/Field';

import CheckBox from '../common/CheckBox/CheckBox';
import actions from '../Store/auth.actions';
import { preSignUp, postSignUp } from '../../../services/api/auth.api';
import { NavLink, useParams } from 'react-router-dom';
import { saveAsCookie } from '../../../services/utils';
import { BASE_URL, IS_PROD } from '../../../constants';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import cookie from 'react-cookies';
import queryString from 'query-string';
import { getSbUtm } from '../../../services/utils/tools';
import { isMobile, isMobileSafari } from 'react-device-detect';
import useUserSteps from '../../../services/utils/useUserSteps';
import { checkEmail, googleSSO } from '../../../services/api/auth.api';
import { useGoogleLogin } from '@react-oauth/google';

function SignupForm({ redux }: any) {
  if (redux && redux.profile) {
    //@ts-expect-error
    const { isEmailValidationDone, isPostSignUpDone } = useUserSteps(redux);

    if (isEmailValidationDone && !isPostSignUpDone) {
      return (
        <section id="post-signup-form">
          <PostSignUp />
        </section>
      );
    }
  }

  return (
    <section id="signup-form">
      <SignUpL />
      <SignUpR />
    </section>
  );
}

function SignUpL({}) {
  //@ts-expect-error
  const { state, dispatch } = useContext(AuthContext);
  const [error, setError] = useState(null);
  const [inputValidationId, setInputValidation] = useState(null);
  const [loading, setLoading] = useState(null);
  const [loadingSSO, setLoadingSSO] = useState(null);
  const [utms, setUtms] = useState(null);
  const { email: encodedEmail } = useParams();
  const intl = useIntl();
  const emailRef = useRef(null);
  const [hasSubmittedPreSignUp, setHasSubmittedPreSignUp] = useState(false);
  const [hasSubmittedGoogleSSO, setHasSubmittedGoogleSSO] = useState(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState('');
  const [isTermsChecked, setIsTermsChecked] = useState(false);

  useEffect(() => {
    let email = '';
    try {
      email = atob(encodedEmail);
      let url = cookie.load('__gtm_campaign_url', {
        path: '/',
        domain: BASE_URL,
      });
      if (url) {
        //@ts-expect-error
        url = url.split('?');
        //@ts-expect-error
        url = url[1] && url[1].split('&');
        //@ts-expect-error
        let properties = url.map((property: any) => {
          property = property.split('=');
          let utmProperty = { name: property[0], value: property[1] };
          return utmProperty;
        });
        setUtms(properties);
      }
    } catch (err) {}
    dispatch({ type: actions.SIGNUP, payload: { email } });

    setTimeout(() => {
      if (emailRef.current) {
        emailRef.current.focus();
      }
    }, 100);
  }, []);

  //@ts-expect-error
  const handleCheckEmail = async (email) => {
    const { data, statusCode } = await checkEmail({
      email: email.trim(),
      isMobile: isMobile || isMobileSafari,
    });
    if (statusCode === 200) return '';
    if (statusCode >= 400) {
      //@ts-expect-error
      return data.messageTranslated || data.message;
    }
    dispatch({
      type: actions.SIGNUP,
      payload: { email: email },
    });
  };

  const handleCheckTerms = () => {
    setHasSubmittedGoogleSSO(true);

    const terms = document.getElementById('terms');
    //@ts-expect-error
    if (!terms.checked) {
      setIsTermsChecked(false);
      return false;
    } else {
      setIsTermsChecked(true);
      return true;
    }
  };

  const prepareSignupFormData = async (email: any, credentialResponse: any) => {
    const terms = document.getElementById('terms');
    const newsletter = document.getElementById('newsletter');

    // Prepare signup form data
    const signupForm = {
      ...state.signupForm,
      email: email ? email.trim() : state.signupForm.email,
      //@ts-expect-error
      acceptedTerms: terms.checked,
      //@ts-expect-error
      receiveNewsletter: !newsletter.checked,
    };

    if (credentialResponse) {
      signupForm.token = credentialResponse.code;
    }

    // Load partner cookies
    const partnerStackCookieValue = cookie.load('ps_partner_key', {
      path: '/',
      domain: BASE_URL,
    });
    const partnerStackCookieXid = cookie.load('ps_xid', {
      path: '/',
      domain: BASE_URL,
    });

    // Add UTM parameters if available
    const utms = getSbUtm();
    if (utms) signupForm.utm = utms;

    // Add partner cookies if they exist
    if (partnerStackCookieValue) signupForm.partner_key = partnerStackCookieValue;
    if (partnerStackCookieXid) signupForm.xid = partnerStackCookieXid;

    // Extract URL query parameters
    const queryParams = queryString.parse(window.location.search);
    if (queryParams.code) signupForm.code = queryParams.code;
    if (queryParams.rewardUnlimitedB2bWeek) signupForm.rewardUnlimitedB2bWeek = true;

    return signupForm;
  };

  const handleSignupSuccess = async (credentialResponse: any) => {
    try {
      setLoadingSSO(true);
      const signupForm = await prepareSignupFormData(null, credentialResponse);

      const { data, statusCode } = await googleSSO(signupForm);

      if (statusCode === 200) {
        //@ts-expect-error
        saveAsCookie(data.jwt);
        //@ts-expect-error
        window.location = '/joinWorkspace';
      } else if (statusCode >= 400) {
        //@ts-expect-error
        setError(data.messageTranslated || data.message);
      } else {
        console.error('Error during Google SSO signup: status', statusCode);
      }
    } catch (error) {
      console.error('Error during Google SSO connection:', error);
    } finally {
      setLoadingSSO(false);
    }
  };

  //@ts-expect-error
  const handleOnSubmitPreSignUp = async (e) => {
    e.preventDefault();

    setInputValidation('');
    setHasSubmittedPreSignUp(true);

    const email = document.getElementById('email');

    try {
      setLoading(true);

      // Check email validity
      //@ts-expect-error
      const emailError = await handleCheckEmail(email.value);
      if (emailError) {
        setIsEmailInvalid(emailError);
        return;
      }

      setIsEmailInvalid('');
      //@ts-expect-error
      const signupForm = await prepareSignupFormData(email.value);

      const { statusCode, data } = await preSignUp(signupForm);

      if (statusCode === 200) {
        if (IS_PROD) {
          window.googleTagManager('pre-registration');
        }

        //@ts-expect-error
        saveAsCookie(data.jwt);

        window.location.href = '/emailvalidation';
      } else {
        //@ts-expect-error
        setError(data.messageTranslated || data.message);
      }
    } catch (err) {
      setError('An error occurred. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  //@ts-expect-error
  const handleGoogleLoginClick = (e) => {
    e.preventDefault();
    if (handleCheckTerms()) {
      googleLogin(); // Google login if terms are accepted
    }
  };

  //@ts-expect-error
  const handleSignupFailure = (error) => {
    console.error('Error while connectiong:', error);
  };

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    //@ts-expect-error
    onSuccess: (credentialResponse) => handleSignupSuccess(credentialResponse),
    onError: handleSignupFailure,
  });

  return (
    <div className="signup-left">
      <form className="form">
        <div className="header-wrapper">
          <h1>
            <FormattedMessage id="preSignUp.createYourFreeAccount" />
          </h1>
          <h2>
            <FormattedMessage id="preSignUp.getContactDetails" />
          </h2>
        </div>

        <div className="form-blocks">
          <Field
            //@ts-expect-error
            label={intl.formatMessage({ id: 'preSignUp.workEmail' })}
            //@ts-expect-error
            onChange={(email) => {
              dispatch({ type: actions.SIGNUP, payload: { email } });
              setHasSubmittedPreSignUp(false);
            }}
            value={state.signupForm.email}
            type={'email'}
            id={'email'}
            inputValidationId={inputValidationId}
            ref={emailRef}
            autoFocus
          />
          {hasSubmittedPreSignUp && isEmailInvalid.length > 0 && (
            <p className="checkemail-error">{isEmailInvalid}</p>
          )}
          <div className="checkbox-listing" style={{ marginBottom: 20 }}>
            <CheckBox
              element={
                <span className="checkbox-text">
                  <FormattedMessage id={'auth.iAgreeTo_'} />{' '}
                  <a
                    target="_blank"
                    className="terms-and-conditions"
                    href="https://kaspr.io/terms/"
                  >
                    <FormattedMessage id={'auth.termsOfService'} />
                  </a>{' '}
                  <FormattedMessage id={'auth.and'} />{' '}
                  <a target="_blank" className="policy" href="https://kaspr.io/privacy-policy/">
                    <FormattedMessage id={'auth.privacyPolicy'} />
                  </a>
                </span>
              }
              onChange={(acceptedTerms: any) => {
                dispatch({ type: actions.SIGNUP, payload: { acceptedTerms } });
                setIsTermsChecked((prev) => !prev);
                setHasSubmittedPreSignUp(false);
                setHasSubmittedGoogleSSO(false);
              }}
              id={'terms'}
              inputValidationId={inputValidationId}
              required
            />
            {(hasSubmittedPreSignUp || hasSubmittedGoogleSSO) && !isTermsChecked && (
              <p className="terms-error">
                <FormattedMessage id="preSignUp.pleaseAcceptTheTerms" />
              </p>
            )}
            <CheckBox
              element={
                <span className="checkbox-text">
                  <FormattedMessage id={'preSignUp.newsletterText'} />
                </span>
              }
              onChange={(receiveNewsletter: any) => {
                dispatch({
                  type: actions.SIGNUP,
                  payload: { receiveNewsletter: !receiveNewsletter },
                });
                setHasSubmittedPreSignUp(false);
              }}
              id={'newsletter'}
            />
          </div>

          {/* GET STARTED BTN */}
          <div className="btn-grp text-center">
            <button
              className="btn btn-blue btn-block h-52"
              style={{ width: '100%' }}
              onClick={(e) => (loading ? false : handleOnSubmitPreSignUp(e))}
            >
              {loading ? (
                <div>
                  <img src="/assets/images/loading.gif" alt="" style={{ width: 30 }} />{' '}
                </div>
              ) : (
                <FormattedMessage id={'preSignUp.getStarted'} />
              )}
            </button>
          </div>

          <div className="separator-ctn">
            <div className="hr"></div>
            <p style={{ textTransform: 'lowercase', color: '#797F99' }}>
              <FormattedMessage id="organization.or" />
            </p>
            <div className="hr"></div>
          </div>

          {/* GOOGLE SSO SIGNUP */}
          <button
            onClick={(e) => (loadingSSO ? false : handleGoogleLoginClick(e))}
            className="sso-btn"
          >
            {loadingSSO ? (
              <div className="sso-centered">
                <img
                  src="/assets/images/loading.gif"
                  alt=""
                  style={{ width: 23, height: 23, margin: 'auto' }}
                />{' '}
              </div>
            ) : (
              <span className="sso-centered">
                <img src="/assets/images/google-icon.svg" alt="google icon" />
                <FormattedMessage id="preSignUp.signupWithGoogle" />
              </span>
            )}
          </button>

          {error && (
            <div
              className="btn-grp text-center  error  kaspr-form-text-error"
              style={{
                top: '0.75rem',
                width: '100%',
              }}
            >
              {error + ' '}
            </div>
          )}

          <div className="other-info text-center">
            <p className="info-btn">
              <FormattedMessage id={'haveAnAccount'} />{' '}
              <NavLink to="/signin" className="text-btn">
                <FormattedMessage id={'auth.logIn'} />
              </NavLink>
            </p>
          </div>
        </div>
        {utms &&
          utms.length > 0 &&
          utms.map((utm: any, i: any) =>
            utm.name && utm.value ? (
              <input key={i} type="hidden" name={utm.name} value={utm.value} />
            ) : null,
          )}
      </form>
    </div>
  );
}

//@ts-expect-error
SignUpL = injectIntl(SignUpL);

function SignUpR() {
  const integrations = [
    'salesforce',
    'hubspot',
    'pipedrive',
    'brevo',
    'lemlist',
    'ringover',
    'aircall',
    'zapier',
  ];
  return (
    <div className="signup-right">
      <div className="signup-right-top">
        <img src="/assets/images/guillemet.svg" alt="Guillemet icon" />
        <h2>
          <FormattedMessage id="preSignUp.quote" />
        </h2>
        <div className="signup-right-user">
          <img
            src="/assets/images/lloyd-profile.svg"
            alt="Guillemet icon"
            style={{ backgroundColor: 'transparent' }}
          />
          <div className="info">
            <p>Lloyd Gordon</p>
            <p>
              <FormattedMessage id="preSignUp.LloydRole" />
            </p>
          </div>
        </div>
      </div>

      <div className="signup-right-bottom">
        <div className="heading-wrapper">
          <img src="/assets/images/vector-signup.svg" width={'100%'} alt="vector" />
          <h3>
            <FormattedMessage id="preSignUp.connectKasprWith" />
          </h3>
          <img
            src="/assets/images/vector-signup.svg"
            width={'100%'}
            style={{ transform: 'rotate(180deg)' }}
            alt="vector"
          />
        </div>
        <div className="integrations-grid">
          {integrations.slice(0, 6).map((intg, index) => (
            <div className="intg-wrapper" key={index}>
              <img src={`/assets/images/${intg}-intg.svg`} alt={intg} />
            </div>
          ))}
        </div>

        <div className="integrations-row">
          {integrations.slice(6).map((intg, index) => (
            <div key={index + 6} className="intg-wrapper">
              <img src={`/assets/images/${intg}-intg.svg`} alt={intg} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function PostSignUp() {
  //@ts-expect-error
  const { state, dispatch } = useContext(AuthContext);
  const [error, setError] = useState(null);
  const [inputValidationId, setInputValidation] = useState(null);
  const [loading, setLoading] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const intl = useIntl();

  const passwordRef = useRef(null);
  const firstNameRef = useRef(null);

  // Password validation states
  const password = state.signupForm.password || '';
  const hasLowerCase = /[a-z]/.test(password);
  const hasUpperCase = /[A-Z]/.test(password);
  const hasNumber = /[0-9]/.test(password);
  const hasMinimumLength = password.length >= 8;

  useEffect(() => {
    const { firstName, lastName } = state.signupForm;
    const isFormValid =
      firstName && lastName && hasLowerCase && hasUpperCase && hasNumber && hasMinimumLength;
    setIsButtonDisabled(!isFormValid);
  }, [state.signupForm, hasLowerCase, hasUpperCase, hasNumber, hasMinimumLength]);

  useEffect(() => {
    if (firstNameRef.current) {
      firstNameRef.current.focus();
    }
  }, []);

  const handleOnSubmitPostSignUp = async (e: any) => {
    e.preventDefault();
    setInputValidation('');
    if (!hasLowerCase || !hasUpperCase || !hasNumber || !hasMinimumLength) {
      return setInputValidation('password');
    }
    try {
      setLoading(true);
      const { firstName, lastName, password } = state.signupForm;
      const { statusCode, data } = await postSignUp(firstName, lastName, password);
      if (statusCode === 200) {
        //@ts-expect-error
        saveAsCookie(data.jwt);
        window.location.href = '/joinWorkspace';
      } else {
        //@ts-expect-error
        setError(data.messageTranslated || data.message);
      }
      setLoading(false);
    } catch (err) {}
  };

  return (
    <div className="post-signup-container">
      <form onSubmit={(e) => (loading ? false : handleOnSubmitPostSignUp(e))} className="form">
        <div className="header-wrapper">
          <h1>
            <FormattedMessage id="postSignUp.letsFinishAccountSetup" />
          </h1>
          <h2>
            <FormattedMessage id="postSignUp.justAFewStepsToFindLead" />
          </h2>
        </div>
        <div className="form-blocks">
          <div style={{ display: 'flex' }}>
            <Field
              //@ts-expect-error
              className="text-capitalize"
              label={intl.formatMessage({ id: 'info.firstName' })}
              //@ts-expect-error
              onChange={(firstName) => dispatch({ type: actions.SIGNUP, payload: { firstName } })}
              value={state.signupForm.firstName}
              id={'firstName'}
              inputValidationId={inputValidationId}
              setInputValidation={setInputValidation}
              containerStyle={{ width: '100%' }}
              ref={firstNameRef}
              autoFocus
            />
            <Field
              //@ts-expect-error
              className="text-capitalize"
              label={intl.formatMessage({ id: 'info.lastName' })}
              //@ts-expect-error
              onChange={(lastName) => dispatch({ type: actions.SIGNUP, payload: { lastName } })}
              value={state.signupForm.lastName}
              id={'lastName'}
              inputValidationId={inputValidationId}
              setInputValidation={setInputValidation}
              containerStyle={{ width: '100%' }}
            />
          </div>

          <Field
            //@ts-expect-error
            label={intl.formatMessage({ id: 'info.password' })}
            //@ts-expect-error
            onChange={(password) => {
              dispatch({ type: actions.SIGNUP, payload: { password } });
            }}
            type={'password'}
            passwordRef={passwordRef}
            id={'password'}
            inputValidationId={inputValidationId}
            setInputValidation={setInputValidation}
            hideCheckIcon
          />

          {/* Password conditions grid */}
          <div className="password-requirements">
            <div className="pw-ctn">
              {hasLowerCase ? (
                <span className="checked-icon">
                  <img src="/assets/images/ic_check.svg" alt="" />
                </span>
              ) : (
                <span className="checked-icon">
                  <img src="/assets/images/ic_grayed_check.svg" alt="" />
                </span>
              )}
              <div className={hasLowerCase ? 'condition-met' : 'condition-not-met'}>
                <FormattedMessage id="postSignUp.oneLowercaseCharacter" />
              </div>
            </div>
            <div className="pw-ctn">
              {hasNumber ? (
                <span className="checked-icon">
                  <img src="/assets/images/ic_check.svg" alt="" />
                </span>
              ) : (
                <span className="checked-icon">
                  <img src="/assets/images/ic_grayed_check.svg" alt="" />
                </span>
              )}
              <div className={hasNumber ? 'condition-met' : 'condition-not-met'}>
                <FormattedMessage id="postSignUp.oneNumber" />
              </div>
            </div>
            <div className="pw-ctn">
              {hasUpperCase ? (
                <span className="checked-icon">
                  <img src="/assets/images/ic_check.svg" alt="" />
                </span>
              ) : (
                <span className="checked-icon">
                  <img src="/assets/images/ic_grayed_check.svg" alt="" />
                </span>
              )}
              <div className={hasUpperCase ? 'condition-met' : 'condition-not-met'}>
                <FormattedMessage id="postSignUp.oneUppercaseCharacter" />
              </div>
            </div>
            <div className="pw-ctn">
              {hasMinimumLength ? (
                <span className="checked-icon">
                  <img src="/assets/images/ic_check.svg" alt="" />
                </span>
              ) : (
                <span className="checked-icon">
                  <img src="/assets/images/ic_grayed_check.svg" alt="" />
                </span>
              )}
              <p className={hasMinimumLength ? 'condition-met' : 'condition-not-met'}>
                <FormattedMessage id="postSignUp.XCharactersMinimum" values={{ value: '8' }} />
              </p>
            </div>
          </div>

          {error && (
            <div
              className="btn-grp text-center floating-effect kaspr-form-text-error"
              style={{ width: '100%' }}
            >
              {error + ' '}
            </div>
          )}

          <div className="btn-grp text-center">
            <button
              className={`btn btn-block ${isButtonDisabled ? 'disabled-btn' : 'btn-blue '}`}
              style={{
                width: '100%',
                color: 'white',
              }}
              type={'submit'}
              disabled={isButtonDisabled || loading}
            >
              {loading ? (
                <div>
                  <img src="/assets/images/loading.gif" alt="" style={{ width: 30 }} />{' '}
                </div>
              ) : (
                <FormattedMessage id={'auth.next'} />
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default SignupForm;
