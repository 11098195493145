import React from 'react';
import './SummaryAddon.css';
import { CREDIT_COLORS } from '../../../../../../../constants';
import { FormattedMessage, useIntl } from 'react-intl';

import CurrencyDisplay from '../../../../../../../components/CurrencyDisplay/CurrencyDisplay';

const SummaryAddon = ({
  type,
  nbAdditionalPackage,
  id,
  labelId,
  creditsToAdd,
  subscriptionType,
  user,
  organizationCredits,
}: any) => {
  const intl = useIntl();
  return (
    <div id="summary-addon">
      <div className="addon-title">
        <div className="credit-title-free">
          <img src={CREDIT_COLORS[id].icon} alt={intl.formatMessage({ id: labelId })} />

          <div className="add-on">
            <p>
              <FormattedMessage id="billing.addOn" />
            </p>

            <h5>
              <FormattedMessage id={labelId} />
            </h5>
          </div>
        </div>

        <p className="addon-price">
          <CurrencyDisplay
            user={user}
            organizationCredits={organizationCredits}
            amount={intl.formatNumber(
              subscriptionType === 'yearly'
                ? Math.round(creditsToAdd[id].price * 100) / 100
                : Math.round(creditsToAdd[id].price * 100) / 100,
              {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              },
            )}
          />{' '}
        </p>
      </div>

      <div className="additional-package">
        <p>
          <FormattedMessage id="billing.additionalPackage" />
        </p>

        <div>
          <span className="number">+ {creditsToAdd[id].numberOfCredits}</span>
        </div>
      </div>
    </div>
  );
};

export default SummaryAddon;
