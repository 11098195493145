import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Dropdown from '../../common/Dropdown/Dropdown';

import DropdownLeadList from '../../common/DropdownLeadList/DropdownLeadList';
import './MappingField.css';

import ProgressSteps from '../../common/ProgressSteps/ProgressSteps';
import { toast } from 'react-toastify';
import { BulkEnrichmentContext } from '../../store/bulkEnrichment.store';
import actions from '../../store/bulkEnrichment.actions';

import InfoToolTip from '../../../../components/InfoToolTip/InfoToolTip';

function MappingField() {
  const intl = useIntl();
  //@ts-expect-error
  const { state, dispatch } = useContext(BulkEnrichmentContext);
  const [linkedinColumnError, setLinkedinColumnError] = useState(false);

  const handleCancel = () => {
    dispatch({
      type: actions.IS_MODAL_OPEN,
      payload: false,
    });
    dispatch({
      type: actions.SET_LINKEDIN_COLUMN,
      payload: '',
    });
    dispatch({
      type: actions.SET_FIRSTNAME_COLUMN,
      payload: '',
    });
    dispatch({
      type: actions.SET_LASTNAME_COLUMN,
      payload: '',
    });
    dispatch({
      type: actions.SET_SELECTED_LABEL,
      payload: [{ id: null, name: 'Default list' }],
    });
    dispatch({
      type: actions.CURRENT_STEP,
      payload: 1,
    });
  };

  const handleNext = () => {
    if (!state.linkedinColumn) {
      setLinkedinColumnError(true);
      toast.error(
        <FormattedMessage id={'bulkEnrichment.settings.pleaseSelectATableColumnAndLeadList'} />,
      );
    } else if (
      state.linkedinColumn === state.firstnameColumn ||
      state.linkedinColumn === state.lastnameColumn
    ) {
      toast.error(
        <FormattedMessage id="bulkEnrichment.uploadFiles.linkedinAndNameColumnMustBeDifferent" />,
      );
    } else if (state.lastnameColumn !== '' && state.lastnameColumn === state.firstnameColumn) {
      toast.error(
        <FormattedMessage id="bulkEnrichment.uploadFiles.FirstAndLastNamesColumnsMustBeDifferent" />,
      );
    } else {
      setLinkedinColumnError(false);
      dispatch({
        type: actions.CURRENT_STEP,
        payload: 2,
      });
    }
  };

  return (
    <div id="bulk-mapping" className={'modal-container'}>
      <div className={'modal-container-top'}>
        <h1>
          <FormattedMessage id={'bulkEnrichment.settings.settings'} />
        </h1>

        <div className={'modal-content-container'}>
          <div className={'mapping-field'}>
            <h2>
              <FormattedMessage id={'bulkEnrichment.uploadFiles.mappingField'} />
            </h2>

            <div className={'modal-content-container-top'}>
              <h3>
                <FormattedMessage id={'bulkEnrichment.settings.csvFields'} />
              </h3>

              <h3>
                <FormattedMessage id={'bulkEnrichment.settings.tableColumns'} />
              </h3>
            </div>

            {/* LINKEDIN URL */}

            <div className={'modal-content-container-bottom'}>
              <div className="csv-field">
                <p>
                  <FormattedMessage id={'bulkEnrichment.settings.linkedinUrl'} />
                </p>

                <div className="asterisk">
                  <p>
                    (<FormattedMessage id={'required'} />){' '}
                  </p>
                </div>
              </div>

              <div className="linkedin-url-row">
                <Dropdown
                  options={state.csvHeaders}
                  defaultName={<FormattedMessage id={'bulkEnrichment.settings.selectColumn'} />}
                  columnType={'linkedinColumn'}
                  actionName={'SET_LINKEDIN_COLUMN'}
                  hasError={linkedinColumnError}
                  setHasError={setLinkedinColumnError}
                  mandatory={true}
                />
                {linkedinColumnError && (
                  <p className="error-message">
                    <FormattedMessage id="bulkEnrichment.settings.requiredField" />
                  </p>
                )}
              </div>
            </div>
            {/* FIRST NAME */}

            <div className={'modal-content-container-bottom'} style={{ marginTop: '20px' }}>
              <div className="csv-field">
                <p>
                  <FormattedMessage id="firstName" />
                </p>

                <div className="info">
                  <InfoToolTip
                    text={intl.formatMessage({
                      id: 'bulkEnrichment.uploadFiles.infoToolTip',
                    })}
                    style={{
                      width: '13px',
                      marginTop: '-10px',
                      cursor: 'pointer',
                    }}
                    containerStyle={{
                      lineHeight: '18px',
                      padding: '8px',
                    }}
                  />
                </div>
              </div>

              <Dropdown
                options={state.csvHeaders}
                defaultName={<FormattedMessage id={'bulkEnrichment.settings.selectColumn'} />}
                columnType={'nameColumn'}
                actionName={'SET_FIRSTNAME_COLUMN'}
              />
            </div>

            {/* LAST NAME */}

            <div className={'modal-content-container-bottom'} style={{ marginTop: '20px' }}>
              <div className="csv-field">
                <p>
                  <FormattedMessage id="lastName" />
                </p>

                <div className="info">
                  <InfoToolTip
                    text={intl.formatMessage({
                      id: 'bulkEnrichment.uploadFiles.infoToolTip',
                    })}
                    style={{
                      width: '13px',
                      marginTop: '-10px',
                      cursor: 'pointer',
                    }}
                    containerStyle={{
                      lineHeight: '18px',
                      padding: '8px',
                    }}
                  />
                </div>
              </div>

              <Dropdown
                options={state.csvHeaders}
                defaultName={<FormattedMessage id={'bulkEnrichment.settings.selectColumn'} />}
                columnType={'nameColumn'}
                actionName={'SET_LASTNAME_COLUMN'}
              />
            </div>
          </div>

          <div className={'lead-list'}>
            <h2>
              <FormattedMessage id={'bulkEnrichment.settings.chooseALeadList'} />
            </h2>

            <DropdownLeadList
              defaultName={<FormattedMessage id={'bulkEnrichment.settings.selectList'} />}
            />
          </div>
        </div>
      </div>
      {/* FOOTER*/}

      <div className={'modal-container-bottom'}>
        <ProgressSteps />

        <div className={'modal-container-bottom-buttons'}>
          <a onClick={handleCancel} className="btn btn2 btn-md mr14">
            <FormattedMessage id={'bulkEnrichment.settings.cancel'} />
          </a>

          <a onClick={handleNext} className="btn btn1 btn-md">
            <FormattedMessage id={'bulkEnrichment.settings.next'} />
          </a>
        </div>
      </div>
    </div>
  );
}

export default MappingField;
