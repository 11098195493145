import React, { useEffect, useRef, useState } from 'react';
import './Animation.css';

function FadeIn({ children, toggle, setToggle, style }: any) {
  const fade_ref = useRef(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  function handleClickOutside(e: any) {
    if (fade_ref.current && !fade_ref.current.contains(e.target)) {
      setToggle(false);
    }
  }

  useEffect(() => {
    let timeout: any = null;
    if (fade_ref.current) {
      fade_ref.current.style.transition = 'all .3s ease';
      if (toggle) {
        timeout = setTimeout(() => {
          fade_ref.current.style.opacity = '1';

          fade_ref.current.style.transform = 'translateX(0)';
        }, 10);

        fade_ref.current.style.display = 'inline-block';
      } else {
        timeout = setTimeout(() => {
          fade_ref.current.style.display = 'none';
        }, 10);

        fade_ref.current.style.opacity = '0';

        fade_ref.current.style.transform = 'scale(0.9)';
      }
    }
    return () => clearTimeout(timeout);
  }, [toggle]);
  // if (!toggle) return null;
  return (
    <div ref={fade_ref} style={{ zIndex: 10000, display: 'none', position: 'relative', ...style }}>
      {children}
    </div>
  );
}

function SlideDown({
  children,
  toggle,
  setToggle,
  className,
  style,
  heightSize,
  closeSelect,
  withoutScroll,
}: any) {
  const slide_ref = useRef(null);
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  function handleClickOutside(e: any) {
    if (closeSelect) {
      setToggle(false);
    }
    if (e.target.id == 'button-collapse') {
      return;
    }
    if (e.target.id == 'search-tag-input') {
      return;
    }

    if (slide_ref.current && !slide_ref.current.contains(e.target)) {
      setToggle(false);
    }
  }

  useEffect(() => {
    if (slide_ref.current) {
      if (toggle) {
        slide_ref.current.style.transition = 'all .5s ease';
        if (!withoutScroll) {
          setTimeout(() => {
            if (slide_ref.current) slide_ref.current.style.overflow = 'auto';
          }, 400);
        } else {
          setTimeout(() => {
            if (slide_ref.current) slide_ref.current.style.overflow = 'unset';
          }, 400);
        }

        if (heightSize) {
          slide_ref.current.style.maxHeight = heightSize + 'px';
        } else {
          slide_ref.current.style.maxHeight = '60vh';
        }

        slide_ref.current.style.transform = 'translateY(0px)';

        if (toggle === 'addedby') {
          slide_ref.current.style.padding = '20px 0';
        }
        if (toggle === 'labels') {
          slide_ref.current.style.padding = '20px';
        }
      } else {
        slide_ref.current.style.maxHeight = '0px';

        slide_ref.current.style.overflow = 'hidden';

        slide_ref.current.style.padding = '0 0';

        slide_ref.current.style.transform = 'translateY(-7px)';
      }
    }
  }, [toggle]);
  if (!toggle) return null;
  return (
    <div ref={slide_ref} className={className} style={style ? style : { left: '-10px' }}>
      {children}
    </div>
  );
}
function Appear({ setToggle, toggle, className, children, element, style, listStyle }: any) {
  const appear_ref = useRef(null);
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  function handleClickOutside(e: any) {
    if (appear_ref.current && !appear_ref.current.contains(e.target)) {
      setToggle(false);
    }
  }
  if (toggle) {
    return (
      <div
        ref={appear_ref}
        className={className}
        style={{
          left: '-10px',
          width: 'max-content',
          padding: 5,
          ...style,
        }}
      >
        {element && <div style={{ padding: 10 }}>{element}</div>}

        <div
          style={{
            maxHeight: 500,
            overflow: 'auto',
            padding: 10,
            ...listStyle,
          }}
        >
          {children}
        </div>
      </div>
    );
  }

  return <div />;
}
export { SlideDown, FadeIn, Appear };
