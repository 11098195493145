import { convertToJsonAndHandleErrors, getToken } from '../utils';
import { API_URL } from '../../constants';

export const createLabel = (name: any) => {
  return fetch(`${API_URL}/organization/label/add`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify({ name }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const editLabel = (label: any) => {
  return fetch(`${API_URL}/organization/label/update`, {
    method: 'PUT',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(label),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const getLabels = () => {
  return fetch(`${API_URL}/organization/label/getAll`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return data;
    });
};
export const updateLabel = (label: any) => {
  return fetch(`${API_URL}/organization/labels`, {
    method: 'PUT',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(label),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const deleteLabel = (labelId: any) => {
  return fetch(`${API_URL}/organization/label/delete`, {
    method: 'delete',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify({ labelId }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
