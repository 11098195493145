import React, { useContext, useState, useEffect, useRef } from 'react';

import { Field } from '../../common/common.tools';
import { FormattedMessage } from 'react-intl';
import { getSubscriptionInfos } from '../../../../../services/api/credits.api';
import { actions, BillingContext } from '../../../store/billing.store';
import { formatSubscribeInfo } from '../../../../../services/utils/tools';
import { useIntl } from 'react-intl';
import NumberFormatter from '../../../../../components/NumberFormatter/NumberFormatter';
import './DiscountSection.css';

export default function DiscountSection({
  couponModalToggle,
  setCouponModalToggle,
  user,
  organizationCredits,
}: any) {
  const [coupon, setCoupon] = useState(null);
  const [applyCoupon, setApplyCoupon] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  //@ts-expect-error
  const { state, dispatch } = useContext(BillingContext);
  const [showInput, setShowInput] = useState(false);

  const wrapperRef = useRef(null);

  const intl = useIntl();

  useEffect(() => {
    //@ts-expect-error
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowInput(false);
      }
    }

    if (showInput) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showInput]);

  const handleSubmitCoupon = async () => {
    const plan = state.selectedPlan;

    setLoading(true);

    const { data, statusCode } = await getSubscriptionInfos({
      numberOfSeats: plan.seats || plan.numberOfSeats,
      planId: plan.planId,
      isAnnual: plan.yearly,
      country: state.checkoutForm.country,
      numberOfMonths: plan.numberOfMonths,
      extraCredits:
        plan.extraCredits &&
        plan.extraCredits.map((credit: any) => ({
          amount: credit.addedCredits,
          creditType: credit.creditType,
        })),
      coupon,
    });

    setLoading(false);

    if (statusCode === 200) {
      //@ts-expect-error
      const discount = data.items.find((item: any) => item.info === 'discount');
      setApplyCoupon(discount);
      //@ts-expect-error
      if (!data.ok && data.message) {
        //@ts-expect-error
        return setError(data.messageTranslated || data.message);
      }
      const planWithDiscount = {
        //@ts-expect-error
        ...data.plan,
        isPrivate: plan.isPrivate,
        //@ts-expect-error
        isUpgrade: data.isUpgrade,
        discount,
        ...formatSubscribeInfo(data),
        coupon,
      };
      planWithDiscount.subscriptionInfo = state.selectedPlan.subscriptionInfo;
      dispatch({
        type: actions.GET_SUBSCRIPTION_INFOS,
        payload: planWithDiscount,
      });
      setError(null);
    } else {
      //@ts-expect-error
      setError(data.messageTranslated || data.message);
    }
  };

  const handleCancelCoupon = () => {
    dispatch({
      type: actions.ON_CHECKOUT_FORM,
      payload: { ...state.checkoutForm, coupon: null },
    });
  };

  //@ts-expect-error
  const handleCouponChange = (e) => {
    setCoupon(e.target.value);
  };

  if (applyCoupon) {
    return (
      <div className="inline-info card-detail-ui">
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <FormattedMessage id={'billing.couponCode'} /> :
          <span
            className="section-info"
            style={{ marginLeft: 20, width: 'max-content', color: '#3b53d2' }}
          >
            {applyCoupon.coupon}{' '}
            <span style={{ marginLeft: 4, color: '#777' }}>{applyCoupon.percentage}%</span>
          </span>
        </span>

        <span>
          <strong>
            <NumberFormatter
              value={applyCoupon.unitPrice / 100}
              user={user}
              currency={true}
              decimals={true}
            />
          </strong>
        </span>
      </div>
    );
  }
  return (
    <div id="discount-code-ctn" ref={wrapperRef}>
      <div className="discount-code-wrapper">
        {!showInput && (
          <div className="discount-btn" onClick={() => setShowInput(true)}>
            <span>
              <FormattedMessage id={'billing.haveACoupon'} />
            </span>
          </div>
        )}
        {showInput && (
          <div className="input-btn">
            <Field
              onChange={handleCouponChange}
              value={coupon}
              placeholder={intl.formatMessage({ id: 'billing.haveACoupon' })}
            />
            {coupon && coupon.length > 0 && (
              <button
                style={{ zIndex: 0 }}
                type={'button'}
                className={'apply-btn'}
                onClick={handleSubmitCoupon}
              >
                {loading ? (
                  <img src={'/assets/images/loading.gif'} width={25} />
                ) : (
                  <FormattedMessage id={'billing.apply'} />
                )}
              </button>
            )}
            <div className={'has-error'}>{error}</div>
          </div>
        )}
      </div>
    </div>
  );
}
