import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function WarningSection({ textElement }: any) {
  return (
    <div
      className={'error-warning '}
      style={{
        textAlign: 'center',
        fontSize: 15,
        padding: 20,
        border: '1px solid coral',
        margin: 30,
        borderRadius: 11,
      }}
    >
      <h3 style={{ fontSize: 20 }}>
        <FormattedMessage id={'billing.attention'} />{' '}
      </h3>
      {textElement}
    </div>
  );
}
