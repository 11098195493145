import { API_URL } from '../../constants';
import { convertToJsonAndHandleErrors, getToken } from '../utils';

export const getNotifications = () => {
  return fetch(`${API_URL}/notifications`, {
    method: 'GET',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      //@ts-expect-error
      json: true,
      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const seeNotifications = () => {
  return fetch(`${API_URL}/notifications/see`, {
    method: 'PUT',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      //@ts-expect-error
      json: true,
      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const clickNotification = (id: any) => {
  return fetch(`${API_URL}/notifications/click`, {
    method: 'PUT',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      //@ts-expect-error
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify({ id }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const getNotificationsSettings = () => {
  return fetch(`${API_URL}/notifications/settings/get`, {
    method: 'GET',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      //@ts-expect-error
      json: true,
      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const updatedNotificationsSettings = (data: any) => {
  return fetch(`${API_URL}/notifications/settings/edit`, {
    method: 'Put',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      //@ts-expect-error
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
