import React from 'react';
import './DisplayWorkspaces.css';
import { capitalize } from '../../services/utils';
import { FormattedMessage } from 'react-intl';

const DisplayWorkspaces = ({
  organization,
  handleRequestJoinOrganization,
  handleAutoJoinOrganization,
}: any) => {
  const allMembers = [...organization.admins, ...organization.members];

  const requestToJoin = () => {
    if (organization && organization._id) handleRequestJoinOrganization(organization._id);
  };
  const autoJoin = () => {
    if (organization && organization._id) handleAutoJoinOrganization(organization._id);
  };

  return (
    <div id="display-workspaces">
      <div className="display-wk-left">
        <img
          src="/assets/images/ic_organization active.svg"
          width={'34px'}
          height={'34px'}
          alt="Organization icon"
        />

        <div className="display-wk-left-inner">
          <h1>{organization.name}</h1>

          <p>
            {capitalize(allMembers[0]?.firstName)} {capitalize(allMembers[0]?.lastName)}
            {organization.totalMembersCount - 1 > 0 &&
              (organization.totalMembersCount - 1 === 1 ? (
                <span>
                  {' '}
                  <FormattedMessage
                    id="signUpWk.andOneOther"
                    values={{ value: organization.totalMembersCount }}
                  />
                </span>
              ) : (
                <span>
                  {' '}
                  <FormattedMessage
                    id="signUpWk.andXOthers"
                    values={{ value: organization.totalMembersCount }}
                  />
                </span>
              ))}
          </p>
        </div>
      </div>
      {organization.hasSentJoinRequest ? (
        <button
          className="btn2"
          style={{ backgroundColor: '#C0C2CF', cursor: 'not-allowed', color: 'white' }}
        >
          + <FormattedMessage id="organization.requestPending" />
        </button>
      ) : organization.couldUserAutoJoin ? (
        <button className="btn btn3" onClick={autoJoin}>
          + <FormattedMessage id="signupWk.join" />
        </button>
      ) : (
        <button className="btn btn3" onClick={requestToJoin}>
          + <FormattedMessage id="signupWk.requestToJoin" />
        </button>
      )}
    </div>
  );
};

export default DisplayWorkspaces;
