import { FormattedMessage } from 'react-intl';
import React from 'react';

const SalesforceTabs = ({ salesforceTab, setSalesforceTab }: any) => {
  return (
    <div
      className="subscription-tabs"
      style={{
        marginTop: '-10px',
        marginBottom: '40px',
      }}
    >
      <ul className="nav nav-tabs">
        <li onClick={() => setSalesforceTab('lead')}>
          <a className={salesforceTab === 'lead' ? 'active' : ''}>
            <FormattedMessage id={'settings.integration.salesforce.lead'} />
          </a>
        </li>

        <li onClick={() => setSalesforceTab('contact')}>
          <a className={salesforceTab === 'contact' ? 'active' : ''}>
            <FormattedMessage id={'settings.integration.salesforce.contact'} />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default SalesforceTabs;
