import React, { useCallback, useEffect, useRef, useState, forwardRef } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input';
import { OverlayTrigger } from 'react-bootstrap';
import { Tooltip } from '../../../../components';
import { injectIntl, useIntl } from 'react-intl';
import './Field.css';

function isValidPhoneNumber(param: any) {
  return true;
}

const FieldBase = forwardRef(
  (
    {
      label,
      onChange,
      className,
      type,
      value,
      passwordRef,
      formType,
      element,
      disabled,
      hideCheck,
      id,
      inputValidationId,
      setInputValidation,
      hideCheckIcon,
      intl,
      containerStyle,
      autoFocus,
    }: any,
    ref,
  ) => {
    const [validation, setValidation] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
      if (inputValidationId === id) {
        setValidation('error');
      } else {
        setValidation('');
      }
    }, [inputValidationId]);

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    const handleOnChange = useCallback(
      //@ts-expect-error
      (e) => {
        if (type === 'password') {
          if (e.target.value && formType !== 'signin') {
            if (passwordRegex.test(e.target.value)) {
              setValidation('success');
            } else {
              setValidation('error');
            }
          } else {
            setValidation('');
          }
        }
        if (e.target.value) {
          if (setInputValidation) setInputValidation('');
          if (type !== 'password') setValidation('');
        } else {
          setValidation('error');
        }
        onChange(e.target.value);
      },
      [onChange, setInputValidation, type, formType],
    );

    const toggleShowPassword = () => {
      setShowPassword((prev) => !prev);
    };

    return (
      <div
        id="field"
        style={{
          ...containerStyle,
          ...(type === 'password' ? { position: 'relative' } : {}),
        }}
        className={
          'floating-effect  form-group ' +
          (formType !== 'signin' && !hideCheckIcon ? validation : '')
        }
      >
        <input
          type={type === 'password' && showPassword ? 'text' : type ? type : 'text'}
          className={className}
          placeholder={' '}
          onChange={handleOnChange}
          value={value}
          //@ts-expect-error
          ref={ref}
          autoFocus={autoFocus}
          disabled={disabled}
          id={id}
          aria-label={label}
          autoComplete={
            id === 'lastName' || id === 'firstName'
              ? 'off'
              : id === 'password'
                ? 'new-password'
                : null
          }
        />
        <label>{label}</label>
        {type === 'password' && (
          <button
            type="button"
            onClick={toggleShowPassword}
            className="toggle-password-visibility"
            aria-label={showPassword ? 'Hide password' : 'Show password'}
          >
            <i
              style={{
                cursor: 'pointer',
                color: '#797f99',
              }}
              className={showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'}
            >
              {' '}
            </i>
          </button>
        )}
        {formType !== 'signin' && !hideCheckIcon && (
          <span className="checked-icon">
            <img src="/assets/images/ic-checked.svg" alt="" />
          </span>
        )}
        {element}
      </div>
    );
  },
);

const PhoneFieldBase = ({
  onChange,
  propsValue,
  id,
  inputValidationId,
  setInputValidation,
  intl,
}: any) => {
  const [value, setValue] = useState();
  const [internationalValue, setInternationalValue] = useState(null);
  const phoneRef = useRef(null);

  useEffect(() => {
    const inputRef = document.querySelector('.PhoneInputInput');
    const label = document.createElement('label');
    label.style.marginLeft = '80px';
    label.innerHTML = intl.formatMessage({ id: 'leads.properties.phone' });

    inputRef.parentNode.insertBefore(label, inputRef.nextSibling);

    phoneRef.current = inputRef;
    setValue(propsValue);
  }, [propsValue, intl]);

  const handleValue = useCallback(
    (tel: any) => {
      setInternationalValue('');
      if (tel) {
        setInputValidation('');
        let international = formatPhoneNumberIntl(tel);
        setValue(tel);
        const isValidPhone = isValidPhoneNumber(international);
        setInternationalValue(international);
        let phone = formatPhoneNumber(tel);
        let phoneArray = international.split(' ');
        const countryCode = phoneArray[0];
        phoneArray = phoneArray.filter((chunk) => chunk !== countryCode);
        onChange(countryCode, phoneArray.join(''), isValidPhone);
      }
    },
    [onChange, setInputValidation],
  );

  const handleOnBlur = () => {
    setValue(internationalValue);
  };

  const phoneValidated = isValidPhoneNumber(value) ? 'success' : 'error';
  const isPhoneNotEmpty = inputValidationId === id ? 'error' : '';

  return (
    <div className={'floating-effect  form-group ' + (isPhoneNotEmpty && phoneValidated)}>
      <PhoneInput
        placeholder=" "
        value={value}
        defaultCountry={intl.locale === 'en' ? 'US' : 'FR'}
        onBlur={handleOnBlur}
        onChange={handleValue}
        error={'invalid'}
        id={id}
      />
      {value && !isValidPhoneNumber(value) && (
        <OverlayTrigger
          placement="bottom"
          show={true}
          overlay={(props) =>
            Tooltip({
              ...props,
              data: intl.formatMessage({ id: 'auth.invalidPhone' }),
            })
          }
        >
          <div />
        </OverlayTrigger>
      )}
      {inputValidationId === id && !value && (
        <OverlayTrigger
          placement="bottom"
          show={true}
          overlay={(props) =>
            Tooltip({
              ...props,
              data: 'Invalid phone',
            })
          }
        >
          <div />
        </OverlayTrigger>
      )}
    </div>
  );
};

//@ts-expect-error
const Field = injectIntl(FieldBase);
const PhoneField = injectIntl(PhoneFieldBase);

const MemoizedField = React.memo(Field);
const MemoizedPhoneField = React.memo(PhoneField);

export { MemoizedField as Field, MemoizedPhoneField as PhoneField };
