import React, { useState } from 'react';

import { InvitesLeft } from '../Onboarding.tools';
import { FormattedMessage, useIntl } from 'react-intl';

import InputEmailTags from '../../../../components/InputEmailTags/InputEmailTags';
import { deleteInvitation } from '../../../../services/api/auth.api';

export default function InviteFriends({
  invitations,
  sendInvitations,
  invitationType,
  setInvitationType,
  deleteInvitations,
}: any) {
  const intl = useIntl();
  const [invitationsToSend, setInvitationsToSend] = useState(null);
  const [error, setError] = useState(null);
  let goldAccepted =
    invitations &&
    invitations.goldInvitations &&
    invitations.goldInvitations.filter((invitation: any) => invitation.accepted).length;
  let standardAccepted =
    invitations &&
    invitations.standardInvitations &&
    //@ts-expect-error
    invitations.standardInvitations.filter((invitation) => invitation.accepted).length;

  const checkValidity = (email: string) => {
    let emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]+$/g;
    return emailRegex.test(email);
  };

  //@ts-expect-error
  const handleOnChange = (tags, txtValue) => {
    //const value = e.target.value;
    let invalidsNumber = tags.filter((tag: any) => tag.isValid == false).length;
    let allInvalids = tags.some((tag: any) => tag.isValid);
    if (invalidsNumber) {
      setError(
        intl.formatMessage({ id: 'home.youHaveEnteredInvalidEmail' }, { value: invalidsNumber }),
      );
    } else {
      setError(null);
    }
    if (
      (tags && tags.length && (tags.length > 1 || invalidsNumber !== 1) && allInvalids) ||
      checkValidity(txtValue)
    ) {
      setInvitationsToSend(tags.filter((tag: any) => tag.isValid).map((tag: any) => tag.value));
    } else {
      setInvitationsToSend(null);
    }
  };
  const handleSendInvitations = () => {
    // window.history.replaceState(null, null, "/onboarding/invite");
    if (sendInvitations) sendInvitations(invitationsToSend, invitationType);
  };
  const handleDeleteInvitations = async (invitee: any) => {
    await deleteInvitation(invitee);
  };
  return (
    <div className={'onboarding-content invitations '}>
      <div className={'invites-container'}>
        <div
          className="gold-invites"
          style={invitationType == 'goldInvitations' ? { borderColor: '#d25c19' } : {}}
          onClick={() => setInvitationType('goldInvitations')}
        >
          <InvitesLeft total={3} accepted={goldAccepted} isGold />

          <h3>
            <div>
              <img
                src="/assets/images/orange_star.svg"
                alt=""
                style={{ width: 25, marginRight: 10, marginTop: -4 }}
              />
              3{' '}
              <FormattedMessage
                id={'home.onboarding.goldInvites'}
                defaultMessage={'Gold invites'}
              />
            </div>
            {invitationType == 'goldInvitations' && (
              <img
                src="/assets/images/check.svg"
                alt=""
                style={{ width: 25, marginRight: 10, marginTop: -4 }}
              />
            )}
          </h3>

          <p>
            <FormattedMessage
              id={'home.onboarding.goldInvitesDesc'}
              values={{ strong: (chunk) => <strong> {chunk} </strong> }}
            />
          </p>
        </div>

        <div
          className="normal-invites"
          style={
            invitationType == 'standardInvitations' ? { borderColor: 'rgb(77, 104, 248)' } : {}
          }
          onClick={() => setInvitationType('standardInvitations')}
        >
          <InvitesLeft total={10} accepted={standardAccepted} />

          <h3>
            <div>
              <img
                src="/assets/images/blue_flash.svg"
                alt=""
                style={{ width: 25, marginRight: 10, marginTop: -4 }}
              />
              10{' '}
              <FormattedMessage
                id={'home.onboarding.standardInvites'}
                defaultMessage={'Standard invites'}
              />
            </div>
            {invitationType == 'standardInvitations' && (
              <img
                src="/assets/images/check_blue.svg"
                alt=""
                style={{ width: 25, marginRight: 10, marginTop: -4 }}
              />
            )}
          </h3>

          <p>
            <FormattedMessage
              id={'home.onboarding.standardInvitesDesc'}
              defaultMessage={
                'Gives 10 phone credits to your colleagues and get 10 phone credits yourself.'
              }
              values={{ strong: (chunk) => <strong>{chunk} </strong> }}
            />
          </p>
        </div>
      </div>

      {/* <textarea placeholder={"Enter email"} onChange={handleOnChange} />*/}

      <div
        className={
          'input-tags-invite-container ' +
          (invitationType == 'goldInvitations' ? 'is-gold' : 'is-standard')
        }
      >
        <InputEmailTags
          domains={[]}
          onChange={handleOnChange}
          //  placeholder={"Type email address of your colleague(s), press \"SPACE\" to add multiple..."}
          placeholder={intl.formatMessage({ id: 'auth.typeYourEmailAddress' })}
          checkValidity={checkValidity}
        />
        {error && (
          <div className={'invalid-emails-error'} style={{ marginTop: 20 }}>
            <img src="/assets/images/ic-alert-dark.svg" alt="" /> {error}
          </div>
        )}
      </div>

      <p>
        {invitationType == 'goldInvitations' ? (
          <FormattedMessage
            id={'home.onboarding.invitesInfoMessage'}
            values={{ strong: (chunk) => <strong> {chunk} </strong> }}
          />
        ) : (
          <FormattedMessage id={'home.onboarding.invitesStandardInfoMessage'} />
        )}
      </p>

      <button
        className="btn btn1 invites-btn"
        onClick={!!invitationsToSend ? handleSendInvitations : () => null}
        /*style={!!invitationsToSend ? {} : { opacity: 0.5 }}*/
      >
        <FormattedMessage id={'home.onboarding.sendInvite'} />
      </button>
      {(invitations['goldInvitations'].length > 0 ||
        invitations['standardInvitations'].length > 0) && (
        <div className={'invitations-status'}>
          {/*PENDING*/}

          <div className={'pending-invitations '}>
            <h3>
              <FormattedMessage id={'auth.pendingInvitations'} />
            </h3>

            <div className="emails-tags">
              {invitations['goldInvitations']
                .filter((invt: any) => !invt.accepted)

                .map((invitation: any) => (
                  <span className={'gold'}>
                    {invitation.email}

                    <span
                      className={'close-tag'}
                      onClick={() => deleteInvitations(invitation.email)}
                    >
                      <img src="/assets/images/close.svg" alt="" />
                    </span>
                  </span>
                ))}
              {invitations['standardInvitations']
                .filter((invt: any) => !invt.accepted)

                .map((invitation: any) => (
                  <span className={'standard'}>
                    {invitation.email}

                    <span
                      className={'close-tag'}
                      onClick={() => deleteInvitations(invitation.email)}
                    >
                      <img src="/assets/images/close.svg" alt="" />
                    </span>
                  </span>
                ))}
            </div>
          </div>
          {/*ACCEPTED*/}
          {(invitations['goldInvitations'].filter((invt: any) => invt.accepted).length > 0 ||
            invitations['standardInvitations'].filter((invt: any) => invt.accepted).length > 0) && (
            <div className={'pending-invitations  accepted'}>
              <h3>
                <FormattedMessage id={'auth.acceptedInvitations'} />
              </h3>

              <div className="emails-tags">
                {invitations['goldInvitations']
                  .filter((invt: any) => invt.accepted)

                  .map((invitation: any) => (
                    <span className={'gold'}>
                      {invitation.email}

                      <img src="/assets/images/ic-checked.svg" alt="" />
                    </span>
                  ))}
                {invitations['standardInvitations']
                  .filter((invt: any) => invt.accepted)

                  .map((invitation: any) => (
                    <span className={'standard'}>
                      {invitation.email}

                      <img src="/assets/images/ic-checked.svg" alt="" />
                    </span>
                  ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
