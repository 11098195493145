import React, { useEffect, useRef, useState } from 'react';
import './OptOut.css';
import { Col, Container, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { optOut, confirmOptOut } from '../../services/api/optOut.api';
import { toast, ToastContainer } from 'react-toastify';

export default function OptOut() {
  const [recaptchaToken, setRecaptchaToken] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    token: '',
    linkedinId: '',
    fullName: '',
  });
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [humanIntervention, setHumanIntervention] = useState(false);
  const [isEmailOptOut, setIsEmailOptOut] = useState(true);
  const captchaRef = useRef(null);
  useEffect(() => {
    const verifyCallback = async (recaptchaToken: any) => {
      setRecaptchaToken(recaptchaToken);
    };

    if (window.captchaReady) {
      window.grecaptcha.render('recaptcha', {
        sitekey: '6LeIxoYgAAAAADf4ejpNpqo10MiUBtx8iSLgS7qR',
        callback: verifyCallback,
      });
    } else {
      setTimeout(() => {
        if (window.captchaReady) {
          window.grecaptcha.render('recaptcha', {
            sitekey: '6LeIxoYgAAAAADf4ejpNpqo10MiUBtx8iSLgS7qR',
            callback: verifyCallback,
          });
        } else {
          setTimeout(() => {
            window.grecaptcha.render('recaptcha', {
              sitekey: '6LeIxoYgAAAAADf4ejpNpqo10MiUBtx8iSLgS7qR',
              callback: verifyCallback,
            });
          }, 1000);
        }
      }, 500);
    }
  }, []);

  const onChange = (e: any, id: any) => {
    let obj = Object.assign({}, formData);
    //@ts-expect-error
    obj[id] = e.target.value;
    setFormData(obj);
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    let result = await optOut({ recaptchaToken, isEmailOptOut, ...formData });
    if (result && result.success) setConfirm(true);
    else {
      toast.error(result.message);

      window.grecaptcha.reset();
    }
  };

  const onSubmitToken = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    let result = await confirmOptOut({
      token: formData.token,
      email: formData.email,
      isEmailOptOut,
    });
    setLoading(false);
    if (result && result.success) setSuccess(true);
    else if (result && result.message === 'too many profiles to opt out')
      setHumanIntervention(true);
    else toast.error(result.message);
  };

  if (success) {
    return (
      <>
        <Header />

        <div className={'optout-container'}>
          <div className={'optout-success'}>
            Thank you for your request. We confirm your details have been successfully deleted from
            our database.
          </div>
        </div>

        <ToastContainer position="bottom-right" style={{ top: 100 }} />
      </>
    );
  }

  if (humanIntervention) {
    return (
      <>
        <Header />

        <div className={'optout-container'}>
          <div className={'optout-success'}>
            Thank you for your request. We confirm our team will analyse this and come back to you
            as soon as possible. <br />
            You can also get in contact with us at privacy@kaspr.io.
          </div>
        </div>

        <ToastContainer position="bottom-right" style={{ top: 100 }} />
      </>
    );
  }

  if (confirm) {
    return (
      <>
        <Header />

        <div className={'optout-container'}>
          <ConfirmIntro email={formData.email} />

          <form onSubmit={onSubmitToken}>
            <Field
              required
              title={'Email (*required)'}
              onChange={onChange}
              value={formData.email}
              id={'linkedinId'}
            />

            <Field
              required
              title={'Confirmation Code (*required)'}
              onChange={onChange}
              value={formData.token}
              id={'token'}
            />
            {loading ? (
              <button>
                <i className="fa fa-circle-o-notch fa-spin"></i>Loading
              </button>
            ) : (
              <button type={'submit'}>Verify token</button>
            )}
          </form>

          <Footer />
        </div>

        <ToastContainer position="bottom-right" style={{ top: 100 }} />
      </>
    );
  }

  return (
    <>
      <Header />

      <div className={'optout-container'}>
        <Col
          md={15}
          className="subscription-tabs"
          style={{ marginBottom: '20px', transform: 'scale(0.8)' }}
        >
          <ul className="nav nav-tabs" style={{ width: 'max-content' }}>
            <li onClick={() => setIsEmailOptOut(true)}>
              <a className={isEmailOptOut ? 'active' : ''}>Email</a>
            </li>

            <li onClick={() => setIsEmailOptOut(false)}>
              <a className={isEmailOptOut ? '' : 'active'}>All data</a>
            </li>
          </ul>
        </Col>

        <Intro information={isEmailOptOut ? 'email' : 'profile'} />

        <form onSubmit={onSubmit}>
          <Field
            required
            title={'Email (*required)'}
            onChange={onChange}
            value={formData.email}
            id={'email'}
            type={'email'}
          />
          {!isEmailOptOut && (
            <Field
              title={'Linkedin profile ID (*optional)'}
              onChange={onChange}
              value={formData.linkedinId}
              id={'linkedinId'}
            />
          )}
          {!isEmailOptOut && (
            <Field
              title={'Full name (*optional)'}
              onChange={onChange}
              value={formData.fullName}
              id={'fullName'}
            />
          )}

          <div id={'recaptcha'} ref={captchaRef} />

          <button type={'submit'}>Remove informations</button>

          <div className={'info-text'}>
            <p>
              If you would like to check what data we hold on you as an individual, or for more
              information, please email us at <span>privacy@kaspr.io</span> .
            </p>

            <p>
              {' '}
              For <strong>US residents only</strong> , you can also opt-out by contacting us on our
              toll-free number: <span>+1 833 902 8733</span> .
            </p>
          </div>
        </form>

        <Footer />
      </div>

      <ToastContainer position="bottom-right" style={{ top: 100 }} />
    </>
  );
}

function Field({ title, onChange, value, type = 'text', required, id }: any) {
  return (
    <div className={'field-container'}>
      <label>{title}</label>

      <div>
        <span id="CollectedForms-6052405" />

        <input
          required={required}
          type={type}
          onChange={(e) => onChange(e, id)}
          value={value}
          id={id}
        />
      </div>
    </div>
  );
}

function Intro({ information }: any) {
  return (
    <div>
      <h1>This form allows you to remove your {information} from our database.</h1>

      <p> Please enter the information required in the form below.</p>
    </div>
  );
}

function ConfirmIntro(props: any) {
  return (
    <div>
      <h1>This form allows you to remove your information from our database.</h1>

      <p>
        An email with a confirmation code was sent to <strong> {props.email} </strong>. Please use
        this code to confirm the removal of your personal information from our database.
      </p>
    </div>
  );
}

function Footer() {
  return (
    <footer>
      <p>
        {' '}
        Kaspr will only use the information you insert when filling this form in order to remove
        your details from our database.
      </p>
    </footer>
  );
}

function Header() {
  return (
    <header className="signup-header">
      <Container>
        <Row className="align-items-center">
          <Col>
            <div className={'logo text-left'}>
              <a className="" href="/">
                <img src="/assets/images/logo_on_white.png" alt="logo" width="125" />
              </a>
            </div>
          </Col>

          <Col>
            <div className="header-right d-flex align-items-center justify-content-end">
              <p className="p16 p-color pr-3">
                Have an account?{' '}
                <NavLink className="text-btn" to="/signin">
                  Log in
                </NavLink>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  );
}
