/*export default  [
  {background: "#F8EFDB" , color  : "#A47023"},
  {background: "#E0F7E9" , color  : "#1E8648"},
  {background: "#EEEAFE" , color  : "#6549D5"},
  {background: "#DDE8F3" , color  : "#113A63"},
  {background: "#FEEBEA" , color  : "#DC4238"},
  {background: "#f3f3f4" , color  : "#6a1b9a"},
  {background: "#f3f3f4" , color  : "#ff4081"},
  {background: "#f3f3f4" , color  : "#0097a7"},
  {background: "#f3f3f4" , color  : "#f57c00"},
  {background: "#f3f3f4" , color  : "#afb42b"},
  {background: "#f3f3f4" , color  : "#4e342e"},
  {background: "#f3f3f4" , color  : "#ff6d00"},
  {background: "#f3f3f4" , color  : "#ffb74d"},
  {background: "#f3f3f4" , color  : "#004d40"},
  {background: "#f3f3f4" , color  : "#64dd17"},
  {background: "#f3f3f4" , color  : "#ccc"},
]*/

export default () => {
  let colors = Array.from(Array(24).keys());
  //@ts-expect-error
  colors = colors.map((color) => {
    let hex = '#' + Math.floor(Math.random() * 16777215).toString(16);
    // return { color: hex, background: hex + "1A" };
    return hex;
  });
  return colors;
};
