import React from 'react';
import './ModifyUsers.css';
import { FormattedMessage } from 'react-intl';

// Increment the user count button
const IncrementButton = ({ onClick }: any) => (
  <div className="increment-button" onClick={onClick}>
    <div className="plus-stick"></div>

    <div className="plus-stick vertical"></div>
  </div>
);

// Decrement the user count button
const DecrementButton = ({ onClick }: any) => (
  <div className="decrement-button" onClick={onClick}>
    <div className="minus-stick"></div>
  </div>
);

const ModifyUsers = ({ selectedPlan, onUserSelect, minSeats, setNbLicenses }: any) => {
  // Function to handle incrementing the number of seats
  const handleIncrement = () => {
    const newNumberOfSeats = selectedPlan.numberOfSeats + 1;
    setNbLicenses(newNumberOfSeats);
    onUserSelect(newNumberOfSeats, selectedPlan);
  };

  // Function to handle decrementing the number of seats
  const handleDecrement = () => {
    if (selectedPlan.numberOfSeats > minSeats) {
      // Ensures not decrementing below minimum seats
      const newNumberOfSeats = selectedPlan.numberOfSeats - 1;
      setNbLicenses(newNumberOfSeats);
      onUserSelect(newNumberOfSeats, selectedPlan);
    }
  };

  return (
    <div id="modify-users">
      <div className="modify-left-ctn">
        <img src="/assets/images/modify-users.svg" alt="Modify Users" />

        <div>
          <h2>
            <FormattedMessage id="organizations.addLicences" />
          </h2>

          <h3>
            <FormattedMessage id="billing.customizePlan.selectTotalNbOfLicenses" />
          </h3>
        </div>
      </div>

      <div className="plan-ctn">
        <DecrementButton onClick={handleDecrement} />

        <span className="number-of-seats">{selectedPlan.numberOfSeats}</span>

        <IncrementButton onClick={handleIncrement} />
      </div>
    </div>
  );
};

export default ModifyUsers;
