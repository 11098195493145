import React, { useContext, useState, useEffect } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

import ManageUsersModal from '../../common/ManageUsersModal/ManagaUsersModal';
import actions from '../../store/billing.actions';
import { BillingContext } from '../../store/billing.store';
import { getSubscriptionInfos } from '../../../../services/api/credits.api';
import { formatSubscribeInfo } from '../../../../services/utils/tools';
import { toast } from 'react-toastify';
import { DASHBOARD_URL } from '../../../../constants';

import CustomToolTip from '../../../../components/CustomToolTip/CustomToolTip';

export default function AddonSection({
  plan,
  organizationCredits,
  subscription,
  openUnsubscribeModal,
  usersManagementToggle,
  setUsersManagement,
  isVersion2,
  organization,
}: any) {
  //@ts-expect-error
  const { state, dispatch } = useContext(BillingContext);
  const [loadingAddUsers, setLoadingAddUsers] = useState(false);
  const [isNoticePeriod, setIsNoticePeriod] = useState(false);

  useEffect(() => {
    const today = new Date();
    const endDateTime = new Date(subscription?.endDate);
    let daysToDecrease = plan.numberOfMonths === 1 ? 2 : 30;
    const noticePeriodDateTime = new Date(endDateTime);
    noticePeriodDateTime.setDate(endDateTime.getDate() - daysToDecrease);

    if (today >= noticePeriodDateTime) {
      setIsNoticePeriod(true);
    } else {
      setIsNoticePeriod(false);
    }
  }, [subscription, plan.numberOfMonths]);

  const handleManagePlanUsers = async (seats: any, plan: any, isDowngrade: any) => {
    plan.isAnnual = plan.numberOfMonths === 12;
    plan.yearly = plan.numberOfMonths === 12;
    let currentPlan = {
      ...plan,
      isAnnual: plan.yearly,
      numberOfSeats: plan.numberOfSeats,
      country: state.checkoutForm.email
        ? state.checkoutForm.country
        : (organizationCredits &&
            organizationCredits.customer &&
            organizationCredits.customer.country) ||
          state.checkoutForm.country,
      numberOfMonths: plan.yearly ? 12 : 1,
    };
    setLoadingAddUsers(true);
    const { data, statusCode } = await getSubscriptionInfos({
      numberOfSeats: seats,
      numberOfMonths: currentPlan.numberOfMonths,
      planId: currentPlan.planId,
      extraCredits: currentPlan.extraCredits,
      country: currentPlan.country,
    });
    setLoadingAddUsers(false);
    if (statusCode == 200) {
      dispatch({
        type: actions.GET_SUBSCRIPTION_INFOS,
        payload: {
          ...currentPlan,
          isPrivate: plan.isPrivate,
          //@ts-expect-error
          automaticRenewal: !!data.nextPeriodItems.length,
          //@ts-expect-error
          renewDate: data.renewDate,
          ...formatSubscribeInfo(data),
          numberOfSeats: seats,
        },
      });
    } else {
      //@ts-expect-error
      toast.error(data.messageTranslated || data.message);
    }
  };

  const onAutoRenewCancel = async ({ creditType }: any) => {
    const currentPlan = {
      ...organizationCredits.plan,
      extraCredits: [{ creditType, amount: 0 }],
    };
    const { data, statusCode } = await getSubscriptionInfos(currentPlan);
    dispatch({
      type: actions.GET_SUBSCRIPTION_INFOS,
      payload: {
        ...currentPlan,
        ...formatSubscribeInfo(data),
      },
    });
  };

  if (!isVersion2) {
    return (
      <div className="info-card billing-info-card">
        <h2>
          <FormattedMessage id={'billing.productAndAddOn'} />{' '}
        </h2>

        <div className={'header-manage'}>
          <span className={'plan-name-tag'}>
            <span>
              {plan.name} <FormattedMessage id={'plan'} />
            </span>

            <span>
              {plan.numberOfSeats} <FormattedMessage id={'billing.users'} />{' '}
            </span>
          </span>

          <a className="btn btn1" href={'/billing/plans'}>
            <FormattedMessage id={'billing.upgradeAndManage'} />
          </a>
        </div>

        <div className={'credits-section'}>
          <div className="info-block">
            <p className={'section-title'} style={{ marginBottom: -3 }}>
              <FormattedMessage id={'credits'} />
            </p>

            <p className={'plan-credits-rm'}>
              <span>
                <FormattedMessage
                  id={
                    plan.numberOfMonths == 12
                      ? 'billing.legacyCreditsPerYear'
                      : 'billing.legacyCreditsPerMonth'
                  }
                  values={{
                    value:
                      plan.legacyCredits.perSeat *
                      (plan.numberOfMonths *
                        (organizationCredits && organizationCredits.plan.planId === '0'
                          ? organization &&
                            organization.organization &&
                            organization.organization.totalMembers
                          : plan.numberOfSeats)),
                  }}
                />
              </span>
            </p>
            {subscription && (
              <div className={'addons-line-foot'} style={{ marginTop: 10 }}>
                <span>
                  <FormattedMessage
                    id={'billing.autoRenewOn'}
                    values={{
                      value: (
                        <span className={'date'}>
                          <FormattedDate
                            value={new Date(subscription.endDate)}
                            year="numeric"
                            month="long"
                            day="numeric"
                          />
                        </span>
                      ),
                    }}
                  />
                </span>{' '}
                <span onClick={() => openUnsubscribeModal(true)}>
                  <FormattedMessage id={'billing.cancelAutoRenew'} />{' '}
                </span>
                <a href={'/billing/info/legacy'} className={'make-a-choice'}>
                  <FormattedMessage id={'billing.makeAChoice'} />{' '}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="info-card billing-info-card">
      <h2>
        <FormattedMessage id={'billing.productAndAddOn'} />{' '}
      </h2>

      <div className={'header-manage'}>
        <span className={'plan-name-tag'}>
          <span>
            {plan.name} <FormattedMessage id={'plan'} />
          </span>{' '}
          <span>
            {plan.numberOfSeats} <FormattedMessage id={'billing.users'} />{' '}
            {plan.planId !== '0' && <small onClick={() => setUsersManagement(true)}>+</small>}
          </span>
        </span>

        <a className="btn btn1" href={'/billing/plans'}>
          <FormattedMessage id={'billing.upgradeAndManage'} />
        </a>
      </div>

      <div className={'credits-section'}>
        <div className="info-block">
          <p className={'section-title'}>
            <FormattedMessage id={'credits'} />
          </p>

          <p className={'plan-credits-rm'}>
            <span>
              <FormattedMessage
                id={
                  plan.numberOfMonths == 12
                    ? 'billing.phoneCreditsPerYear'
                    : 'billing.phoneCreditsPerMo'
                }
                values={{
                  value:
                    plan.phoneCredits.perSeat *
                    plan.numberOfMonths *
                    (organizationCredits && organizationCredits.plan.planId === '0'
                      ? organization &&
                        organization.organization &&
                        organization.organization.totalMembers
                      : plan.numberOfSeats),
                }}
              />
            </span>

            <span>
              <FormattedMessage
                id={
                  plan.numberOfMonths == 12
                    ? 'billing.personalEmailCreditsPerYear'
                    : 'billing.personalEmailCreditsPerMo'
                }
                values={{
                  value:
                    plan.personalEmailCredits.perSeat *
                    plan.numberOfMonths *
                    (organizationCredits && organizationCredits.plan.planId === '0'
                      ? organization &&
                        organization.organization &&
                        organization.organization.totalMembers
                      : plan.numberOfSeats),
                }}
              />
            </span>

            <span>
              <FormattedMessage
                id={
                  plan.numberOfMonths == 12
                    ? 'billing.exportCreditsPerYear'
                    : 'billing.exportCreditsPerMo'
                }
                values={{
                  value:
                    plan.exportCredits.perSeat *
                    plan.numberOfMonths *
                    (organizationCredits && organizationCredits.plan.planId === '0'
                      ? organization &&
                        organization.organization &&
                        organization.organization.totalMembers
                      : plan.numberOfSeats),
                }}
              />
            </span>
          </p>

          {(subscription && !organizationCredits.pending.plan) ||
          (organizationCredits.pending.plan &&
            subscription &&
            organizationCredits.pending.plan.planId !== '0') ? (
            <div className={'addons-line-foot'} style={{ marginTop: 10 }}>
              <span>
                <FormattedMessage
                  id={'billing.autoRenewOn'}
                  values={{
                    value: (
                      <span className={'date'}>
                        <FormattedDate
                          value={new Date(subscription.endDate)}
                          year="numeric"
                          month="numeric"
                          day="numeric"
                        />
                      </span>
                    ),
                  }}
                />
              </span>{' '}
              {!isNoticePeriod ? (
                <span onClick={() => openUnsubscribeModal(true)}>
                  <FormattedMessage id={'billing.cancelAutoRenew'} />{' '}
                </span>
              ) : (
                <CustomToolTip
                  style={{
                    fontSize: '14px',
                    paddingTop: '4px',
                    paddingBottom: '4px',
                  }}
                  element={<FormattedMessage id="billing.noticePeriodTooltip" />}
                >
                  <span style={{ color: 'grey' }}>
                    <FormattedMessage id={'billing.cancelAutoRenew'} />
                  </span>
                </CustomToolTip>
              )}
            </div>
          ) : null}
        </div>
      </div>
      {plan.planId !== '0' && subscription && (
        <>
          <AddonLine
            isNoticePeriod={isNoticePeriod}
            title={<FormattedMessage id={'phoneCredits'} values={{ value: '' }} />}
            numberOfCredits={plan.phoneCredits.extra}
            creditType={'phoneCredits'}
            date={subscription.endDate}
            onAutoRenewCancel={onAutoRenewCancel}
            organizationCredits={organizationCredits}
          />

          <AddonLine
            isNoticePeriod={isNoticePeriod}
            title={<FormattedMessage id={'personalEmailCredits'} values={{ value: '' }} />}
            numberOfCredits={plan.personalEmailCredits.extra}
            creditType={'personalEmailCredits'}
            date={subscription.endDate}
            onAutoRenewCancel={onAutoRenewCancel}
            organizationCredits={organizationCredits}
          />

          <AddonLine
            isNoticePeriod={isNoticePeriod}
            title={<FormattedMessage id={'exportCredits'} values={{ value: '' }} />}
            numberOfCredits={plan.exportCredits.extra}
            creditType={'exportCredits'}
            date={subscription.endDate}
            onAutoRenewCancel={onAutoRenewCancel}
            organizationCredits={organizationCredits}
          />
        </>
      )}
      {usersManagementToggle && (
        <ManageUsersModal
          toggle={usersManagementToggle}
          setToggle={() => {
            if (window.location.href.indexOf('addLicense') > -1) {
              window.location.href = '/billing/info';
            } else {
              setUsersManagement(false);
            }
          }}
          onProceed={handleManagePlanUsers}
          infos={{ plan }}
          organizationCredits={organizationCredits}
          loadingAddUsers={loadingAddUsers}
          setLoadingAddUsers={setLoadingAddUsers}
        />
      )}
    </div>
  );
}

function AddonLine({
  isNoticePeriod,
  title,
  creditType,
  numberOfCredits,
  date,
  onAutoRenewCancel,
  organizationCredits,
}: any) {
  let types: any = {
    phoneCredits: 'phone',
    personalEmailCredits: 'email',
    exportCredits: 'export',
  };

  return (
    <div className="addons-section">
      <div className={'addons-section-line'}>
        <div className={'addons-line-head'}>
          <div>
            <span>
              <FormattedMessage id={'billing.addOn'} /> {title}{' '}
            </span>{' '}
            <span>
              {numberOfCredits} <FormattedMessage id={'billing.creditsMonth'} />{' '}
            </span>{' '}
          </div>

          <a href={'/billing/plans/credits/' + types[creditType]}>
            <FormattedMessage id={'billing.manageAddons'} />
          </a>
        </div>
        {numberOfCredits !== 0 ? (
          <div className={'addons-line-foot'}>
            <span>
              <FormattedMessage
                id={'billing.autoRenewOn'}
                values={{
                  value: (
                    <span className={'date'}>
                      <FormattedDate
                        value={new Date(date)}
                        year="numeric"
                        month="numeric"
                        day="numeric"
                      />
                    </span>
                  ),
                }}
              />
            </span>{' '}
            {!isNoticePeriod ? (
              <span onClick={() => onAutoRenewCancel({ creditType })}>
                <FormattedMessage id={'billing.cancelAutoRenew'} />{' '}
              </span>
            ) : (
              <CustomToolTip
                style={{
                  fontSize: '14px',
                  paddingTop: '4px',
                  paddingBottom: '4px',
                }}
                element={<FormattedMessage id="billing.noticePeriodTooltip" />}
              >
                <span style={{ color: 'grey' }}>
                  <FormattedMessage id={'billing.cancelAutoRenew'} />
                </span>
              </CustomToolTip>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}
