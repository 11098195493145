import React, { useEffect, useState } from 'react';
import { useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import { LAUNCHDARKLY_CLIENT_SIDE_ID } from '../../constants';
import { trackFeatureFlagExperiment } from '../../services/api/global.api';

const countriesToExlude = [
  'IN', // India
];

export default function LDProvider({ children }) {
  const { email: userEmail, country: userCountry } = useSelector((state) => state.profile || {});

  if (countriesToExlude.includes(userCountry)) return <>{children} </>;

  if (!userEmail) return <>{children} </>;

  const LDProviderWithConfig = withLDProvider({
    clientSideID: LAUNCHDARKLY_CLIENT_SIDE_ID,
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
    context: {
      kind: 'user',
      key: userEmail,
      anonymous: false,
    },
  })(() => <>{children}</>);

  return <LDProviderWithConfig />;
}

export const useEvaluatedFlags = (keys) => {
  const userEmail = useSelector((state) => state.profile?.email);
  const [isLDReady, setIsLDReady] = useState(false);
  const [flags, setFlags] = useState({});

  const ldClient = useLDClient();

  useEffect(() => {
    if (ldClient) {
      setFlags(ldClient.allFlags());
      ldClient.waitUntilReady().then(() => {
        setIsLDReady(true);
      });
    }
  }, [ldClient]);

  useEffect(() => {
    if (userEmail && isLDReady) {
      for (let key of keys) {
        if (flags[key] !== undefined) {
          trackFeatureFlagExperiment({
            userId: userEmail,
            experimentKey: key,
            variation: flags[key],
          });
        }
      }
    }
  }, [userEmail, isLDReady, flags]);

  return flags;
};
