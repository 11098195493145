import React, { useEffect, useRef, useState } from 'react';
import './AddressEdit.css';
import { Animation } from '../../../../../../components';
import countries from './countries';
import { FormattedMessage } from 'react-intl';
function AddressEdit({ data, setEdiCompanyToggle, editEditToggle, onChange }: any) {
  const [addFieldToggle, setAddFieldToggle] = useState(false);
  const [addressValue, setAddressValue] = useState({
    country: '',
    city: '',
    headquarter: true,
    postalCode: '',
    description: '',
    line1: '',
  });
  const inputRef = useRef(null);
  useEffect(() => {
    document.addEventListener('click', handleClickOutSide, true);
    return () => {
      document.removeEventListener('click', handleClickOutSide, true);
    };
  }, []);
  const handleClickOutSide = (e: any) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      setAddFieldToggle(false);
      setAddressValue({
        country: '',
        city: '',
        headquarter: true,
        postalCode: '',
        description: '',
        line1: '',
      });
    }
  };
  const handleAddAddress = () => {
    onChange({ value: addressValue, property: 'rawAddresses', index: -1 });
    setAddressValue({
      country: '',
      city: '',
      headquarter: true,
      postalCode: '',
      description: '',
      line1: '',
    });
    setAddFieldToggle(false);
  };
  return (
    <div style={{ width: '100%' }}>
      {data &&
        data.length > 0 &&
        data.map((address: any, i: any) => (
          <AddressField
            address={address}
            toggleValue={'addresses' + i}
            toggle={editEditToggle}
            setToggle={setEdiCompanyToggle}
            onChange={onChange}
            index={i}
            key={i}
          />
        ))}

      <li ref={inputRef} style={{ display: 'inline-block', padding: 9 }}>
        {addFieldToggle ? (
          <>
            <AddressField
              address={addressValue}
              toggle={'addresses-1'}
              toggleValue={'addresses-1'}
              //@ts-expect-error
              setToggle={() => null}
              onChange={({ value }: any) => setAddressValue(value)}
              index={-1}
            />

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button className={'btn btn1 add-btn'} onClick={handleAddAddress}>
                <FormattedMessage id={'add'} />
              </button>
            </div>
          </>
        ) : (
          <button
            style={{
              margin: 0,
              boxShadow: 'none',
              color: '#573ad7',
              padding: 0,
            }}
            onClick={() => {
              setAddFieldToggle(true);
              setEdiCompanyToggle(true);
            }}
          >
            + <FormattedMessage id={'add'} />
          </button>
        )}
      </li>
    </div>
  );
}

function AddressField({ address, toggle, toggleValue, setToggle, onChange, index }: any) {
  const inputContainerRef = useRef(null);
  useEffect(() => {
    document.addEventListener('click', handleClickOutSide, true);
    return () => {
      document.removeEventListener('click', handleClickOutSide, true);
    };
  }, []);

  const getCurrentCountry = (code: any) => {
    const myCountry = countries.find((c) => c.code == code);
    if (myCountry) return { name: myCountry.name, code: myCountry.code };
    else return { name: address.country, code: address.country };
  };

  const handleClickOutSide = (e: any) => {
    if (inputContainerRef.current && !inputContainerRef.current.contains(e.target)) {
      setToggle(null);
    }
  };

  const handleToggle = () => {
    setToggle(toggleValue);
  };
  const handleValueChange = (value: any, type: any) => {
    const currentAddress = Object.assign({}, address);
    currentAddress[type] = value;
    onChange({ value: currentAddress, index, property: 'rawAddresses' });
  };
  return (
    <div
      style={{ display: 'inline' }}
      onClick={() => (toggle !== toggleValue ? handleToggle() : null)}
    >
      {toggle === toggleValue ? (
        <li className={'custom-edit-company '} ref={inputContainerRef}>
          <div>
            <span className={'field_value'}>
              {(address.country ? getCurrentCountry(address.country).name + ', ' : '') +
                (address.city ? address.city + ' ,' : ' ') +
                (address.line1 ? address.line1 + ', ' : ' ') +
                (address.postalCode ? address.postalCode + '. ' : '')}
            </span>
          </div>

          <div className={'company-edit-container'}>
            <span>
              {' '}
              <FormattedMessage id={'street'} />
            </span>{' '}
            <input
              type="text"
              placeholder={'...'}
              value={address.line1 || ''}
              onChange={(e) => handleValueChange(e.target.value, 'line1')}
              autoFocus={true}
            />
          </div>

          <div className={'company-edit-container'}>
            <span>
              {' '}
              <FormattedMessage id={'city'} />
            </span>{' '}
            <input
              type="text"
              placeholder={'...'}
              value={address.city || ''}
              onChange={(e) => handleValueChange(e.target.value, 'city')}
            />
          </div>

          <div className={'company-edit-container'}>
            <span>
              <FormattedMessage id={'postalCode'} />
            </span>{' '}
            <input
              type="text"
              placeholder={'...'}
              value={address.postalCode || ''}
              onChange={(e) => handleValueChange(e.target.value, 'postalCode')}
            />
          </div>

          <CountriesList
            address={address}
            handleValueChange={handleValueChange}
            getCurrentCountry={getCurrentCountry}
          />

          <div className={'company-edit-container'}>
            <span>Description</span>{' '}
            <textarea
              placeholder={'...'}
              value={address.description || ''}
              onChange={(e) => handleValueChange(e.target.value, 'description')}
            />
          </div>
        </li>
      ) : (
        <li className={'editable-field '} style={{ paddingBottom: 28, width: 'max-content' }}>
          <p className={'edit_profile_field_container'} style={{ border: 'none' }}>
            <span className={'field_value'}>
              {(address.country ? getCurrentCountry(address.country).name : '') +
                ' ' +
                (address.city ? address.city + ', ' : ' ') +
                (address.postalCode ? address.postalCode + ', ' : ' ') +
                '...'}
            </span>

            <span className={'edit_profile_field'} style={{ right: -10, top: -10 }}>
              <img src="/assets/images/ic_edit_g.png" alt="" />

              <img
                src="/assets/images/icon-delete.svg"
                alt=""
                onClick={(e) => {
                  e.stopPropagation();
                  onChange({ value: null, property: 'rawAddresses', index });
                }}
              />
            </span>
          </p>
        </li>
      )}
    </div>
  );
}

function CountriesList({ address, handleValueChange, getCurrentCountry }: any) {
  const [matchedCountries, setMatchedCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const refInput = useRef(null);

  useEffect(() => {
    if (refInput && refInput.current && selectedCountry) {
      refInput.current.value = selectedCountry.name;
    }
    return () => {
      setMatchedCountries([]);
      setSelectedCountry(null);
    };
  }, [address.country]);

  const currentCountry = selectedCountry || getCurrentCountry(address.country);
  const onChange = (e: any) => {
    if (e.target.value) {
      const matched = countries.filter((c) =>
        c.name.toLocaleLowerCase().match(e.target.value.toLocaleLowerCase()),
      );

      setMatchedCountries(matched);
    } else {
      setMatchedCountries([]);
    }
  };
  const onCountrySelect = (country: any) => {
    setSelectedCountry(country);
    handleValueChange(country.code, 'country');
  };
  const onInputBlur = () => {
    if (matchedCountries.length === 1) {
      setSelectedCountry(matchedCountries[0]);

      handleValueChange(matchedCountries[0].code, 'country');
    } else {
      refInput.current.value = currentCountry.name;
      handleValueChange(currentCountry.code, 'country');
    }

    setTimeout(() => {
      setMatchedCountries([]);
    }, 100);
  };

  return (
    <div className={'company-edit-container'}>
      <span>
        <FormattedMessage id={'country'} />
      </span>{' '}
      <input
        type="text"
        placeholder={'...'}
        defaultValue={currentCountry.name}
        // onChange={(e) => handleValueChange(e.target.value, "country")}
        onChange={onChange}
        ref={refInput}
        onBlur={onInputBlur}
      />
      {matchedCountries.length > 0 && (
        <div className="cstm-dropdown ">
          <Animation.SlideDown
            toggle={true}
            setToggle={() => true}
            className={'dropdown-menu show'}
            style={{ top: 44, left: 105, padding: 5, overflow: 'hidden' }}
            heightSize={100}
          >
            {matchedCountries.map((c, i) => (
              <div key={i} className={'country-field'} onClick={() => onCountrySelect(c)}>
                {c.name}{' '}
              </div>
            ))}
          </Animation.SlideDown>
        </div>
      )}
    </div>
  );
}

export default AddressEdit;
