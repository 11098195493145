import React, { useState, useEffect, useRef } from 'react';
import './InputField.css';

export default function InputField({
  label,
  type = 'text',
  width = '520px',
  onChange,
  userDomain,
  value,
  placeholder,
}: any) {
  const [inputFocused, setInputFocused] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    // Focus the input when the component mounts
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleFocus = () => {
    setInputFocused(true);
  };

  const handleBlur = () => {
    setInputFocused(false);
  };

  return (
    <div className="input-container" style={{ width: width }}>
      <input
        ref={inputRef}
        id="inputField"
        placeholder={
          userDomain
            ? `${userDomain.split('.')[0].charAt(0).toUpperCase() + userDomain.split('.')[0].slice(1)} Workspace`
            : placeholder
        }
        type={type}
        className={inputFocused ? 'input-focused' : ''}
        value={value}
        required
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        autoFocus // Auto-focus when the input is rendered
      />

      <label htmlFor="inputField">{label}</label>
    </div>
  );
}
