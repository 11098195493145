import React from 'react';
import './UserBlocked.css';
import { FormattedMessage } from 'react-intl';
export default function UserBlocked() {
  return (
    <div className={'user-blocked'}>
      <div style={{ textAlign: 'center' }}>
        <h1>
          <FormattedMessage id={'userBlocked.youAreBlocked'} />
        </h1>

        <h3>
          <FormattedMessage id={'userBlocked.forMoreInformationContactUsOnOurChat'} />{' '}
        </h3>
      </div>
    </div>
  );
}
