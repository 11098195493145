import React from 'react';
import './ExtraCreditsTable.css';
import { FormattedMessage, useIntl } from 'react-intl';
import CurrencyDisplay from '../../../../../components/CurrencyDisplay/CurrencyDisplay';
import { CREDIT_COLORS } from '../../../../../constants';

export function OldExtraCreditsTable({ plan, currentPlan, user, organizationCredits }: any) {
  const intl = useIntl();
  let extraCredits = [
    {
      ...currentPlan.phoneCredits,
      title: intl.formatMessage({ id: 'billing.addOnPhoneCredits' }),
      creditType: 'phoneCredits',
      addOn: null,
    },
    {
      ...currentPlan.personalEmailCredits,
      title: intl.formatMessage({ id: 'billing.addOnPersonalEmailCredits' }),
      creditType: 'personalEmailCredits',
      addOn: null,
    },
    {
      ...currentPlan.exportCredits,
      title: intl.formatMessage({ id: 'billing.addOnExportCredits' }),
      creditType: 'exportCredits',
      addOn: null,
    },
  ];
  extraCredits = extraCredits.map((extra) => {
    //@ts-expect-error
    let addOn = plan.extraCredits.find((plan) => plan.creditType === extra.creditType);
    if (addOn) {
      extra.addOn =
        addOn.addedCredits &&
        addOn.addedCredits * (plan.isUpgrade[extra.creditType] === false ? -1 : 1);
      extra.amount = addOn.unitPrice / 100;
    }
    return extra;
  });
  const totalExtraCredits = extraCredits.reduce(
    (previous, current) => previous + (current.amount || 0),
    0,
  );
  return (
    <div className={'extra-credits-table'}>
      <span className="line-title">
        <FormattedMessage id={'billing.addOns'} />
      </span>
      <div
        style={{
          color: ' #797f99',
          fontWeight: 500,
          fontSize: 13,
          marginTop: -10,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <img src="/assets/images/icon-info.svg" alt="" style={{ marginRight: 6, width: 14 }} />
        <FormattedMessage id={'billing.addonCheckoutTooltip'} />
      </div>
      <table>
        <thead>
          <tr>
            <td>
              <FormattedMessage id={'billing.packageName'} />
            </td>
            <td>
              <FormattedMessage id={'billing.currentCredits'} />
            </td>
            <td>
              <FormattedMessage id={'billing.extraAddonCredits'} />
            </td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {extraCredits.map((extra) => (
            <tr>
              <td>
                <img src={CREDIT_COLORS[extra.creditType].icon} /> {extra.title}{' '}
              </td>
              <td>
                <span className="value-txt">{extra.extra}</span>{' '}
              </td>
              {plan.isPlanSuspended ? (
                <td>
                  {plan.isUpgrade[extra.creditType] == true && (
                    <span className="value-txt isUpgrade">
                      <img src="/assets/images/upgrade-arrow.svg" alt="" /> {extra.extra}
                    </span>
                  )}
                  {plan.isUpgrade[extra.creditType] == false && (
                    <span className="value-txt isDowngrade">
                      <img src="/assets/images/downgrade-arrow.svg" alt="" /> {extra.extra}
                    </span>
                  )}
                </td>
              ) : (
                <td>
                  {plan.isUpgrade[extra.creditType] == true && (
                    <span className="value-txt isUpgrade">
                      <img src="/assets/images/upgrade-arrow.svg" alt="" />{' '}
                      {plan.numberOfMonths == currentPlan.numberOfMonths
                        ? extra.addOn + extra.extra
                        : extra.addOn}
                    </span>
                  )}
                  {plan.isUpgrade[extra.creditType] == false && (
                    <span className="value-txt isDowngrade">
                      <img src="/assets/images/downgrade-arrow.svg" alt="" />{' '}
                      {extra.addOn && plan.numberOfMonths == currentPlan.numberOfMonths
                        ? extra.addOn + extra.extra
                        : extra.addOn}
                    </span>
                  )}
                </td>
              )}

              <td>
                <strong>
                  <CurrencyDisplay
                    user={user}
                    organizationCredits={organizationCredits}
                    amount={extra.amount || 0}
                  />
                </strong>
              </td>
            </tr>
          ))}
          {extraCredits.length && (
            <>
              <tr>
                <td>
                  <FormattedMessage id="amount" />
                </td>
                <td></td>
                <td></td>
                <td>
                  <span className={'main-line'}>
                    <strong>
                      <CurrencyDisplay
                        user={user}
                        organizationCredits={organizationCredits}
                        amount={totalExtraCredits}
                      />
                    </strong>
                  </span>
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>
      <hr />
    </div>
  );
}
