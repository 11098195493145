import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
//@ts-expect-error
import all_countries from '../../common/countriesStates';

import StripeField from './StripeField';
import { BillingContext } from '../../store/billing.store';
import actions from '../../store/billing.actions';

import { FormattedMessage, injectIntl, useIntl } from 'react-intl';

import { CheckoutFormWrapper, Field } from '../common/common.tools';
import { getSubscriptionInfos } from '../../../../services/api/credits.api';
import { formatSubscribeInfo } from '../../../../services/utils/tools';
import './CheckoutForm.css';
import NumberFormatter from '../../../../components/NumberFormatter/NumberFormatter';

export default function CheckoutForm({
  organizationCredits,
  changeCardToggle,
  setChangeCardToggle,
  updatePaymentToggle,
  error,
  blockProceed,
  user,
  plan,
}: any) {
  const { state, dispatch }: any = useContext(BillingContext);
  const [allCountries, setAllCountries] = useState(null);
  const intl = useIntl();
  let countries = useMemo(() => all_countries, [all_countries]);

  useEffect(() => {
    setAllCountries(countries);
    const choosenCountry = countries.find(
      (cntry: any) => cntry.name === state.checkoutForm.country,
    );
    dispatch({
      type: actions.SET_COUNTRY_STATES,
      payload: choosenCountry.states,
    });
    if (state.profile && state.profile.email) {
      dispatch({
        type: actions.ON_CHECKOUT_FORM,
        payload: { ...state.checkoutForm, email: state.profile.email },
      });
    }
  }, []);

  useEffect(() => {
    if (organizationCredits.customer && !state.checkoutForm.email) {
      const customerCountry = countries.find(
        (cntry: any) => cntry.name === organizationCredits.customer.country,
      );
      dispatch({
        type: actions.SET_COUNTRY_STATES,
        payload: customerCountry ? customerCountry.states : state,
      });

      if (customerCountry && customerCountry.states && customerCountry.states.length === 0) {
        organizationCredits.customer.state = '';
      }
      if (
        customerCountry &&
        customerCountry.states &&
        customerCountry.states.length > 0 &&
        !organizationCredits.customer.state
      ) {
        organizationCredits.customer.state = customerCountry.states[0].name;
      }

      dispatch({
        type: actions.ON_CHECKOUT_FORM,
        payload: { ...state.checkoutForm, ...organizationCredits.customer },
      });
    }
  }, [organizationCredits.customer]);

  if (!allCountries) {
    return <div />;
  }
  const handleSelectCountry = async (selected: any) => {
    dispatch({
      type: actions.ON_CHECKOUT_FORM,
      payload: {
        ...state.checkoutForm,
        country: selected.name,
        state: selected.states && selected.states.length > 0 ? selected.states[0].name : null,
      },
    });
    dispatch({ type: actions.SET_COUNTRY_STATES, payload: selected.states });
    const { data } = await getSubscriptionInfos({
      numberOfSeats: state.selectedPlan.numberOfSeats,
      numberOfMonths: state.selectedPlan.numberOfMonths,
      planId: state.selectedPlan.planId,
      extraCredits: state.selectedPlan.extraCreditsToUpgrade.map((credit: any) => ({
        amount: credit.addedCredits,
        creditType: credit.creditType,
      })),
      country: selected.name,
    });

    dispatch({
      type: actions.GET_SUBSCRIPTION_INFOS,
      payload: {
        ...state.selectedPlan,
        isPrivate: state.selectedPlan.isPrivate,
        //@ts-expect-error
        automaticRenewal: !!data.nextPeriodItems.length,
        //@ts-expect-error
        renewDate: data.renewDate,
        ...formatSubscribeInfo(data),
        credits: state.selectedPlan.credits,
      },
    });
  };

  const handleSelectState = (selected: any) => {
    if (selected) {
      dispatch({
        type: actions.ON_CHECKOUT_FORM,
        payload: { ...state.checkoutForm, state: selected.name },
      });
    }
  };
  const handleOnChange = (value: any, type: any) => {
    const checkoutForm = state.checkoutForm;
    checkoutForm[type] = value;
    dispatch({ type: actions.ON_CHECKOUT_FORM, payload: checkoutForm });
  };

  // Format renew date
  const renewDate = new Date(plan.renewDate);
  const formattedDate = renewDate.toLocaleDateString(intl.locale, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  return (
    <div id="checkout-form-ctn">
      <div className="checkout-right-wrapper">
        {/* TITLE */}
        <h1>
          <FormattedMessage id="checkout.payWithCard" />
        </h1>
        {/* EMAIL */}
        <div className="card-detail-ui checkout-email-ctn">
          <Field
            label={<FormattedMessage id={'info.email'} />}
            placeholder={'user@kaspr.io'}
            //@ts-expect-error
            onChange={(e) => handleOnChange(e.target.value, 'email')}
            value={state.checkoutForm.email}
            isEmailField={true}
            noAsterisk={true}
            required
          />
        </div>
        {/* CARD INFORMATION */}
        {organizationCredits.customer &&
        organizationCredits.customer.cardInfo &&
        !changeCardToggle ? (
          <PaymentInfos
            customer={organizationCredits.customer}
            setChangeCardToggle={setChangeCardToggle}
          />
        ) : (
          <div className="payment-info-wrap">
            <label className="payment-info">
              <FormattedMessage id={'billing.paymentInfo'} />
            </label>
            <StripeField />
          </div>
        )}
        <div className="card-detail-ui ">
          <div className="checkout-form-bottom" style={{ width: '100%' }}>
            {/* COMPANY NAME */}
            <div style={{ width: '100%' }}>
              <FormattedMessage id={'billing.companyName.placeholder'}>
                {(placeholder) => (
                  <Field
                    label={<FormattedMessage id={'billing.companyName'} />}
                    placeholder={placeholder}
                    onChange={(e: any) => handleOnChange(e.target.value, 'companyName')}
                    value={state.checkoutForm.companyName}
                    noAsterisk={true}
                    required={true}
                  />
                )}
              </FormattedMessage>
            </div>
            {/* COMPANY ADDRESS */}
            <div style={{ width: '100%' }}>
              <FormattedMessage id={'billing.companyAddress.placeholder'}>
                {(placeholder) => (
                  <Field
                    label={<FormattedMessage id={'billing.companyAddress'} />}
                    placeholder={placeholder}
                    //@ts-expect-error
                    onChange={(e) => handleOnChange(e.target.value, 'address')}
                    value={state.checkoutForm.address}
                    noAsterisk={true}
                    required={true}
                  />
                )}
              </FormattedMessage>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', width: '100%', gap: '1rem' }}>
              {/* COUNTRIES */}
              <CountriesSelect
                label={<FormattedMessage id={'billing.country'} />}
                options={allCountries}
                onChange={handleSelectCountry}
                selected={state.checkoutForm.country}
                required={true}
              />
              {/* STATE */}
              {state.countryStates && state.countryStates.length > 0 && (
                <StatesSelect
                  label={<FormattedMessage id={'billing.state'} />}
                  options={state.countryStates}
                  onChange={handleSelectState}
                  selected={state.checkoutForm.state}
                />
              )}
            </div>
            {/* VAT NUMBER */}
            <div className="vat-number">
              <FormattedMessage id={'billing.vatNumber.placeholder'}>
                {(placeholder) => (
                  <Field
                    label={
                      <>
                        <FormattedMessage id="billing.vatNumber" /> (
                        <FormattedMessage id="checkout.optional" />)
                      </>
                    }
                    placeholder={placeholder}
                    //@ts-expect-error
                    onChange={(e) => handleOnChange(e.target.value, 'vat')}
                    value={state.checkoutForm.vat}
                  />
                )}
              </FormattedMessage>
            </div>
            {/* PAY */}
            <div id="pay-ctn">
              <div className="pay-wrapper">
                <button
                  type={'submit'}
                  className="btn1"
                  style={blockProceed ? { background: '#ccc', width: '100%' } : { width: '100%' }}
                >
                  <div className="btn-content">
                    <img src="/assets/images/ic-lock.svg" alt="" />
                    {plan.total && organizationCredits.plan.planId === '0' ? (
                      <FormattedMessage id={'checkout.paymentBtn'} />
                    ) : plan.total && organizationCredits.plan.planId !== '0' ? (
                      <FormattedMessage id="billing.proceedToPay" />
                    ) : (
                      <FormattedMessage id={'billing.saveChanges'} />
                    )}
                  </div>
                </button>
              </div>
              {error && (
                <div
                  style={{
                    textAlign: 'right',
                    paddingRight: '19%',
                    paddingTop: 10,
                    color: 'darkred',
                  }}
                >
                  {error}
                </div>
              )}
            </div>

            {/* BOTTOM INFOS */}
            <div id="bottom-infos">
              <div className="infos">
                <span>
                  <FormattedMessage
                    id="checkout.cancelSubscription"
                    values={{ value: formattedDate }}
                  />
                </span>
                {plan.total && organizationCredits.plan.planId !== '0' && (
                  <>
                    {' '}
                    <span>
                      <FormattedMessage
                        id="checkout.nextBillingAmount"
                        values={{
                          value: (
                            <NumberFormatter
                              value={plan.totalRenew * 0.01}
                              user={user}
                              organizationCredits={organizationCredits}
                              currency={true}
                              decimals={true}
                            />
                          ),
                        }}
                      />
                    </span>
                  </>
                )}{' '}
                <span>
                  <FormattedMessage id="checkout.youCanCancelAnytime" />
                </span>
                {plan.extraCredits.length > 0 && (
                  <span className="info-text" style={{ position: 'relative' }}>
                    <img
                      src={'/assets/images/ic-info.png'}
                      alt={'info icon'}
                      width={'10px'}
                      height={'10px'}
                    />
                    <FormattedMessage id="billing.addonCheckoutTooltip" />
                  </span>
                )}
                {/* plan.renewDate */}
              </div>
              <div className="links">
                <div>
                  <FormattedMessage id="checkout.securedBy" />{' '}
                  <svg
                    fill="#838383"
                    width="36px"
                    height="36px"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M8.25 10.435l-2.165 0.46-0.010 7.12c0 1.315 0.99 2.165 2.305 2.165 0.73 0 1.265-0.135 1.56-0.295v-1.69c-0.285 0.115-1.685 0.525-1.685-0.785v-3.16h1.685v-1.89h-1.685zM12.705 13.015l-0.135-0.655h-1.92v7.66h2.215v-5.155c0.525-0.69 1.41-0.555 1.695-0.465v-2.040c-0.3-0.105-1.335-0.3-1.855 0.655zM17.32 9.4l-2.23 0.475v1.81l2.23-0.475zM2.245 14.615c0-0.345 0.29-0.48 0.755-0.485 0.675 0 1.535 0.205 2.21 0.57v-2.090c-0.735-0.29-1.47-0.405-2.205-0.405-1.8 0-3 0.94-3 2.51 0 2.46 3.375 2.060 3.375 3.12 0 0.41-0.355 0.545-0.85 0.545-0.735 0-1.685-0.305-2.43-0.71v2c0.825 0.355 1.66 0.505 2.425 0.505 1.845 0 3.115-0.79 3.115-2.39 0-2.645-3.395-2.17-3.395-3.17zM32 16.28c0-2.275-1.1-4.070-3.21-4.070s-3.395 1.795-3.395 4.055c0 2.675 1.515 3.91 3.675 3.91 1.060 0 1.855-0.24 2.46-0.575v-1.67c-0.605 0.305-1.3 0.49-2.18 0.49-0.865 0-1.625-0.305-1.725-1.345h4.345c0.010-0.115 0.030-0.58 0.030-0.795zM27.605 15.44c0-1 0.615-1.42 1.17-1.42 0.545 0 1.125 0.42 1.125 1.42zM21.96 12.21c-0.87 0-1.43 0.41-1.74 0.695l-0.115-0.55h-1.955v10.24l2.22-0.47 0.005-2.51c0.32 0.235 0.795 0.56 1.57 0.56 1.59 0 3.040-1.16 3.040-3.98 0.005-2.58-1.465-3.985-3.025-3.985zM21.43 18.335c-0.52 0-0.83-0.19-1.045-0.42l-0.015-3.3c0.23-0.255 0.55-0.44 1.060-0.44 0.81 0 1.37 0.91 1.37 2.070 0.005 1.195-0.545 2.090-1.37 2.090zM15.095 20.020h2.23v-7.66h-2.23z" />
                  </svg>
                </div>
                <div className="terms">
                  <a className="copyright" target="_blank" href="https://kaspr.io/terms/">
                    <FormattedMessage id={'auth.terms'} />
                  </a>
                  <a className="copyright" target="_blank" href="https://kaspr.io/privacy-policy/">
                    <FormattedMessage id={'auth.privacy'} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {updatePaymentToggle && (
          <div className="btn-block">
            {error && (
              <div
                className="btn-grp text-center  error  kaspr-form-text-error"
                style={{ top: -10, width: '90%' }}
              >
                {error + ' '}
              </div>
            )}
            <button type={'submit'} className="btn1">
              <span className="ic-lock">
                <img src="/assets/images/ic-lock.svg" alt="" />
              </span>
              <FormattedMessage id={'confirm'} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
function CountriesSelect({ options, label, onChange, selected, required }: any) {
  return (
    <div className="form-group">
      <label>{label}</label>

      <div className="mycustom-select">
        <select
          className="my-select"
          onChange={(e) =>
            onChange(options.find((country: any) => country.name === e.target.value))
          }
          value={selected || 'France'}
          required={required}
        >
          {options.map((opt: any, i: any) => (
            <option key={i} id={opt.name}>
              {opt.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
function StatesSelect({ options, label, onChange, selected }: any) {
  const [alloptions, setOptions] = useState(null);
  const selectRef = useRef(null);

  useEffect(() => {
    setOptions([]);
    setOptions(options);
  }, [options]);

  return (
    <div className="form-group">
      <label>{label}</label>
      {alloptions && (
        <div className="mycustom-select">
          <select
            ref={selectRef}
            className="my-select"
            onChange={(e) =>
              onChange(alloptions.find((state: any) => state.name === e.target.value))
            }
            value={selected}
          >
            {alloptions.map((opt: any, i: any) => (
              <option key={i} id={opt.name}>
                {opt.name}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
}

function PaymentInfos({ customer, setChangeCardToggle }: any) {
  return (
    <div className="payment-info-wrap">
      <label className="payment-info">
        <FormattedMessage id={'billing.paymentInfo'} />
      </label>
      <div className="card-detail-ui border-style">
        <div className="card-info card-type">
          <span className="ic-visa">
            <img src="/assets/images/ic-billing-active.png" alt="" />
          </span>{' '}
          · ****{customer.cardInfo.last4}
        </div>

        <div className="card-info card-expiry">
          <FormattedMessage id={'billing.expiry'} />{' '}
          {customer.cardInfo.expMonth + '/' + customer.cardInfo.expYear}
        </div>
      </div>

      <div className="card-info change-card text-right" style={{ marginTop: '0.5rem' }}>
        <a href="#" className="text-btn" onClick={() => setChangeCardToggle(true)}>
          <FormattedMessage id={'billing.changeCard'} />
        </a>
      </div>
    </div>
  );
}
