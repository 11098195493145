import React, { useEffect, useState } from 'react';
import { PreLoader } from '../../../components';
import './NotifySuccess.css';
import { FormattedMessage } from 'react-intl/lib';
import { warnAdmin } from '../../../services/api/organization.api';
function NotifySuccess() {
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setIsLoading(true);

    window.history.pushState(null, null, '/');
    warnAdmin().then(({ data, statusCode }) => {
      setIsLoading(false);
      if (statusCode == 200) {
        setSuccessMessage(true);
      } else {
        //@ts-expect-error
        setErrorMessage(data.message);
      }
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    });
  }, []);

  if (isLoading) {
    return <PreLoader />;
  }
  if (successMessage) {
    return (
      <div className="unpaid-container unpaid-notify">
        <div className="top-container">
          <i className="fas fa-check-circle"></i>

          <h2>
            <FormattedMessage id={'unpaid.emailSuccessfullySent'} />
          </h2>
        </div>
      </div>
    );
  }
  return (
    <div className="unpaid-container unpaid-notify error">
      <div className="top-container">
        <i className="fas fa-times-circle"></i> <h2 className={'error'}> {errorMessage} </h2>
      </div>
    </div>
  );
}

export default NotifySuccess;
