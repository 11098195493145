import React from 'react';
import { Row } from 'react-bootstrap';

function ContentWrapperUi({ children }: any) {
  return (
    <div className="content-wrapper-ui" style={{ position: 'relative' }}>
      <div className="container-fluid">
        <div className="wd-wrapper">
          <Row> {children}</Row>
        </div>
      </div>
    </div>
  );
}

export default ContentWrapperUi;
