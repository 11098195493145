import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl/lib';

import DataNotFound from '../../../../components/DataNotFound/DataNotFound';
import { actions, OrganizationContext } from '../../store/Organization.store';
import { getActivity } from '../../../../services/api/credits.api';

import ActivityItem from '../../common/ActivityItem/ActivityItem';
import { Pagination, PaginationInput } from '../../../../components';
import './CreditsHistory.css';
export default function CreditsHistory() {
  //@ts-expect-error
  const { state, dispatch } = useContext(OrganizationContext);
  const [currentPage, setPage] = useState(1);
  const handleGoToPage = (page: any) => {
    getActivity({
      page,
      startDate: state.organization.createdAt,
      endDate: new Date(),
    }).then(({ data, statusCode }) => {
      if (statusCode === 200) {
        dispatch({
          type: actions.GET_ACTIVITY,
          payload: {
            activity: data,
            activityFilters: { ...state.activityFilters },
          },
        });
        setPage(page);
      } else {
        dispatch({
          type: actions.GET_ACTIVITY,
          payload: { activity: 'not_allowed' },
        });
      }
    });
  };

  if (!state.activity) return <div></div>;
  return (
    <CreditsHistoryWrapper
      handleGoToPage={handleGoToPage}
      currentPage={currentPage}
      activity={state.activity}
    >
      {state.activity.creditUsage.length === 0 ? (
        <DataNotFound />
      ) : (
        <table width="100%" className="table-fixed">
          <thead>
            <tr className="sticky-header">
              <th>Date</th>

              <th>Email</th>

              <th>
                <FormattedMessage id={'organization.source'} />
              </th>

              <th>
                <FormattedMessage id={'organization.activity.request'} />
              </th>

              <th>
                <FormattedMessage id={'organization.activity.spentCredits'} />
              </th>
            </tr>
          </thead>

          <tbody>
            {state.activity.creditUsage.map((item: any, i: any) => (
              <ActivityItem key={i} item={item} />
            ))}
          </tbody>
        </table>
      )}
    </CreditsHistoryWrapper>
  );
}

function CreditsHistoryWrapper({ children, activity, currentPage, handleGoToPage }: any) {
  return (
    <div className="col-md-11 credits-activity" style={{ padding: 0, marginBottom: 50 }}>
      <div className="info-card ">
        <div
          className="info-card-header head_block "
          style={{
            border: 'none',
            marginBottom: 10,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <p>
            <FormattedMessage id={'organization.activity.creditUsageHistory'} />
          </p>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className="pagination-inline">
              <label>
                <FormattedMessage id={'page'} />
              </label>

              <PaginationInput
                pages={activity.pages.totalPages}
                currentPage={currentPage}
                onChange={handleGoToPage}
                styles={{ margin: 0 }}
                top
              />

              <label>
                <FormattedMessage id={'ofPages'} values={{ value: activity.pages.totalPages }} />
              </label>
            </div>

            <a
              style={{ marginLeft: 16, color: '#3B53D1', fontWeight: '500' }}
              href={'/workspace/activity'}
            >
              <FormattedMessage id={'organization.viewAll'} />
            </a>
          </div>
        </div>

        <div className={' table-content'}> {children}</div>
      </div>
    </div>
  );
}
