import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';

import { Branch, Block, AttachBlock } from './Tools';
import { actions } from '../../store/workflow.store';
import { FormattedMessage, injectIntl } from 'react-intl';

function EnrichementTemplate({ handleSettingsToggle, blockOptions, template, activeBlockId }: any) {
  const getBlock = (id: any) => {
    return template.blocks.find((block: any) => block.id == id);
  };

  useEffect(() => {
    if (!blockOptions) {
      handleSettingsToggle(getBlock(1));
    }
  }, []);

  return <></>;
}
//@ts-expect-error
EnrichementTemplate = injectIntl(EnrichementTemplate);

export { EnrichementTemplate };
