import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Animation, Permissions } from '../../../../../components';
import './LeadExport.css';
function LeadExport({ style, setToggle, toggle, handleExportLeads, trackingFrom }: any) {
  return (
    <>
      <div className="cstm-dropdown addedby user_filter custom-lead-export" style={{ ...style }}>
        <div className="dropdown">
          <Animation.SlideDown
            className={'dropdown-menu show'}
            toggle={toggle}
            setToggle={setToggle}
            style={{ width: 'max-content' }}
            withoutScroll
          >
            <ul>
              {/* <li
                onClick={() => handleExportLeads("Export Excel")}
                data-tracking-id={trackingFrom + "-export-excel"}
              >
              <Permissions.CallToAction >
                  Export EXCEL
              </Permissions.CallToAction>
              </li>*/}

              <li
                onClick={() => handleExportLeads('Export CSV')}
                data-tracking-id={trackingFrom + '-export-csv'}
              >
                Export CSV
              </li>

              <li
                onClick={() => handleExportLeads('Export Excel')}
                data-tracking-id={trackingFrom + '-export-excel'}
              >
                Export EXCEL
              </li>

              <li
                onClick={() => handleExportLeads('Integrations')}
                data-tracking-id={trackingFrom + '-export-integrations'}
              >
                Export <FormattedMessage id={'integration'} />
              </li>
            </ul>
          </Animation.SlideDown>
        </div>
      </div>
    </>
  );
}

export default LeadExport;
