/* global chrome */
import React, { useEffect, useState } from 'react';
import { validatePluginInstallation } from '../../../services/api/auth.api';
import { CHROME_STORE, EXTENSION_ID, IS_PROD } from '../../../constants';
import { ToastContainer } from 'react-toastify';
import { injectIntl, useIntl } from 'react-intl';
import './Onboarding.css';

import OnboardingFooter from '../common/OnboardingFooter/OnboardingFooter';

import RedirectionPage from './RedirectionPage/RedirectionPage';
import { useNavigate } from 'react-router-dom';

function OnBoardingSteps(props: any) {
  const [steps, setSteps] = useState([]);
  const [isExtensionInstalled, setIsExtensionInstalled] = useState(false);
  const intl = useIntl();
  let navigate = useNavigate();

  useEffect(() => {
    if (props.profile && props.profile.steps) {
      setSteps(props.profile.steps);
    }

    if (window.chrome && window.chrome.runtime) {
      window.chrome.runtime.sendMessage(
        EXTENSION_ID,
        {
          action: 'isKasprExtensionInstalled',
        },
        async (response: any, err: any) => {
          if (!response) {
            setIsExtensionInstalled(false);
          } else {
            const allSteps = props.profile.steps.map((step: any) => {
              if (step.id === 'pluginInstallation') {
                if (step.done) {
                  return step;
                }
                step.done = true;
                validatePluginInstallation();
              }
              return step;
            });
            setSteps(allSteps);
            setIsExtensionInstalled(true);
          }
        },
      );
    }
  }, [props.profile]);

  const handleOnClick = async (type: any) => {
    switch (type) {
      case 'linkedin_connect':
        isExtensionInstalled
          ? window.open(
              'https://www.linkedin.com/in/anne-charlotte-lazou/?synchro=kaspr&isFirstKasprSynchro=true',
            )
          : (window.location.href = CHROME_STORE);
        break;
      case 'plugin':
        window.location.href = CHROME_STORE;
        break;
    }
  };

  const handleSkipSteps = async () => {
    navigate('/leads');
  };
  const handleProceed = async () => {
    navigate('/leads');
  };

  return (
    <div id="onboarding-ctn">
      {' '}
      <OnBoardWrapper
        handleSkipSteps={handleSkipSteps}
        handleProceed={handleProceed}
        nextStep={props.profile && props.profile.nextStep}
      >
        <RedirectionPage
          isExtensionInstalled={isExtensionInstalled}
          handleOnClick={handleOnClick}
          isFlagOn={props.isFlagOn}
        />

        <ToastContainer position="bottom-right" style={{ top: 100 }} />
      </OnBoardWrapper>
      <OnboardingFooter />
    </div>
  );
}

function OnBoardWrapper({ children }: any) {
  return (
    <div className="onboard-content-ui">
      <div className="container-fluid">{children}</div>
    </div>
  );
}

//@ts-expect-error
OnBoardingSteps = injectIntl(OnBoardingSteps);
export { OnBoardingSteps };
